import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Drawer,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import * as Yup from 'yup';

import { Button } from 'components/common';
import { useCommonProps } from 'utils/form';
import * as client from 'api/client';
import ProjectNameSuggestions from 'components/suggestions/ProjectNameSuggestions';

const messages = {
	failure: 'Something failed, please retry later.',
};

const CreateProjectDrawer = ({ customerId, customerAddress, isCreating, onProjectCreated, onClose, classes }) => (
	<Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={isCreating} variant="temporary">
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				Create project
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<CreateProjectForm
				customerId={customerId}
				customerAddress={customerAddress}
				onProjectCreated={onProjectCreated}
			/>
		</Box>
	</Drawer>
);

// Pass the project as a prop and let formik handle states internally since
// <Formik /> is an uncontrolled component.
const CreateProjectForm = ({ customerId, customerAddress, onProjectCreated }) => (
	<Formik
		initialValues={{
			title: '',
			address: customerAddress,
			price: null,
			useCustomerAddress: !!(customerAddress && customerAddress.length),
		}}
		validationSchema={Yup.object().shape({
			title: Yup.string().max(512),
			address: Yup.string().nullable().max(512),
			price: Yup.number().min(0).nullable(),
			useCustomerAddress: Yup.boolean(),
		})}
		onSubmit={(values, actions) => {
			actions.setSubmitting(true);
			client
				.createProjectForCustomer({
					customerId,
					title: values.title,
					price: values.price,
					address: values.address,
				})
				.then((response) => {
					if (response.success) {
						onProjectCreated(response.data.project);
					}
				})
				.catch((error) => {
					console.error(error);
					actions.setErrors({ submit: messages.failure });
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<CreateProjectFormFields
							values={values}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
							setFieldValue={setFieldValue}
							isSubmitting={isSubmitting}
							customerAddress={customerAddress}
						/>

						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="100%"
								height="50px"
							>
								Create
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const CreateProjectFormFields = ({
	values,
	onChange,
	setFieldValue,
	onBlur,
	errors,
	touched,
	customerAddress,
	isSubmitting,
}) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField
					fullWidth
					variant="outlined"
					{...propsFn('title')}
					label="Title"
					autoFocus
					placeholder="Gutter installation, Pool, Reroofing, etc."
				/>
			</Grid>

			<ProjectNameSuggestions
				onSelect={(name) => {
					setFieldValue('title', name);
				}}
			/>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('price')} variant="outlined" label="Price" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField
					fullWidth
					{...propsFn('address')}
					variant="outlined"
					label="Address"
					disabled={values.useCustomerAddress}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<FormControlLabel
					aria-label="Use same customer address."
					onClick={(event) => event.stopPropagation()}
					onFocus={(event) => event.stopPropagation()}
					control={
						<Checkbox
							checked={values.useCustomerAddress}
							{...propsFn('useCustomerAddress')}
							onChange={(event) => {
								setFieldValue('useCustomerAddress', event.target.checked);
								if (event.target.checked) {
									setFieldValue('address', customerAddress);
								}
							}}
						/>
					}
					label="Use same customer address."
				/>
			</Grid>
		</Grid>
	);
};

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

export default withStyles(styles)(CreateProjectDrawer);
