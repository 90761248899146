import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Drawer,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import * as Yup from 'yup';

import { Button, OutlinedSelect } from 'components/common';
import { useCommonProps } from 'utils/form';
import * as client from 'api/client';
import { taskPriorityOptions } from 'modules/Task/Utils';
import { TaskPriority, TaskStatus } from 'types/enums';

const messages = {
	failure: 'Something failed, please retry later.',
};

const CreateTaskDrawer = ({ isCreating, onTaskCreated, onClose, classes }) => (
	<Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={isCreating} variant="temporary">
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				Create task
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<CreateTaskForm onTaskCreated={onTaskCreated} />
		</Box>
	</Drawer>
);

const CreateTaskForm = ({ onTaskCreated }) => (
	<Formik
		initialValues={{
			description: '',
			priority: TaskPriority.Medium,
		}}
		validationSchema={Yup.object().shape({
			description: Yup.string().required(),
			priority: Yup.number().oneOf([TaskPriority.Low, TaskPriority.Medium, TaskPriority.High]),
		})}
		validateOnBlur={false}
		onSubmit={(values, actions) => {
			actions.setSubmitting(true);
			client
				.createTask({
					description: values.description,
					priority: values.priority,
					status: TaskStatus.Undone,
				})
				.then((response) => {
					if (response.success) {
						onTaskCreated(response.data.task);
					}
				})
				.catch((error) => {
					console.error(error);
					actions.setErrors({ submit: messages.failure });
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<CreateTaskFormFields
							values={values}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
							isSubmitting={isSubmitting}
						/>

						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="50%"
								height="50px"
							>
								Create
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const CreateTaskFormFields = ({ values, onChange, onBlur, errors, touched, isSubmitting }) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField
					variant="outlined"
					fullWidth
					{...propsFn('description')}
					label="Description"
					autoFocus
					multiline
					rows={4}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<InputLabel>Priority</InputLabel>
				<OutlinedSelect {...propsFn('priority')} placeholder="Priority" fullWidth items={taskPriorityOptions} />
			</Grid>
		</Grid>
	);
};

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

export default withStyles(styles)(CreateTaskDrawer);
