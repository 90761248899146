import styled from 'styled-components';

export const AppStyles = styled.div`
	display: flex;
	min-height: 100vh;
`;

export const MainStyle = styled.div`
	html,
	body {
		/* Prevent scroll on narrow devices */
		overflow-x: hidden;
		font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana,
			sans-serif;
		font-size: 14px;
	}

	body {
		padding-top: 56px;
	}

	/* Prevent scroll when a Bootstrap modal is opened */
	body.modal-open {
		padding-right: 0 !important;
	}

	@media (max-width: 991.98px) {
		.offcanvas-collapse {
			position: fixed;
			top: 56px;
			/* Height of navbar */
			bottom: 0;
			left: 100%;
			width: 100%;
			padding-right: 1rem;
			padding-left: 1rem;
			overflow-y: auto;
			visibility: hidden;
			background-color: #343a40;
			transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
			transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
			transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
		}

		.offcanvas-collapse.open {
			visibility: visible;
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}

	.nav-scroller {
		position: relative;
		z-index: 2;
		height: 2.75rem;
		overflow-y: hidden;
	}

	.nav-scroller .nav {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		padding-bottom: 1rem;
		margin-top: -1px;
		overflow-x: auto;
		color: rgba(255, 255, 255, 0.75);
		text-align: center;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
	}

	.nav-underline .nav-link {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		font-size: 0.875rem;
		color: #6c757d;
	}

	.nav-underline .nav-link:hover {
		color: #007bff;
	}

	.nav-underline .active {
		font-weight: 500;
		color: #343a40;
	}

	.text-white-50 {
		color: rgba(255, 255, 255, 0.5);
	}

	.bg-purple {
		background-color: #6f42c1;
	}

	.lh-100 {
		line-height: 1;
	}

	.lh-125 {
		line-height: 1.25;
	}

	.lh-150 {
		line-height: 1.5;
	}

	/* Table Styles */
	table td {
		vertical-align: middle !important;
	}

	/* Custom */
	main {
		margin-top: 5px;
	}

	#add-new-customer-button {
		position: fixed;
		bottom: 10px;
		right: 10px;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	/* Proposal Image */

	.proposal-image-wrapper {
		position: relative;
	}

	.proposal-image-overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(1, 1, 1, 0.5);
		display: none;
		font-size: 32px;
		color: white;
		font-weight: normal;
	}

	.proposal-image-wrapper:hover > .proposal-image-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* Color box */
	.gutter-color-wrapper {
		display: flex;
		align-self: center;
	}

	.gutter-color-wrapper > .gutter-color-box {
		display: inline-block;
		width: 32px;
		height: 16px;
		border: 1px black solid;
	}

	.gutter-color-wrapper > .gutter-color-name {
		margin-left: 10px;
	}

	/* Project card */
	.project-property-name-cell {
		background-color: #f8f9fa;
	}

	.project-section-title {
		color: #ff6700;
		font-weight: bold;
	}

	/* Title with lines on sides */
	.title-with-lines-on-sides {
		display: flex;
		flex-direction: row;
		font-size: 16px;
	}

	.title-with-lines-on-sides:before,
	.title-with-lines-on-sides:after {
		content: '';
		flex: 1 1;
		border-bottom: 1px solid #000;
		margin: auto;
	}

	.title-with-lines-on-sides:before {
		margin-right: 10px;
	}

	.title-with-lines-on-sides:after {
		margin-left: 10px;
	}

	.hsg-orange-header {
		color: #ff6700;
		font-weight: bold;
	}

	/* Update Project State */
	.hsg-update-state {
		display: flex;
		flex-wrap: nowrap;
	}

	.hsg-update-state-completed {
		width: 100%;
		text-align: center;
	}

	.hsg-update-state-completed > img {
		max-height: 50vh;
	}

	.hsg-update-state-left {
		align-items: center;
		background-color: rgb(248, 249, 250);
		display: flex;
		flex-direction: column;
		flex: 2;
		justify-content: center;
		width: 100%;
		padding: 5px;
	}

	.hsg-update-state-customer-details {
		padding: 0;
		margin: 0;
		text-align: center;
	}

	.hsg-update-state-customer-details > ul {
		list-style: none;
		margin: 10px 0;
		padding: 0;
		color: #7b7878;
		text-align: left;
	}

	.hsg-update-state-right {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		margin-left: 10px;
		width: 100%;
	}

	.hsg-update-state-right > button {
		margin: 10px 0 10px 0;
		width: 100%;
	}

	@media only screen and (max-width: 600px) {
		.hsg-update-state {
			flex-direction: column;
		}

		.hsg-update-state-right {
			margin: 10px 0;
		}
	}

	/* Project Card */
	.project-card-overview-holder {
		margin: 10px;
	}

	.project-card-wrapper .card-header {
		color: white;
		background: #85bb65;
	}

	.project-card-wrapper .project__header-top {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.project-card-wrapper .project__price {
		font-size: 48px;
	}

	.project-card-wrapper .project__customer-name {
		font-size: 16px;
	}

	.project-card-wrapper .project-event {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
	}

	.project__show-proposal-button {
		position: absolute;
		top: 5px;
		right: 5px;
		text-decoration: none;
	}

	.project-navigation {
		margin-top: 16px;
	}

	.project__price-input {
		background-color: transparent;
		border: none;
		margin: 0;
		color: white;
		text-align: center;
	}

	/* Chrome, Safari, Edge, Opera */
	.project__price-input::-webkit-outer-spin-button,
	.project__price-input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.project__price-input:focus {
		outline: none;
	}

	/*----------------------------------------------------------------------
        Borderless input
        ----------------------------------------------------------------------*/
	.inline-input {
		background-color: transparent;
		border: none;
		margin: 0;
		text-align: center;
		width: 100%;
	}

	/* Chrome, Safari, Edge, Opera */
	.inline-input::-webkit-outer-spin-button,
	.inline-input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.inline-input:focus {
		outline: none;
	}

	/*----------------------------------------------------------------------
        Status badge
        ----------------------------------------------------------------------*/

	.status-badge {
		padding: 10px;
		margin: 5px;
	}

	.hsg-tasks-action-button {
		width: 100%;
		padding: 5px 0;
		margin: 10px;
	}

	/*----------------------------------------------------------------------
        Payment method
        ----------------------------------------------------------------------*/

	.hsg-payment-method-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hsg-payment-method-button {
		border: 1px solid #ededed;
		padding: 30px;
		border-radius: 10px;
		flex-direction: column;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 5px;
	}

	.hsg-payment-method-button:hover {
		cursor: pointer;
		border: 1px solid #dadada;
	}

	.hsg-payment-method-button > small {
		margin-top: 10px;
	}

	.hsg-payment-method-button.hsg-button-active {
		background: #ededed;
		border: 1px solid #ededed;
	}

	/*----------------------------------------------------------------------
        Margins
        ----------------------------------------------------------------------*/

	.mt10 {
		margin-top: 10px;
	}

	/*----------------------------------------------------------------------
        White Button
        ----------------------------------------------------------------------*/
	.btn-bordered {
		border: 1px solid;
	}
`;
