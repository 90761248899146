export const currencyFormater = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const displayAmount = (number) => {
	if (number === null || number === undefined) {
		return number;
	}
	return currencyFormater.format(number);
};
