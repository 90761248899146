import { BaseUrl } from 'constant';
import { getHeaders, getJustAuthHeader } from 'utils/http';
import { clearLocalStorage } from 'utils/localStorage';

//***************
// TYPES
//***************

//
// The API endpoints use a simple JSON format where `success` field
// express whether the call was a success or not. An extra field called
// `data` carry out the extra information provided by the handler.
//

export interface GenericSuccess<T> {
	success: true;
	data: T;
}

export interface GenericError {
	success: false;
	data: {
		message?: string;
	};
}

export type GenericResult = { success: boolean };

//***************
// REQUESTS
//***************

const HttpCode = {
	Unauthorized: 401,
};

/* Use this method if the arguments can be safely encoded as JSON */
export async function post<A, R>(route: string, args: A): Promise<R> {
	const response = await fetch(BaseUrl + route, {
		method: 'post',
		headers: getHeaders(),
		body: JSON.stringify(args),
	});
	if (response.status === HttpCode.Unauthorized) {
		// If the given request failed with status code 401 (Unauthorize), then
		// we force the user to login again since the credentials are no longer
		// valid. This error is different from 403 (Forbidden) that means the user
		// has valid credentials but cannot access the given resource.
		clearLocalStorage();
		window.location.replace('/');
	}

	const jsonInfo: any = await response.json();
	return jsonInfo;
}

/* Use this method if the corresponding endpoint is uploading files */
export function postMultipart<R>(route: string, data: FormData): Promise<R> {
	return fetch(BaseUrl + route, {
		method: 'post',
		headers: getJustAuthHeader(),
		body: data,
	}).then((res) => res.json());
}
