import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';

const TitleSection = ({ title, children }) => (
	<>
		<Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
			<Typography variant="h3">{title}</Typography>
			{children}
		</Box>
		<Divider />
	</>
);

export default TitleSection;
