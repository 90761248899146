import React, { Component } from 'react';
import { Grid, Paper, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { getEventColorsCached } from 'utils/localStorage';

class EventColorPicker extends Component {
	state = {
		color: this.props.color,
		colors: [],
	};

	componentDidMount() {
		getEventColorsCached().then((colors) => {
			this.setState({ colors: colors });
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.color !== this.props.color) {
			this.setState({ color: this.props.color });
		}
	}

	handleColorClick = (color) => {
		this.setState({ color }, () => {
			const { onChange } = this.props;
			if (onChange) {
				onChange(color);
			}
		});
	};

	render() {
		if (this.state.colors.length === 0) {
			return null;
		}

		// Look for available colors and fallback to the calendar default color if not found.
		let selectedColor = this.state.color;
		if (
			!selectedColor ||
			this.state.colors.map(({ background }) => background.toLowerCase()).indexOf(selectedColor.toLowerCase()) === -1
		) {
			selectedColor = this.state.colors[0].background;
		}

		const { classes } = this.props;

		return (
			<Grid container justify="space-between" className={classes.colorsWrapper}>
				{this.state.colors.map(({ background }) => (
					<Grid key={background} item xs={1} onClick={() => this.handleColorClick(background)}>
						<Paper
							className={clsx(classes.color, {
								[classes.selectedColor]: background === selectedColor,
							})}
							style={{ backgroundColor: background }}
						></Paper>
					</Grid>
				))}
			</Grid>
		);
	}
}

const styles = (theme) => ({
	colorsWrapper: {
		marginTop: 2,
		alignItems: 'center',
	},
	color: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		cursor: 'pointer',
	},
	selectedColor: {
		border: '2px solid black',
	},
});

export default withStyles(styles)(EventColorPicker);
