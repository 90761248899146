import moment from 'moment-timezone';

export function getCompactedTimeRange(sTime: string, eTime: string): string {
	const a = moment(sTime);
	const b = moment(eTime);
	const now = moment.now();

	if (a.isSame(b, 'date')) {
		let format = 'dddd, MMMM D';
		if (!a.isSame(now, 'year')) {
			format += ', YYYY';
		}
		format += ' ⋅ h:mm';
		if (a.format('a') !== b.format('a')) {
			format += 'a';
		}
		format += ' - ';
		return a.format(format) + b.format('h:mma');
	}

	// Fallback to the non-compacted representation.
	return a.format('MMMM D, YYYY, h:mma') + ' - ' + b.format('MMMM D, YYYY, h:mma');
}

export function relativeTime(datetime: string): string {
	return moment(datetime).fromNow();
}
