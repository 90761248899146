import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import { Row, Column } from 'components/table';

const styles = {
	text: {
		fontWeight: 700,
	},
};

const Header = ({ headerInfo, addExtraColumn, classes }) => (
	<Grid container>
		<Row addDividers>
			{addExtraColumn && <Column justifyContent="flex-start" xs={1} />}
			<Column justifyContent="flex-start" xs={headerInfo[0].xs}>
				<Typography variant="h5" className={classes.text}>
					{headerInfo[0].text}
				</Typography>
			</Column>
			{headerInfo.slice(1, -1).map((h) => (
				<Column key={h.text} xs={h.xs}>
					<Typography variant="h5" className={classes.text}>
						{h.text}
					</Typography>
				</Column>
			))}
			<Column justifyContent="flex-end" xs={headerInfo[headerInfo.length - 1].xs}>
				<Typography variant="h5" className={classes.text}>
					{headerInfo[headerInfo.length - 1].text}
				</Typography>
			</Column>
		</Row>
	</Grid>
);

Header.propTypes = {
	headerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			xs: PropTypes.number,
		})
	),
	addExtraColumn: PropTypes.bool,
};

export default withStyles(styles)(Header);
