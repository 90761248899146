import React, { Component, Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Typography, Menu as MuiMenu, MenuItem, withStyles } from '@material-ui/core';

import styles from 'components/common/styles/menu';

class Menu extends Component {
	state = {
		anchorEl: null,
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleOnClick = (onClick) => {
		onClick();
		this.handleClose();
	};

	render() {
		const { items, button, width = 'auto', classes } = this.props;
		const { anchorEl } = this.state;

		const buttonWithProps = Children.map(button, (child) => {
			if (isValidElement(child)) {
				return cloneElement(child, {
					onClick: (e) => this.setState({ anchorEl: e.currentTarget }),
				});
			}
			return child;
		});

		return (
			<>
				{buttonWithProps}
				<MuiMenu
					anchorEl={anchorEl}
					anchorOrigin={{
						horizontal: 'left',
						vertical: 'bottom',
					}}
					getContentAnchorEl={null}
					onClose={this.handleClose}
					open={Boolean(anchorEl)}
					classes={{ paper: classes.menuPaper, list: classes.menuList }}
					PaperProps={{ style: { maxWidth: width, width } }}
				>
					{items.map(({ value, label, onClick }) => (
						<MenuItem
							key={value}
							value={value}
							onClick={() => this.handleOnClick(onClick)}
							classes={{ root: classes.menuItem }}
							className={classes.menuItem}
							style={{ maxWidth: width, width }}
						>
							<Typography variant="h5" className={classes.label}>
								{label}
							</Typography>
						</MenuItem>
					))}
				</MuiMenu>
			</>
		);
	}
}

Menu.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			label: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	).isRequired,
	button: PropTypes.element.isRequired,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(Menu);
