import React, { Component } from 'react';

import { Button } from 'components/common';

class UploadButton extends Component {
	constructor(props) {
		super(props);
		this.fileInput = React.createRef();
	}

	handleSelect = (e) => {
		const { onUploadFiles } = this.props;
		onUploadFiles(e.target.files);
	};

	render() {
		// eslint-disable-next-line no-unused-vars
		const { acceptFiles, singleFile, ...propsWithoutOnUploadFiles } = this.props;
		return (
			<>
				<Button onClick={() => this.fileInput.current.click()} {...propsWithoutOnUploadFiles}>
					{this.props.children || 'Upload file'}
				</Button>
				<input
					ref={this.fileInput}
					onChange={this.handleSelect}
					type="file"
					accept={acceptFiles || ''}
					multiple={!singleFile}
					style={{ display: 'none' }}
				/>
			</>
		);
	}
}

export default UploadButton;
