import React from 'react';
import TableMobile from 'modules/Customer/List/TableMobile';
import TableWeb from 'modules/Customer/List/TableWeb';
import { withIsMobile } from 'hoc/IsMobileHOC';

const Table = ({ isMobile, ...props }) => {
	return isMobile ? <TableMobile {...props} /> : <TableWeb {...props} />;
};

export default withIsMobile(Table);
