import { BaseUrl, IsProduction } from 'constant';

export const getImageUrl = (imageRelativeSrc) => {
	if (!imageRelativeSrc) {
		return null;
	}

	const [protocol, domain] = BaseUrl.split('/').filter((x) => !!x);

	return IsProduction ? imageRelativeSrc : `${protocol}//${domain}${imageRelativeSrc}`;
};

// https://www.codegrepper.com/code-examples/javascript/convert+base64+to+image+javascript
export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
