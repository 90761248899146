import React from 'react';
import { Button as MuiButton, CircularProgress, withStyles } from '@material-ui/core';

import styles from 'components/common/styles/button';

const Button = ({ children, variant = 'contained', height, width, loading, classes, style, ...props }) => {
	const { loader, ...buttonClasses } = classes;
	return (
		<MuiButton
			variant={variant}
			classes={buttonClasses}
			style={{ width, height, outline: 'none', ...style }}
			{...props}
		>
			{children}
			{loading ? <CircularProgress size={height} className={loader} /> : null}
		</MuiButton>
	);
};

export default withStyles(styles)(Button);
