import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

class NoteItemSearch extends Component {
	render = () => {
		const { content } = this.props;

		return (
			<Grid container direction="row" spacing={2}>
				<Grid item>
					<CommentOutlinedIcon />
				</Grid>
				<Grid item>
					<Typography variant="h6">{content}</Typography>
				</Grid>
			</Grid>
		);
	};
}

NoteItemSearch.propTypes = {
	content: PropTypes.string.isRequired,
};

export default withRouter(NoteItemSearch);
