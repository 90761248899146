import React from 'react';
import PropTypes from 'prop-types';

import {
	Box,
	Dialog as MuiDialog,
	DialogContent,
	DialogTitle as MuiDialogTitle,
	Grid,
	IconButton,
	Typography,
	withStyles,
} from '@material-ui/core';
import { Close, ArrowBack, MoreVert } from '@material-ui/icons';
import { Menu } from 'components/common';

import styles from './styles/dialog';

export const Dialog = withStyles(styles)(
	({
		open,
		onClose,
		goBack,
		title,
		maxWidth,
		fullWidth,
		options,
		TitleComponent,
		disabled,
		classes,
		children,
		...props
	}) => (
		<MuiDialog open={open} onClose={!disabled ? onClose : null} maxWidth={maxWidth} fullWidth={fullWidth} {...props}>
			{TitleComponent || (
				<DialogTitle onClose={onClose} goBack={goBack} options={options} disabled={disabled}>
					{title}
				</DialogTitle>
			)}
			<DialogContent className={classes.dialogContent}>{children}</DialogContent>
		</MuiDialog>
	)
);

Dialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	goBack: PropTypes.func,
	title: PropTypes.string,
	maxWidth: PropTypes.string,
	fullWidth: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.number,
			onClick: PropTypes.func,
		})
	),
	TitleComponent: PropTypes.element,
	disabled: PropTypes.bool,
};

export const DialogTitle = withStyles(styles)((props) => {
	const { onClose, goBack, options, disabled, children, classes } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.titleDialog}>
			<Grid container direction="row" alignItems="center">
				{goBack && (
					<Box mt="3px">
						<IconButton aria-label="close" onClick={goBack} disabled={disabled}>
							<ArrowBack />
						</IconButton>
					</Box>
				)}
				<Typography variant="h5" className={classes.titleText}>
					{children}
				</Typography>
			</Grid>
			<Grid container direction="row" justify="flex-end">
				{options && (
					<Menu
						items={options}
						button={
							<IconButton aria-label="close" onClick={onClose} disabled={disabled}>
								<MoreVert />
							</IconButton>
						}
					/>
				)}
				<IconButton aria-label="close" onClick={onClose} disabled={disabled}>
					<Close />
				</IconButton>
			</Grid>
		</MuiDialogTitle>
	);
});

DialogTitle.propTypes = {
	onClose: PropTypes.func,
	goBack: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.number,
			onClick: PropTypes.func,
		})
	),
	disabled: PropTypes.bool,
};
