import React, { Component } from 'react';
import { FormHelperText, Grid, IconButton, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as _ from 'lodash';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Button } from 'components/common';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';

import ChipInput from 'material-ui-chip-input';
import moment from 'moment';

// Helper function to create a placeholder phone that will be created for this customer. A unique
// ID is created for such phones in order to use it to identify phones that the user is editing or
// decide to delete them. Real (phones existing in the database) will have a numeric ID instead.
const makeBlankPhone = () => ({
	phone: '',
	id: _.uniqueId('placeholder-'),
	isPrimary: false,
});

class CustomerUpdateForm extends Component {
	state = {
		// We need to change id to customerId since the edit endpoint expect `customerId` instead. Thus
		// we can pass the state object as it is.
		customerId: this.props.customer.id,

		// Select other editable fields
		..._.pick(this.props.customer, ['firstName', 'lastName', 'address', 'email', 'organization', 'note', 'tags']),

		// We can't take the birthday directly because the server returns it in the 'YYY-MM-DD' format, but
		// we need it in the 'MM/DD/YYYY' format instead.
		birthday: this.props.customer.birthday ? moment(this.props.customer.birthday).format('L') : null,

		// If the customer doesn't have any phone create a placeholder to always render at
		// least one text input.
		phones: this.props.customer.phones.length > 0 ? this.props.customer.phones : [makeBlankPhone()],
	};

	// Simply append a new phone placeholder to the end of the phones list. This phone gets
	// created with a fake unique ID useful to identify the entry being edited or deleted.
	handleAddPhone = () => {
		this.setState(({ phones }) => ({
			phones: [...phones, makeBlankPhone()],
		}));
	};

	handlePhoneChanged = (id, value) => {
		this.setState(({ phones }) => ({
			phones: phones.map((currentPhone) => (currentPhone.id !== id ? currentPhone : { ...currentPhone, phone: value })),
		}));
	};

	handlePhoneRemoved = (id) => {
		this.setState(({ phones }) => ({
			phones: phones.filter((phone) => phone.id !== id),
		}));
	};

	editCustomer = (values, actions) => {
		const { onCustomerUpdated, snackbarContext } = this.props;

		const { customerId, phones, tags } = this.state;
		const { firstName, lastName, email, address, organization, note, birthday } = values;

		client
			.editCustomer({
				customerId,
				firstName,
				lastName,
				email,
				address,
				organization,
				note,
				phones,
				tags,
				birthday,
			})
			.then((response) => {
				if (response.success) {
					snackbarContext.success('Customer edited successfully');
					onCustomerUpdated(response.data.customer);
				} else {
					actions.setErrors({ submit: response.data.message });
				}
			})
			.catch((error) => {
				snackbarContext.failure('Something went wrong, try again!');
				console.error(error);
				actions.setSubmitting(false);
			});
	};

	render() {
		const { firstName, lastName, phones, email, organization, address, note, tags, birthday } = this.state;

		return (
			<Formik
				initialValues={{
					firstName,
					lastName,
					email,
					organization,
					address,
					note,
					tags,
					birthday,
				}}
				validationSchema={Yup.object().shape({
					firstName: Yup.string().max(128).nullable(),
					lastName: Yup.string().max(128).nullable(),
					email: Yup.string().email('Must be a valid email').max(255).nullable(),
					address: Yup.string().max(1024).nullable(),
					organization: Yup.string().max(128).nullable(),
					birthday: Yup.date().nullable(),
					note: Yup.string().nullable(),
				})}
				onSubmit={(values, actions) => this.editCustomer(values, actions)}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Grid container md={12} xs={12} spacing={2}>
							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.firstName && errors.firstName)}
									fullWidth
									helperText={touched.firstName && errors.firstName}
									label="First name"
									name="firstName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.firstName}
									variant="outlined"
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.lastName && errors.lastName)}
									fullWidth
									helperText={touched.lastName && errors.lastName}
									label="Last name"
									name="lastName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.lastName}
									variant="outlined"
								/>
							</Grid>

							<CustomerPhonesForm
								phones={phones}
								onPhoneChanged={this.handlePhoneChanged}
								onPhoneRemoved={this.handlePhoneRemoved}
							/>

							<Grid item md={12} xs={12}>
								<Button onClick={this.handleAddPhone} variant="outlined" width="100%" height="50px">
									+ Add another phone
								</Button>
							</Grid>

							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									label="Email"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.email}
									variant="outlined"
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.address && errors.address)}
									fullWidth
									helperText={touched.address && errors.address}
									label="Address"
									name="address"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.address}
									variant="outlined"
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.organization && errors.organization)}
									fullWidth
									helperText={touched.organization && errors.organization}
									label="Organization"
									name="organization"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.organization}
									variant="outlined"
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								<KeyboardDatePicker
									label="Birthday"
									name="birthday"
									format="MM/DD/yyyy"
									placeholder="MM/DD/YYYY"
									fullWidth
									variant="inline"
									inputVariant="outlined"
									value={values.birthday}
									error={Boolean(touched.birthday && errors.birthday)}
									helperText={touched.birthday && errors.birthday}
									onChange={(date) => setFieldValue('birthday', date.format('L'))}
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.note && errors.note)}
									fullWidth
									helperText={touched.note && errors.note}
									label="Customer note"
									name="note"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.note}
									variant="outlined"
									multiline
									rows={4}
								/>
							</Grid>

							<Grid item md={12} xs={12}>
								{/* uncontrolled mode */}
								<ChipInput
									defaultValue={values.tags}
									error={Boolean(touched.tags && errors.tags)}
									fullWidth
									helperText={touched.tags && errors.tags}
									label="Tags"
									name="tags"
									onBlur={handleBlur}
									onChange={(tags) => this.setState({ tags })}
									variant="outlined"
								/>
							</Grid>

							{errors.submit && (
								<Grid item md={12} xs={12}>
									<FormHelperText error>{errors.submit}</FormHelperText>
								</Grid>
							)}

							<Grid item md={12} xs={12}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									loading={isSubmitting}
									width="100%"
									height="50px"
								>
									Edit
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		);
	}
}

// Non-stateful component rendering phone inputs with two actions: edit & remove. The
// parent component is the one keeping the state of what is rendered in this one.
const CustomerPhonesForm = ({ phones, onPhoneChanged, onPhoneRemoved }) =>
	phones.map(({ id, phone }, index) => (
		<Grid key={`phone-${id}`} item md={12} xs={12}>
			<TextField
				fullWidth
				value={phone}
				onChange={(e) => onPhoneChanged(id, e.target.value)}
				variant="outlined"
				label="Phone"
				InputProps={{
					// With !!index here we are forcing to hide the remove button from
					// the very first input. The idea is that at least one phone number
					// must be rendered.
					endAdornment: !!index && (
						<IconButton aria-label="delete" onClick={() => onPhoneRemoved(id)}>
							<DeleteIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</Grid>
	));

export default withSnackbarContext(CustomerUpdateForm);
