import React, { Component } from 'react';

import { Box, CircularProgress, Grid } from '@material-ui/core';

import { DefaultPageSize } from 'utils/search';
import { ProjectStatusSelect } from 'utils/states';
import { Search } from 'components/filters';
import { Select } from 'components/common';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import Pagination from 'components/pagination';
import Table from 'modules/Project/List/Table';
import TitleSection from 'components/TitleSection';
import ConfirmationAlert from 'components/confirmationAlert';
import EditProject from 'modules/Project/Edit';

const messages = {
	projects: 'Projects',
	addProject: 'Add project',
	deleteProjectWarning:
		'All items related to this project will be also removed (e.g attachments, ' +
		'notes, and events). Are you sure you want to proceed?',
};

class ProjectList extends Component {
	state = {
		projects: [],

		projectToDelete: null,

		isLoading: false,

		total: 0,

		pagination: {
			start: 0,
			limit: DefaultPageSize,
		},

		search: {
			query: '',
		},

		filters: {
			status: -1,
		},

		projectToEdit: null,
	};

	componentDidMount = () => {
		this.fetchCustomersList();
	};

	//----------------------------------------------------------------------
	// List & remove project helpers
	//----------------------------------------------------------------------

	fetchCustomersListInternal = () => {
		const { pagination, search, filters } = this.state;
		client
			.getProjects({ pagination, search, filters, withCustomer: true })
			.then((response) => {
				const { projects, total } = response.data;
				this.setState({ projects, total });
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				snackbarContext.failure('Unable to fetch projects, refresh the page.');

				console.error(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	fetchCustomersList = () => {
		this.setState({ isLoading: true }, () => this.fetchCustomersListInternal());
	};

	handleRemoveProjectIntention = (project) => {
		this.setState({ projectToDelete: project });
	};

	handleRemoveProjectInternal = () => {
		const { projectId } = this.state.projectToDelete;
		client
			.deleteProject({ projectId })
			.then((response) => {
				if (response.success) {
					this.setState(({ projects }) => ({
						projectToDelete: null,
						projects: projects.filter((project) => project.projectId !== projectId),
					}));
				}
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				snackbarContext.failure('Unable to delete project, try later.');

				console.error(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	handleRemoveProject = () => {
		this.setState({ isLoading: true }, () => this.handleRemoveProjectInternal());
	};

	//----------------------------------------------------------------------
	// Edit project handlers
	//----------------------------------------------------------------------

	handleProjectEdit = (editedProject) => {
		this.setState((previousState) => ({
			projectToEdit: null,
			projects: previousState.projects.map((project) =>
				project.projectId === editedProject.projectId ? editedProject : project
			),
		}));
	};

	handleCloseEditProjectDrawer = () => {
		this.setState({ projectToEdit: null });
	};

	handleProjectUpdate = (project) => {
		this.setState({
			projectToEdit: project,
		});
	};

	//----------------------------------------------------------------------
	// Pagination & filtering
	//----------------------------------------------------------------------

	handlePaginationLimitChanged = (limit) => {
		this.setState({ pagination: { start: 0, limit } }, () => this.fetchCustomersList());
	};

	handlePageChanged = (page) => {
		this.setState(
			({ pagination: { limit } }) => ({
				pagination: { start: (page - 1) * limit, limit },
			}),
			() => this.fetchCustomersList()
		);
	};

	handleQueryChanged = (query) => {
		this.setState(
			({ pagination: { limit } }) => ({
				search: { query },
				pagination: { start: 0, limit },
			}),
			() => this.fetchCustomersList()
		);
	};

	handleStatusChanged = (status) => {
		this.setState({ filters: { status } }, () => this.fetchCustomersList());
	};

	render = () => {
		const { projects, total, pagination, filters, projectToDelete, isLoading, projectToEdit } = this.state;
		const pages = Math.ceil(total / pagination.limit);
		const currentPage = Math.floor(pagination.start / pagination.limit) + 1;

		return (
			<>
				<EditProject
					project={projectToEdit}
					onEdit={this.handleProjectEdit}
					onClose={this.handleCloseEditProjectDrawer}
				/>
				<TitleSection title={messages.projects} />

				<ConfirmationAlert
					openAlert={!!projectToDelete}
					onCancelation={() => this.setState({ projectToDelete: null })}
					onConfirmation={() => this.handleRemoveProject()}
					message={messages.deleteProjectWarning}
				/>

				<Box mt="20px" mb="20px">
					<Grid container wrap="nowrap">
						<Search placeholder="Search projects" onQueryChange={this.handleQueryChanged} disabled={isLoading} />

						<Box ml="40px">
							<Select
								value={filters.status}
								placeholder="Project status"
								items={ProjectStatusSelect}
								onChange={this.handleStatusChanged}
								disabled={isLoading}
							/>
						</Box>
					</Grid>
				</Box>

				{isLoading ? (
					<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
						<CircularProgress size={60} />
					</Box>
				) : (
					<>
						<Table
							projects={projects}
							onUpdateProject={this.handleProjectUpdate}
							onRemoveProject={this.handleRemoveProjectIntention}
						/>

						<Box mt="40px">
							<Pagination
								currentPage={currentPage}
								pages={pages}
								itemsPerPage={pagination.limit}
								onChangeItemsPerPage={this.handlePaginationLimitChanged}
								goToPage={this.handlePageChanged}
							/>
						</Box>
					</>
				)}
			</>
		);
	};
}

export default withSnackbarContext(ProjectList);
