import React, { Component } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	withStyles,
} from '@material-ui/core';
import {
	AccessTime,
	AccountCircle,
	ArrowForward,
	Close as CloseIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Event,
	LocationOn,
	Phone,
} from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import { getCompactedTimeRange } from 'utils/time';
import { withSnackbarContext } from 'context/SnackbarsContext';
import AddCustomerAndEventForm from 'modules/Calendar/AddCustomerAndEventForm';
import * as client from 'api/client';
import { relativeTime } from 'utils/time';

const messages = {
	eventDeletionSuccess: 'Event deleted succesfully',
	eventDeletionFailure: 'Unable to delete event, try again.',
};

class EventDetailsModal extends Component {
	state = {
		isEditModalOpen: false,
	};

	handleDelete = () => {
		const phaseId = this.props.event.extra.phase.projectPhaseId;
		const { onDeleteComplete, snackbarContext } = this.props;

		client
			.deletePhase({ phaseId })
			.then((response) => {
				if (response.success) {
					onDeleteComplete(phaseId);
					snackbarContext.success(messages.eventDeletionSuccess);
				} else {
					snackbarContext.failure(messages.eventDeletionFailure);
				}
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure(messages.eventDeletionFailure);
			});
	};

	renderAppointmentStatus = () => {
		const { classes } = this.props;

		const { appointmentAcceptedTime } = this.props.event.extra.phase;
		if (appointmentAcceptedTime) {
			return (
				<DialogActions className={classes.dialogFooter}>
					<Alert severity="success" className={classes.alert}>
						Appointment <strong>accepted</strong> {relativeTime(appointmentAcceptedTime)}
					</Alert>
				</DialogActions>
			);
		}

		const { appointmentCanceledTime } = this.props.event.extra.phase;
		if (appointmentCanceledTime) {
			return (
				<DialogActions className={classes.dialogFooter}>
					<Alert severity="error" className={classes.alert}>
						Appointment <strong>canceled</strong> {relativeTime(appointmentCanceledTime)}
					</Alert>
				</DialogActions>
			);
		}

		return null;
	};

	render() {
		const { isOpen, onClose, onUpdateComplete, classes } = this.props;
		const { title, customer, projectId, address, startTime, endTime } = this.props.event.extra.phase;

		return (
			<Dialog maxWidth="sm" fullWidth onClose={onClose} open={isOpen}>
				<DialogTitle disableTypography className={classes.dialogTitle}>
					<div>
						<IconButton aria-label="edit" onClick={() => this.setState({ isEditModalOpen: true })}>
							<EditIcon />
						</IconButton>
						<IconButton aria-label="close" onClick={this.handleDelete}>
							<DeleteIcon />
						</IconButton>
					</div>

					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent dividers className={classes.dialogContent}>
					<List component="nav">
						<ListItem>
							<ListItemIcon>
								<Event />
							</ListItemIcon>
							<ListItemText primary={title} />
						</ListItem>

						<ListItem>
							<ListItemIcon>
								<AccessTime />
							</ListItemIcon>
							<ListItemText primary={getCompactedTimeRange(startTime, endTime)} />
						</ListItem>

						{address ? (
							<ListItem button component="a" target="blank" href={`https://maps.google.com?q=${address}`}>
								<ListItemIcon>
									<LocationOn />
								</ListItemIcon>
								<ListItemText primary={address} />
							</ListItem>
						) : null}

						{customer ? (
							<ListItem button component={Link} to={`/customer/${customer.id}/details`}>
								<ListItemIcon>
									<AccountCircle />
								</ListItemIcon>
								<ListItemText primary={customer.fullName} />
							</ListItem>
						) : null}

						{customer && customer.phone ? (
							<ListItem button component="a" target="blank" href={`tel:${customer.phone}`}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText primary={customer.phone} />
							</ListItem>
						) : null}

						{projectId ? (
							<ListItem button component={Link} to={`/project/${projectId}/details`}>
								<ListItemIcon>
									<ArrowForward />
								</ListItemIcon>
								<ListItemText primary={'Go to project details'} />
							</ListItem>
						) : null}
					</List>
				</DialogContent>

				{this.renderAppointmentStatus()}
				<AddCustomerAndEventForm
					open={this.state.isEditModalOpen}
					event={this.props.event}
					onClose={() => this.setState({ isEditModalOpen: false })}
					onAddOrUpdateCompleted={onUpdateComplete}
				/>
			</Dialog>
		);
	}
}

const styles = () => ({
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	dialogContent: {
		borderBottom: 'none',
	},
	dialogFooter: {
		justifyContent: 'flex-start',
		padding: 0,
	},
	alert: {
		width: '100%',
	},
});

export default withSnackbarContext(withStyles(styles)(EventDetailsModal));
