import * as models from 'types/models/models';
import { post, GenericSuccess } from 'api/core';

export type GetGoogleContactsIntegrationsResult = GenericSuccess<{
	integrations: models.GoogleContactsIntegration[];
}>;

export const getGoogleContactsIntegrations = (): Promise<GetGoogleContactsIntegrationsResult> => {
	return post('integration/google-contacts/', {});
};

//----------------------------------------------------------------------
// Integrations V2
//----------------------------------------------------------------------

export type GetEnabledIntegrationsResult = GenericSuccess<{
	integrations: models.Integration[];
}>;

export const getEnabledIntegrations = (): Promise<GetEnabledIntegrationsResult> => {
	return post('integrations/', {});
};

export interface GetAuthorizationUrlForIntegrationArgs {
	name: string;
}

export type GetAuthorizationUrlForIntegrationResult = GenericSuccess<{
	url: string;
}>;

export const getAuthorizationUrlForIntegration = (
	args: GetAuthorizationUrlForIntegrationArgs
): Promise<GetAuthorizationUrlForIntegrationResult> => {
	return post('integrations/authorization-url/', args);
};
