const styles = (theme) => ({
	container: {
		height: 'calc(95vh - 80px)',
	},
	number: {
		fontSize: 100,
		color: '#00B2A1',
	},
	pageNotFound: {
		marginLeft: 10,
		color: '#00B2A1',
	},
	button: {
		width: 150,
		height: 40,
		marginTop: 20,
		marginLeft: -250,
		[theme.breakpoints.down('md')]: {
			marginLeft: -230,
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: -200,
		},
	},
	adornment: {
		[theme.breakpoints.down('lg')]: {
			height: 600,
			width: 600,
		},
		[theme.breakpoints.down('md')]: {
			height: 500,
			width: 500,
		},
		[theme.breakpoints.down('sm')]: {
			height: 400,
			width: 400,
		},
	},
});

export default styles;
