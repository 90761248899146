import React, { Component } from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import Page from 'components/Page';
import Footer from 'components/Footer';

class TermsOfService extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Page title="Terms Of Service">
				<Card className={classes.root}>
					<Typography variant="h1">Terms of Service</Typography>
					<Typography variant="h5">Last updated June 13, 2023</Typography>

					<br />

					<Typography>
						Please read these terms of service (&quot;terms of service&quot;, &quot;terms&quot;) carefully before using
						Werkgo (&quot;website&quot;, &quot;service&quot;, &quot;us&quot;, &quot;we&quot;, &quot;our&quot;).
					</Typography>

					<br />

					<Typography variant="h4">Conditions of use</Typography>

					<Typography>
						By using this website, you certify that you have read and reviewed this Agreement and that you agree to
						comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to
						leave the website accordingly. We only grants use and access of this website, its products, and its services
						to those who have accepted its terms.
					</Typography>

					<br />

					<Typography variant="h4">Privacy policy</Typography>

					<Typography>
						Before you continue using our website, we advise you to read our privacy policy{' '}
						<a href="/privacy">https://werkgo.com/privacy</a> regarding our user data collection. It will help you
						better understand our practices.
					</Typography>

					<br />

					<Typography variant="h4">Age restriction</Typography>

					<Typography>
						You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you
						warrant that you are at least 18 years of age and you may legally adhere to this Agreement. Werkgo assumes
						no responsibility for liabilities related to age misrepresentation.
					</Typography>

					<br />

					<Typography variant="h4">Intellectual property</Typography>

					<Typography>
						You agree that all materials, products, and services provided on this website are the property of Werkgo,
						its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights,
						trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not
						reproduce or redistribute Werkgo’s intellectual property in any way, including electronic, digital, or new
						trademark registrations.
					</Typography>

					<br />

					<Typography variant="h4">User accounts</Typography>

					<Typography>
						As a user of this website, you may be asked to register with us and provide private information. You are
						responsible for ensuring the accuracy of this information, and you are responsible for maintaining the
						safety and security of your identifying information. You are also responsible for all activities that occur
						under your account or password.
					</Typography>

					<br />

					<Typography>
						If you think there are any possible issues regarding the security of your account on the website, inform us
						immediately so we may address it accordingly.
					</Typography>

					<br />

					<Typography>
						We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole
						discretion.
					</Typography>

					<br />

					<Typography variant="h4">Applicable law</Typography>

					<Typography>
						By visiting this website, you agree that the laws of the State of Florida, without regard to principles of
						conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between
						Werkgo and you, or its business partners and associates.
					</Typography>

					<br />

					<Typography variant="h4">Disputes</Typography>

					<Typography>
						Any dispute related in any way to your visit to this website or to products you purchase from us shall be
						arbitrated by state or federal court of the State of Florida and you consent to exclusive jurisdiction and
						venue of such courts.
					</Typography>

					<br />

					<Typography variant="h4">Indemnification</Typography>

					<Typography>
						You agree to indemnify Werkgo and its affiliates and hold us harmless against legal claims and demands that
						may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
					</Typography>

					<br />

					<Typography variant="h4">Limitation on liability</Typography>

					<Typography>
						Werkgo is not liable for any damages that may occur to you or your company as a result of your misuse of our
						website.
					</Typography>

					<Typography>
						We reserve the right to edit, modify, and change this Agreement any time. We shall let our users know of
						these changes through electronic mail. This Agreement is an understanding between Werkgo and the user, and
						this supersedes and replaces all prior agreements regarding the use of this website.
					</Typography>
				</Card>

				<Footer />
			</Page>
		);
	}
}

const styles = (theme) => ({
	root: {
		width: '1024px',
		maxWidth: '100%',
		padding: theme.spacing(3),
		margin: 'auto',
	},
});

export default withStyles(styles)(TermsOfService);
