import React, { Component } from 'react';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Button } from 'components/common';

import styles from './style';

class NotFound extends Component {
	render() {
		const { history, classes } = this.props;

		return (
			<Grid container justify="center" alignItems="center" className={classes.container}>
				<Box
					position="fixed"
					bgcolor="#00b2a160"
					height="700px"
					width="700px"
					borderRadius="500px"
					top="-200px"
					left="-200px"
					className={classes.adornment}
				/>
				<Box
					position="fixed"
					bgcolor="#42e07abf"
					height="700px"
					width="700px"
					borderRadius="500px"
					top="-300px"
					right="-300px"
					className={classes.adornment}
				/>
				<Box
					position="fixed"
					bgcolor="#00b2a1a0"
					height="700px"
					width="700px"
					borderRadius="500px"
					bottom="-200px"
					right="-100px"
					className={classes.adornment}
				/>
				<Grid container justify="center" alignItems="baseline">
					<Typography variant="h1" className={classes.number}>
						404
					</Typography>
					<Typography variant="h1" className={classes.pageNotFound}>
						Page not found.
					</Typography>
					<Grid container item justify="center" xs={12}>
						<Button onClick={() => history.replace('/')} variant="outlined" color="primary" className={classes.button}>
							Go Home
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(withRouter(NotFound));
