const styles = () => ({
	drawer: {
		width: 230,
		height: 'calc(100% - 80px)',
	},
	drawerPaper: {
		width: 230,
		height: 'calc(100% - 80px)',
		marginTop: 80,
		// Setting this zIndex value to avoid drawer on top
		// of image gallery!
		zIndex: 1000,
	},
	list: {
		marginTop: 10,
		height: '100%',
		position: 'relative',
	},
	menuItem: {
		padding: '10px 0 10px 40px',
		cursor: 'pointer',
		'& :hover': {
			color: '#00B2A190',
		},
	},
	menuText: {
		marginLeft: 15,
	},
	avatar: {
		height: 75,
		width: 75,
		borderRadius: 100,
	},
	avatarAndName: {
		marginTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	link: {
		color: '#000',
		textDecoration: 'none !important',
	},
	selectedLink: {
		color: '#00B2A1',
	},
	selectedSection: {
		backgroundColor: '#F3F6F8',
	},
	logout: {
		position: 'absolute',
		bottom: 0,
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		'& :hover': {
			color: 'red',
		},
	},
});

export default styles;
