/* eslint-disable no-mixed-spaces-and-tabs */
import { post, GenericSuccess, GenericError } from 'api/core';
import * as models from 'types/models/models';

//***************
// TYPES
//***************

//
// login
//

export interface LoginArgs {
	email: string;
	password: string;
}

export type LoginResult =
	| GenericSuccess<{
			user: models.User;
	  }>
	| GenericError;

//
// signup
//

export interface SignupArgs {
	firstName: string;
	lastName: string;
	companyName: string;
	email: string;
	password: string;
	phone: string;
}

export type SignupResult =
	| GenericSuccess<{
			user: models.User;
	  }>
	| GenericError;

//
// signupInvite
//

export interface SignupInviteArgs {
	firstName: string;
	lastName: string;
	invitationToken: string;
	password: string;
	phone: string;
}

//
// verifyEmail
//

export interface VerifyEmailArgs {
	userId: number;
	token: string;
}

export type VerifyEmailResult = GenericSuccess<{}> | GenericError;

//
// forgotPassword
//

export interface ForgotPasswordArgs {
	email: string;
}

export type ForgotPasswordResult = GenericSuccess<{}> | GenericError;

//
// resetPassword
//

export interface ResetPasswordArgs {
	token: string;
	userId: number;
	newPassword: string;
}

export type ResetPasswordResult = GenericSuccess<{}> | GenericError;

//
// getCurrentUserInfo
//

export type GetCurrentUserInfoResult =
	| GenericSuccess<{
			user: {
				id: number;
				firstName: string;
				lastName: string;
				email: string;
			};
	  }>
	| GenericError;

//
// editCurrentUserInfo
//

interface EditCurrentUserInfoArgs {
	firstName: string;
	lastName: string;
	email: string;
}

type EditCurrentUserInfoResult =
	| GenericSuccess<{
			user: {
				id: number;
				firstName: string;
				lastName: string;
				email: string;
			};
	  }>
	| GenericError;

//
// changePassword
//

interface ChangePasswordArgs {
	currentPassword: string;
	newPassword: string;
}

type ChangePasswordResult = GenericSuccess<{}> | GenericError;

//
// fetchGoogleAuthorizationUrl
//
type FetchGoogleAuthorizationUrlResult =
	| GenericSuccess<{
			authorizationUrl: string;
	  }>
	| GenericError;

//
// fetchIntegrationConfig
//

type FetchIntegrationConfigResult =
	| GenericSuccess<{
			gmailIntegration: {
				enabled: boolean;
				email: string;
			};
	  }>
	| GenericError;

//***************
// REQUESTS
//***************

export const login = (args: LoginArgs): Promise<LoginResult> => {
	return post('login/', args);
};

export const signup = (args: SignupArgs): Promise<SignupResult> => {
	return post('signup/', args);
};

export const signupInvite = (args: SignupInviteArgs): Promise<SignupResult> => {
	return post('signup-invite/', args);
};

export const verifyEmail = (args: VerifyEmailArgs): Promise<VerifyEmailResult> => {
	return post('verify-email/', args);
};

export const forgotPassword = (args: ForgotPasswordArgs): Promise<ForgotPasswordResult> => {
	return post('forgot-password/', args);
};

export const resetPassword = (args: ResetPasswordArgs): Promise<ResetPasswordResult> => {
	return post('reset-password/', args);
};

export const getCurrentUserInfo = (): Promise<GetCurrentUserInfoResult> => {
	return post('current-user-info/', {});
};

export const editCurrentUserInfo = (args: EditCurrentUserInfoArgs): Promise<EditCurrentUserInfoResult> => {
	return post('edit-current-user-info/', args);
};

export const changePassword = (args: ChangePasswordArgs): Promise<ChangePasswordResult> => {
	return post('change-password/', args);
};

export const fetchGoogleAuthorizationUrl = (): Promise<FetchGoogleAuthorizationUrlResult> => {
	return post('google/oauth2/authorization/', {});
};

export const fetchIntegrationConfig = (): Promise<FetchIntegrationConfigResult> => {
	return post('integration/', {});
};
