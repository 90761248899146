import React, { Component } from 'react';
import SharedSnackbar from 'components/snackbar';

export const SharedSnackbarContext = React.createContext();

export class SharedSnackbarProvider extends Component {
	state = {
		message: '',
		isOpen: false,
		type: 'success',
	};

	open = (message, type) => {
		this.setState({ message, isOpen: true, type: type });
	};

	success = (message) => {
		this.open(message, 'success');
	};

	failure = (message) => {
		this.open(message, 'error');
	};

	info = (message) => {
		this.open(message, 'info');
	};

	closeSnackbar = () => {
		this.setState({
			isOpen: false,
		});
	};

	render() {
		const { children } = this.props;
		const { isOpen, message, type } = this.state;

		return (
			<SharedSnackbarContext.Provider
				value={{
					success: this.success,
					failure: this.failure,
					info: this.info,
					closeSnackbar: this.closeSnackbar,
					snackbarIsOpen: isOpen,
					message,
					type,
				}}
			>
				<SharedSnackbar />
				{children}
			</SharedSnackbarContext.Provider>
		);
	}
}

export const SharedSnackbarConsumer = SharedSnackbarContext.Consumer;

export function withSnackbarContext(Component) {
	return function WrapperComponent(props) {
		return (
			<SharedSnackbarConsumer>{(state) => <Component {...props} snackbarContext={state} />}</SharedSnackbarConsumer>
		);
	};
}
