const styles = {
	paginatorContainer: {
		marginLeft: 80,
	},
	paper: {
		display: 'inline-block',
		padding: '6px 12px',
		margin: '0 4px',
	},
	selectedPaper: {
		backgroundColor: '#00B2A1',
	},
	text: {
		fontWeight: 600,
	},
	selectedText: {
		color: '#fff',
	},
	button: {
		cursor: 'pointer',
	},
	select: {
		height: 34,
		paddingBottom: 3,
		backgroundColor: '#fff',
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#011842 !important',
		},
	},
	selectFocus: {
		backgroundColor: 'transparent !important',
	},
	showRowsText: {
		marginRight: 10,
	},
};

export default styles;
