/* eslint-disable no-mixed-spaces-and-tabs */
import { post, GenericSuccess, GenericResult, GenericError, postMultipart } from 'api/core';
import { Pagination, SearchFilter, SearchTerm } from 'api/shared';
import { ProjectDetailsResult } from 'api/project';
import * as models from 'types/models/models';

//***************
// TYPES
//***************

//
// getCustomers
//

export interface CustomerListArgs {
	pagination?: Pagination;
	filter?: SearchFilter;
	search?: SearchTerm;
	sortByName?: boolean;
}

export interface GetCustomersArgs {}

export type GetCustomersResult =
	| GenericSuccess<{
			customers: models.Customer[];
	  }>
	| GenericError;

//
// getCustomerDetails
//

export interface CustomerDetailsArgs {
	customerId: number;
}

export type CustomerDetailsResult =
	| GenericSuccess<{
			customer: models.Customer;
			projects: models.Project[];
	  }>
	| GenericError;

//
// createCustomer
//

export interface CreateCustomerArgs {
	firstName: string;
	lastName: string;
	email: string;
	organization: string;
	address: string;
	note: string;
	phones: models.Phone[];
	birthday: string;
}

export type CreateCustomerResult =
	| GenericSuccess<{
			customer: models.Customer;
	  }>
	| GenericError;

//
// editCustomer
//

export interface CustomerEditArgs {
	customerId: number;
	address: string;
	email: string;
	firstName: string;
	lastName: string;
	phones: string[];
	organization: string;
	note: string;
	tags: string[];
	birthday: string;
}

export type CustomerEditResult =
	| GenericSuccess<{
			customer: models.Customer;
	  }>
	| GenericError;

//
// deleteCustomer
//

export interface CustomerDeleteArgs {
	customerId: number;
}

//
// getCustomerDetailsByProject
//

export interface ProjectCreateArgs {
	customerId: number;
	title: string;
	proposalDate: string;
	price: number;
	address: string;
}

export interface UpdateCustomerAvatarArgs {
	avatar: File;
	customerId: number;
}

export type UpdateCustomerAvatarResult =
	| GenericSuccess<{
			avatar: string;
	  }>
	| GenericError;

//
// importCustomers
//

export interface ImportCustomersArgs {
	files: FileList;
}

export type ImportCustomersResult =
	| GenericSuccess<{
			customerImport: models.CustomerImport;
	  }>
	| GenericError;

//***************
// REQUESTS
//***************

export const getCustomers = (args: CustomerListArgs = {}): Promise<GetCustomersResult> => {
	return post('customers/', args);
};

export const getCustomerDetails = (args: CustomerDetailsArgs): Promise<CustomerDetailsResult> => {
	return post('customer/details/', args);
};

export const createCustomer = (args: CreateCustomerArgs): Promise<CreateCustomerResult> => {
	return post('customer/create/', args);
};

export const editCustomer = (args: CustomerEditArgs): Promise<CustomerEditResult> => {
	return post('customer/edit/', args);
};

export const deleteCustomer = (args: CustomerDeleteArgs): Promise<GenericResult> => {
	return post('customer/remove/', args);
};

export const createProjectForCustomer = (args: ProjectCreateArgs): Promise<ProjectDetailsResult> => {
	return post('customer/create-project/', args);
};

export const importCustomers = (args: ImportCustomersArgs): Promise<ImportCustomersResult> => {
	const data = new FormData();
	for (let i = 0; i < args.files.length; i++) {
		data.append('file', args.files[i], args.files[i].name);
	}
	return postMultipart('customer/import/', data);
};

export const updateCustomerAvatar = (args: UpdateCustomerAvatarArgs): Promise<UpdateCustomerAvatarResult> => {
	const data = new FormData();
	data.append('avatar', args.avatar);
	data.append('customerId', `${args.customerId}`);
	return postMultipart('customer/avatar/update/', data);
};
