import React, { Component } from 'react';
import { Avatar, Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import Webcam from 'react-webcam';

import { getInitials } from 'utils/string';
import { getImageUrl, dataURLtoFile } from 'utils/image';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import EmptyState from 'components/EmptyState';

class CustomerAvatarModal extends Component {
	state = {
		capturing: false,
	};

	uploadAvatar = (file) => {
		const { customer, onAvatarUploaded, snackbarContext } = this.props;
		client
			.updateCustomerAvatar({ avatar: file, customerId: customer.id })
			.then((response) => {
				if (response.success) {
					onAvatarUploaded(response.data.avatar);
					snackbarContext.success('Customer picture updated successfully!');
				} else {
					snackbarContext.failure('Something went wrong');
				}
			})
			.catch(() => snackbarContext.failure('Something went wrong'));
	};

	handleFileChange = (e) => {
		this.uploadAvatar(e.target.files[0]);
	};

	renderCameraView = () => (
		<CameraScreenshot
			width={512}
			height={512}
			onCapture={(file) => {
				this.setState({ capturing: false }, () => this.uploadAvatar(file));
			}}
			onCancel={() => this.setState({ capturing: false })}
		/>
	);

	renderMainView = () => (
		<Grid container direction="row" alignItems="center">
			<Grid item xs={6}>
				<Avatar
					variant="square"
					style={{ width: '256px', height: '256px' }}
					src={getImageUrl(this.props.customer.avatar)}
				>
					{getInitials(this.props.customer.fullName)}
				</Avatar>
			</Grid>

			<Grid item xs={6}>
				<Grid container direction="column">
					<Button variant="contained" color="primary" component="label" disableElevation>
						Upload from computer
						<input accept="image/*" type="file" onChange={this.handleFileChange} hidden />
					</Button>

					<h5 className="hrule">or</h5>

					<Button
						variant="contained"
						color="info"
						component="label"
						disableElevation
						onClick={() => this.setState({ capturing: true })}
					>
						Use Camera
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);

	render() {
		const { isOpen, onClose } = this.props;

		return (
			<Dialog maxWidth="sm" fullWidth onClose={onClose} open={isOpen}>
				<DialogContent dividers>{this.state.capturing ? this.renderCameraView() : this.renderMainView()}</DialogContent>
			</Dialog>
		);
	}
}

const CameraScreenshot = ({ width, height, onCancel, onCapture }) => {
	const webcamRef = React.useRef(null);
	const [permissionDenied, setPermissionDenied] = React.useState(false);

	return (
		<Grid container alignItems="center" justify="center">
			{permissionDenied ? (
				<div
					style={{
						height: `${height}px`,
						width: `${width}px`,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<EmptyState name="es-broken-tab" title="Unable to access camera">
						Make sure Werkgo has access to your camera.
					</EmptyState>
				</div>
			) : (
				<Webcam
					audio={false}
					height={height}
					ref={webcamRef}
					screenshotFormat="image/jpeg"
					width={width}
					videoConstraints={{
						width: 1280,
						height: 720,
						facingMode: 'user',
					}}
					onUserMediaError={(error) => {
						if (error && error.name === 'NotAllowedError') {
							setPermissionDenied(true);
						}
					}}
				/>
			)}

			<Grid container justify="flex-end" style={{ width: `${width}px`, marginTop: '10px' }}>
				<Button variant="contained" color="info" disableElevation onClick={onCancel} style={{ marginRight: '10px' }}>
					Cancel
				</Button>

				<Button
					variant="contained"
					color="primary"
					disabled={permissionDenied}
					disableElevation
					onClick={() => {
						const imageSrc = webcamRef.current.getScreenshot();
						onCapture(dataURLtoFile(imageSrc, 'capture.jpeg'));
					}}
				>
					Capture
				</Button>
			</Grid>
		</Grid>
	);
};

export default withSnackbarContext(CustomerAvatarModal);
