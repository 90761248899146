import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid, Hidden, IconButton, Tooltip, Typography } from '@material-ui/core';
import ViewAgendaIcon from '@material-ui/icons/ViewAgendaOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfyOutlined';
import ViewDayIcon from '@material-ui/icons/ViewDayOutlined';
import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';

class Toolbar extends Component {
	renderViewOptions = () => {
		const { onViewChange, view } = this.props;

		return viewOptions.map(({ value, label, icon: Icon }) => {
			return (
				<Tooltip key={value} title={label}>
					<IconButton color={value === view ? 'primary' : 'default'} onClick={() => onViewChange(value)}>
						<Icon />
					</IconButton>
				</Tooltip>
			);
		});
	};

	render() {
		const { date, onDateNext, onDatePrev, onDateToday, ...rest } = this.props;

		return (
			<Grid alignItems="center" container justify="space-between" spacing={3} {...rest}>
				<Grid item>
					<ButtonGroup size="small">
						<Button onClick={onDatePrev}>Prev</Button>
						<Button onClick={onDateToday}>Today</Button>
						<Button onClick={onDateNext}>Next</Button>
					</ButtonGroup>
				</Grid>
				<Hidden smDown>
					<Grid item>
						<Typography variant="h3" color="textPrimary">
							{moment(date).format('MMMM YYYY')}
						</Typography>
					</Grid>
					<Grid item>{this.renderViewOptions()}</Grid>
				</Hidden>
			</Grid>
		);
	}
}

Toolbar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	date: PropTypes.instanceOf(Date).isRequired,
	onDateNext: PropTypes.func,
	onDatePrev: PropTypes.func,
	onDateToday: PropTypes.func,
	onViewChange: PropTypes.func,
	view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};

Toolbar.defaultProps = {
	onDateNext: () => {},
	onDatePrev: () => {},
	onDateToday: () => {},
	onViewChange: () => {},
};

const viewOptions = [
	{
		label: 'Month',
		value: 'dayGridMonth',
		icon: ViewConfigIcon,
	},
	{
		label: 'Week',
		value: 'timeGridWeek',
		icon: ViewWeekIcon,
	},
	{
		label: 'Day',
		value: 'timeGridDay',
		icon: ViewDayIcon,
	},
	{
		label: 'Agenda',
		value: 'listWeek',
		icon: ViewAgendaIcon,
	},
];

export default Toolbar;
