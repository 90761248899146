const commonStyles = {
	fontFamily: 'Sarabun',
	fontWeight: 500,
	color: '#011842',
};

export default {
	h1: {
		...commonStyles,
		fontSize: 35,
		letterSpacing: '-0.24px',
	},
	h2: {
		...commonStyles,
		fontSize: 29,
		letterSpacing: '-0.24px',
	},
	h3: {
		...commonStyles,
		fontSize: 24,
		letterSpacing: '-0.06px',
	},
	h4: {
		...commonStyles,
		fontSize: 20,
		letterSpacing: '-0.06px',
	},
	h5: {
		...commonStyles,
		fontSize: 16,
		letterSpacing: '-0.05px',
	},
	h6: {
		...commonStyles,
		fontSize: 14,
		letterSpacing: '-0.05px',
	},
	overline: {
		fontFamily: 'Sarabun',
		fontWeight: 500,
	},
};
