import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Drawer,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as Yup from 'yup';

import { Button } from 'components/common';
import { useCommonProps } from 'utils/form';
import * as client from 'api/client';

const messages = {
	failure: 'Something failed, please retry later.',
};

const CreateWorkerDrawer = ({ open, onWorkerAction, onClose, classes, workerToUpdate }) => (
	<Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant="temporary">
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				{!workerToUpdate ? 'Create worker' : 'Edit worker'}
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<WorkerForm onWorkerAction={onWorkerAction} workerToUpdate={workerToUpdate} />
		</Box>
	</Drawer>
);

const createWorkerAction = (values, actions, onWorkerAction) => {
	actions.setSubmitting(true);
	client
		.createWorker({
			firstName: values.firstName,
			lastName: values.lastName,
			phone: values.phone,
			email: values.email,
			address: values.address,
			allowLogin: values.allowLogin,
			isAdmin: values.isAdmin,
		})
		.then((response) => {
			if (response.success) {
				onWorkerAction(response.data.worker);
			} else {
				actions.setErrors({
					submit: response?.data?.message || messages.failure,
				});
			}
		})
		.catch((error) => {
			console.error(error);
			actions.setErrors({ submit: messages.failure });
		})
		.finally(() => {
			actions.setSubmitting(false);
		});
};

const editWorkerAction = (values, actions, onWorkerAction, worker) => {
	actions.setSubmitting(true);
	client
		.editWorker({
			userId: worker.id,
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phone: values.phone,
			address: values.address,
			allowLogin: values.allowLogin,
			isAdmin: values.isAdmin,
		})
		.then((response) => {
			if (response.success) {
				onWorkerAction(response.data.worker);
			} else {
				actions.setErrors({
					submit: response.data.message || messages.failure,
				});
			}
		})
		.catch((error) => {
			console.error(error);
			actions.setErrors({ submit: messages.failure });
		})
		.finally(() => {
			actions.setSubmitting(false);
		});
};

const WorkerForm = ({ onWorkerAction, workerToUpdate }) => (
	<Formik
		initialValues={{
			firstName: workerToUpdate?.firstName ?? '',
			lastName: workerToUpdate?.lastName ?? '',
			phone: workerToUpdate?.phone ?? '',
			email: workerToUpdate?.email ?? '',
			address: workerToUpdate?.address.address ?? '',
			allowLogin: workerToUpdate?.allowLogin ?? false,
			isAdmin: workerToUpdate?.isAdmin ?? false,
		}}
		validationSchema={Yup.object().shape({
			firstName: Yup.string().max(128).required(),
			lastName: Yup.string().max(128).nullable(),
			phone: Yup.string().max(32).nullable(),
			email: Yup.string()
				.max(254)
				.nullable()
				.when('allowLogin', {
					is: true,
					then: Yup.string().required('You need to specify an email to allow login.'),
				}),
			address: Yup.string().max(1024).nullable(),
			allowLogin: Yup.boolean(),
			isAdmin: Yup.boolean(),
		})}
		validateOnBlur={false}
		validateOnChange={false}
		onSubmit={(values, actions) => {
			if (!workerToUpdate) {
				createWorkerAction(values, actions, onWorkerAction);
			} else {
				editWorkerAction(values, actions, onWorkerAction, workerToUpdate);
			}
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<CreateWorkerFormFields
							values={values}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
							isSubmitting={isSubmitting}
							isCreating={!workerToUpdate}
							setFieldValue={setFieldValue}
						/>

						{errors.submit && (
							<Box>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="100%"
								height="50px"
							>
								{!workerToUpdate ? 'Create' : 'Edit'}
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const CreateWorkerFormFields = ({ values, onChange, onBlur, errors, touched, isSubmitting, setFieldValue }) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('firstName')} variant="outlined" label="First name (*)" autoFocus />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('lastName')} variant="outlined" label="Last name" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('phone')} variant="outlined" label="Phone" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField
					number
					fullWidth
					{...propsFn('email')}
					variant="outlined"
					label={values.allowLogin ? 'Email (*)' : 'Email'}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('address')} variant="outlined" label="Address" />
			</Grid>

			<Typography variant="h5" color="textPrimary" style={{ margin: '10px' }}>
				<LockOutlinedIcon fontSize="small" /> Permissions
			</Typography>

			<Grid item md={12} xs={12}>
				<FormControlLabel
					aria-label="Allow login"
					onClick={(event) => event.stopPropagation()}
					onFocus={(event) => event.stopPropagation()}
					control={<Checkbox checked={values.allowLogin} name="allowLogin" onChange={onChange} />}
					label="Allow login: the worker will be able to see only projects assigned to them."
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<FormControlLabel
					aria-label="Admin"
					onClick={(event) => event.stopPropagation()}
					onFocus={(event) => event.stopPropagation()}
					control={
						<Checkbox
							checked={values.isAdmin}
							name="isAdmin"
							onChange={(event) => {
								const { checked } = event.target;
								setFieldValue('isAdmin', checked);
								if (checked) {
									// Force allow login //
									setFieldValue('allowLogin', true);
								}
							}}
						/>
					}
					label="Make admin: allow this worker to access and modify everything in this company."
				/>
			</Grid>
		</Grid>
	);
};

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

export default withStyles(styles)(CreateWorkerDrawer);
