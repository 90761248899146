const styles = {
	root: {
		height: 60,
		borderRadius: 5,
		fontFamily: 'Sarabun',
		fontWeight: 700,
		fontSize: 16,
		textTransform: 'unset !important',
	},
	text: {
		backgroundColor: 'transparent !important',
	},
	textPrimary: {
		color: '#00B2A1',
		padding: 0,
		'& :hover': {
			color: '#00B2A1',
			backgroundColor: '#00B2A105',
		},
	},
	textSecondary: {
		color: '#ff3434',
	},
	containedPrimary: {
		backgroundColor: '#011842',
		'&:hover': {
			color: '#fff',
		},
	},
	outlinedPrimary: {
		color: '#00B2A1',
		borderColor: '#00B2A1',
		'&:hover': {
			color: '#00B2A1a0',
			borderColor: '#00B2A1a0',
		},
	},
	outlinedSecondary: {
		color: '#fff',
		borderColor: '#fff',
		'&:hover': {
			color: '#fff',
			borderColor: '#fff',
		},
	},
	loader: {
		position: 'absolute',
		right: 0,
		padding: 15,
		color: '#00B2A1',
	},
};

export default styles;
