const styles = {
	root: {
		height: 37,
		width: '100%',
		paddingBottom: 3,
		border: 0,
		backgroundColor: '#fff',
		boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 3px 4px -2px rgb(0 0 0 / 25%)',
		'& .MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
	},
	select: {
		width: 150,
		paddingTop: 2,
		paddingBottom: 0,
		backgroundColor: 'transparent !important',
	},
	menuPaper: {
		marginTop: 5,
		borderRadius: 0,
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4,
	},
	menuList: {
		padding: 0,
	},
	menuItem: {
		backgroundColor: '#fff !important',
		'& :hover': {
			color: '#00B2A190 !important',
		},
	},
	label: {
		width: '100%',
		height: '100%',
	},
	selected: {
		width: '100%',
		height: '100%',
		color: '#00B2A1 !important',
	},
	hidePlaceholder: {
		display: 'none',
	},
};

export default styles;
