import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { InvoiceStatusLabel } from 'utils/project';
import { Row, Column, Header, Content } from 'components/table';
import * as client from 'api/client';
import TitleSection from 'components/TitleSection';
import { displayAmount } from 'utils/format';

const HeaderInvoice = [
	{ text: '#', xs: 1 },
	{ text: 'Date', xs: 2 },
	{ text: 'Customer', xs: 5 },
	{ text: 'Status', xs: 2 },
	{ text: 'Amount', xs: 2 },
];

class InvoiceList extends Component {
	state = {
		invoices: [],
		isLoading: true,
	};

	componentDidMount = () => {
		client.getInvoices({}).then((response) => {
			const invoices = response.success ? response.data.invoices : [];
			this.setState({
				invoices,
				isLoading: false,
			});
		});
	};

	render() {
		const { invoices } = this.state;
		return (
			<>
				<TitleSection title={'Invoices'} />
				<Header headerInfo={HeaderInvoice} />
				{invoices.map(({ number, id, uuid, issuedDate, customer, status, total }) => (
					<Row key={id}>
						<Column justifyContent="flex-start" xs={1}>
							<Content component={Link} to={`/i/${uuid}?preview=1`} target="__blank">
								{number}
							</Content>
						</Column>

						<Column xs={2}>{moment(issuedDate).format('MMM DD, YYYY')}</Column>

						<Column xs={5}>
							<Content component={Link} to={`/customer/${customer.id}/details`}>
								{customer?.fullName}
							</Content>
						</Column>

						<Column xs={2}>
							<InvoiceStatusLabel status={status} />
						</Column>

						<Column xs={2} justifyContent="flex-end">
							{displayAmount(total / 100)}
						</Column>
					</Row>
				))}
			</>
		);
	}
}

export default InvoiceList;
