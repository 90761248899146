export const ClientInvoiceStyles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '100%',
		textAlign: 'center',
		margin: 'auto',
	},
	paper: {
		padding: '20px',
		width: '1024px',
		maxWidth: '100%',
		minHeight: '100vh',
	},
	payment: {
		padding: '20px',
		marginTop: '20px',
	},
	titleCls: {
		marginBottom: '20px',
		fontWeigth: 'bold',
	},
	boldTitles: {
		textAlign: 'start',
		paddingBottom: 5,
		fontWeight: 'bold',
	},
	topBanner: {
		padding: '0px 15px 0px 15px',
		marginBottom: '20px',
	},
	mobileTopBanner: {
		display: 'flex',
		justifyContent: 'center',
	},
	mainContent: {
		padding: '15px',
	},
	header: {
		fontWeight: 'bold',
	},
	table: {
		margin: '15px 0px 15px 0px',
	},
	mobileTableItem: {
		height: '55px',
	},
	invoiceInfoTableRightAlign: {
		'& td': {
			textAlign: 'right',
		},
		'& td:last-child': {
			textAlign: 'left',
		},
	},
	invoiceInfoTableSpaceBetweenAlign: {
		width: '100%',
		'& td': {
			textAlign: 'left',
		},
		'& td:last-child': {
			textAlign: 'right',
		},
	},
	termsAndConditions: {
		padding: '15px 0px 15px 0px',
	},
	customerInfo: {
		marginTop: '15px',
	},
	totals: {
		display: 'flex',
		paddingTop: '15px',
		justifyContent: 'end',
	},
	payments: {
		display: 'flex',
		paddingTop: '15px',
		justifyContent: 'end',
	},
	paymentSection: {
		padding: '25px 0px 25px 0px',
	},
};

export const CheckoutButtonStyle = {
	marginTop: '25px',
	color: 'white',
	fontSize: '17px',
	fontWeight: 550,
};
