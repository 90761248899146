import { TaskPriority, TaskStatus } from 'types/enums';

// Options to be used in a <select /> component.
export const taskPriorityOptions = [
	{ label: 'Low', value: TaskPriority.Low },
	{ label: 'Medium', value: TaskPriority.Medium },
	{ label: 'High', value: TaskPriority.High },
];

export const taskPriorityFilters = [{ label: 'All', value: -1 }, ...taskPriorityOptions];

export const taskStatusOptions = [
	{ label: 'Undone', value: TaskStatus.Undone },
	{ label: 'Done', value: TaskStatus.Done },
];

export const taskStatusFilters = [{ label: 'All', value: -1 }, ...taskStatusOptions];

// Information needed to render the corresponding
// priority badge for a task.
export const taskPriorityInfo = {
	[TaskPriority.Low]: {
		label: 'Low',
		color: '#929292',
	},
	[TaskPriority.Medium]: {
		label: 'Medium',
		color: '#00a997',
	},

	[TaskPriority.High]: {
		label: 'High',
		color: '#ff5353',
	},
};
