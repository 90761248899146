import React, { Component } from 'react';
import { Row, Column, Header } from 'components/table';
import { withAuthContext } from 'context/AuthContext';
import * as client from 'api/client';
import TitleSection from 'components/TitleSection';
import { Add as AddIcon, Info as InfoIcon } from '@material-ui/icons';
import { relativeTime } from 'utils/time';
import UploadButton from 'components/uploadButton';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { Tooltip, IconButton } from '@material-ui/core';

const headerInfo = [
	{ text: 'Import file name', xs: 9 },
	{ text: 'Uploaded', xs: 3 },
];

class CustomersImport extends Component {
	state = { imports: [] };

	handleUploadFiles = (files) => {
		const { snackbarContext } = this.props;
		client
			.importCustomers({ files })
			.then((response) => {
				if (response.success) {
					this.setState((prevState) => ({
						imports: [response.data.customerImport, ...prevState.imports],
					}));
					snackbarContext.success('Uploading your customers, check back in a minute.');
				}
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure('Error uploading files');
			});
	};

	componentDidMount = () => {
		client.getCustomerImports().then((response) => {
			if (response.success) {
				this.setState({ imports: response.data.imports });
			}
		});
	};

	render() {
		const { imports } = this.state;

		return (
			<>
				<TitleSection title="Import customers">
					<div>
						<Tooltip title="Use CSV/VCF files">
							<IconButton>
								<InfoIcon />
							</IconButton>
						</Tooltip>

						<UploadButton
							onUploadFiles={this.handleUploadFiles}
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							height={38}
							acceptFiles=".csv,.vcf"
							singleFile={true}
						>
							Import customer
						</UploadButton>
					</div>
				</TitleSection>

				<Header headerInfo={headerInfo} />

				{imports.map((element) => (
					<Row key={element.id}>
						<Column xs={9} justifyContent="flex-start">
							{element.name}
						</Column>
						<Column xs={3} justifyContent="flex-end">
							{relativeTime(element.createdTime)}
						</Column>
					</Row>
				))}

				{!imports.length && (
					<Row>
						<Column xs={12}>No imports</Column>
					</Row>
				)}
			</>
		);
	}
}

export default withSnackbarContext(withAuthContext(CustomersImport));
