import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Hidden, IconButton, withStyles } from '@material-ui/core';
import { Dehaze } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { withAuthContext } from 'context/AuthContext';
import { Button } from 'components/common';
import Search from 'modules/GlobalSearch';
import styles from 'components/bars/AppBar/styles';

const showHeaderButtons = ['/home'];

class AppBar extends Component {
	render() {
		const {
			toggleMobileNavBar,
			showNavBar,
			authContext: { token },
			classes,
		} = this.props;

		return (
			<Box
				zIndex={1000}
				position="fixed"
				width="100%"
				height="80px"
				bgcolor="#011842"
				p="0 60px"
				className={classes.bar}
			>
				<Grid
					container
					direction="row"
					wrap="nowrap"
					alignItems="center"
					justify="space-between"
					className={classes.container}
				>
					{showNavBar && (
						<Hidden mdUp>
							<IconButton onClick={toggleMobileNavBar}>
								<Dehaze htmlColor="#ffff" />
							</IconButton>
						</Hidden>
					)}
					<Grid item>
						<Button style={{ border: '0px' }} component={Link} to="/" variant="outlined" disableRipple>
							<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						</Button>
					</Grid>
					{showHeaderButtons.includes(window.location.pathname) ? (
						<Grid item>
							<Button
								component={Link}
								to={token ? '/' : '/signin'}
								variant="outlined"
								color="secondary"
								height={36}
								width={120}
							>
								{token ? 'Home' : 'Sign In'}
							</Button>
						</Grid>
					) : (
						''
					)}
					{token && showNavBar ? <Search /> : ''}
				</Grid>
			</Box>
		);
	}
}

AppBar.propTypes = {
	toggleMobileNavBar: PropTypes.func,
	showNavBar: PropTypes.bool,
};

export default withStyles(styles)(withAuthContext(AppBar));
