const styles = {
	title: {
		fontWeight: 600,
	},
	twoLines: {
		whiteSpace: 'break-spaces',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	},
	link: {
		color: '#011842',
	},
	statusContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	statusText: {
		color: '#FFF',
	},
};

export default styles;
