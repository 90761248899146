import React from 'react';
import { Box, Card, Button, CardContent, Container, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';
import { getMobileOperatingSystem } from 'utils/mobile';
import { AndroidStoreLink, iOSStoreLink } from 'constant';

const getDownloadLink = () => (getMobileOperatingSystem() === 'Android' ? AndroidStoreLink : iOSStoreLink);

const Install = () => (
	<BarnWrapper title="Install Mobile App | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" gutterBottom variant="h2">
							User created!
						</Typography>
						<Typography variant="body2" color="textSecondary">
							Please download Werkgo mobile app and login.
						</Typography>
					</Box>
					<Box flexGrow={1} mt={2}>
						<Button href={getDownloadLink()} fullWidth size="large" variant="outlined" color="primary">
							Download
						</Button>
					</Box>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default Install;
