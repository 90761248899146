const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	calendar: {
		backgroundColor: '#FFFFFF',
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		'& .fc-unthemed .fc-head': {
			backgroundColor: theme.palette.background.dark,
		},
		'& .fc-unthemed .fc-body': {
			backgroundColor: theme.palette.background.default,
		},
		'& .fc-unthemed .fc-row': {
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed .fc-axis': {
			...theme.typography.body2,
		},
		'& .fc-unthemed .fc-divider': {
			backgroundColor: theme.palette.background.dark,
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed th': {
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed td': {
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed td.fc-today': {
			backgroundColor: theme.palette.background.dark,
		},
		'& .fc-unthemed .fc-highlight': {
			backgroundColor: theme.palette.background.dark,
		},
		'& .fc-unthemed .fc-event': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			borderWidth: 2,
			opacity: 0.9,
			'& .fc-time': {
				...theme.typography.h6,
				color: 'inherit',
			},
			'& .fc-title': {
				...theme.typography.body1,
				color: 'inherit',
			},
		},
		'& .fc-unthemed .fc-day-top': {
			...theme.typography.body2,
		},
		'& .fc-unthemed .fc-day-header': {
			...theme.typography.subtitle2,
			fontWeight: theme.typography.fontWeightMedium,
			color: theme.palette.text.secondary,
			padding: theme.spacing(1),
			backgroundColor: theme.palette.background.dark,
		},
		'& .fc-unthemed .fc-list-view': {
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed .fc-list-empty': {
			...theme.typography.subtitle1,
		},
		'& .fc-unthemed .fc-list-heading td': {
			backgroundColor: theme.palette.background.dark,
			borderColor: theme.palette.divider,
		},
		'& .fc-unthemed .fc-list-heading-main': {
			...theme.typography.h6,
		},
		'& .fc-unthemed .fc-list-heading-alt': {
			...theme.typography.h6,
		},
		'& .fc-unthemed .fc-list-item:hover td': {
			backgroundColor: theme.palette.background.dark,
		},
		'& .fc-unthemed .fc-list-item-title': {
			...theme.typography.body1,
		},
		'& .fc-unthemed .fc-list-item-time': {
			...theme.typography.body2,
		},
	},
});

export default styles;
