import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import { ClientInvoiceStyles } from './style';
import { displayAmount } from 'utils/format';

const WebTable = withStyles(ClientInvoiceStyles)(({ items, classes }) => (
	<TableContainer className={classes.table}>
		<Table style={{ border: 'none' }} sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell className={classes.header}>Items</TableCell>
					<TableCell className={classes.header} align="right">
						Quantity
					</TableCell>
					<TableCell className={classes.header} align="right">
						Price
					</TableCell>
					<TableCell className={classes.header} align="right">
						Amount
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((item) => {
					return (
						<TableRow key={`item-${item.id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								{item.name}
							</TableCell>
							<TableCell align="right">{parseFloat(item.quantity).toFixed(2)}</TableCell>
							<TableCell align="right">{displayAmount(item.price)}</TableCell>
							<TableCell align="right">{displayAmount(item.price * item.quantity)}</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	</TableContainer>
));

export default WebTable;
