import React from 'react';

import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Drawer,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { Formik } from 'formik';
import { DateTimePicker } from '@material-ui/pickers';
import { HighlightOffOutlined } from '@material-ui/icons';
import * as _ from 'lodash';
import * as Yup from 'yup';

import { Button } from 'components/common';
import { DateTimeFormat } from 'constant';
import { useCommonProps } from 'utils/form';
import EventColorPicker from 'modules/Event/EventColorPicker';
import EventNameSuggestions from 'components/suggestions/EventNameSuggestions';

const messages = {
	failure: 'Something failed! Modify the values and try again.',
};

/**
 * Component that renders a drawer to either edit or create a project event. Heavy
 * lifting is performed by the parent components since EventFormDrawer's main goal
 * is to notify the parent about the new event values we would like to submit. The
 * parent should handle such intent depending of the context, either creating a new
 * event or editing a selected one.
 *
 * @param {string} drawerTitle What title should be displayed at the top of the
 * drawer.
 * @param {string} submitButtonLabel What label should be displayed for the submit
 * form button.
 * @param {boolean} companyAllowSmsReminders True if the company is allowed to send SMSs.
 * @param {boolean} isOpen Whether this drawer must render open or not.
 * @param {function} onClose Function called if the drawer intend to close (parent
 * component must handle properly in order to close this drawer).
 * @param {string} title Initial event title.
 * @param {Date} startTime Initial event start time.
 * @param {Date} endTime Initial event end time.
 * @param {string} color A hexadecimal representation of the event color.
 * @param {boolean} allowSmsReminder True if a reminder should be sent to the
 * corresponding customer 24 hours before.
 * @param {function} onSubmit Function that receives an object ({title, startTime,
 * endTime}) with the values that require submition. This function will return a
 * Promise with the backend response or fail.
 * @param {boolean} showTitleSuggestions If true, the form will render a list of most
 * used event titles under the title input field.
 *
 * @returns <EventFormDrawer />
 */
const EventFormDrawer = ({
	drawerTitle,
	submitButtonLabel,
	companyAllowSmsReminders,
	isOpen,
	onClose,
	title,
	startTime,
	endTime,
	allowSmsReminder,
	color,
	onSubmit,
	classes,
	showTitleSuggestions,
}) => (
	<Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={isOpen} variant="temporary">
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				{drawerTitle}
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<EventForm
				submitButtonLabel={submitButtonLabel}
				companyAllowSmsReminders={companyAllowSmsReminders}
				title={title}
				startTime={startTime}
				endTime={endTime}
				color={color}
				allowSmsReminder={allowSmsReminder}
				onSubmit={onSubmit}
				showTitleSuggestions={showTitleSuggestions}
			/>
		</Box>
	</Drawer>
);

const EventFormFields = ({
	values,
	onChange,
	onBlur,
	errors,
	touched,
	isSubmitting,
	setFieldValue,
	companyAllowSmsReminders,
	showTitleSuggestions,
}) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField
					fullWidth
					{...propsFn('title')}
					label="Title"
					variant="outlined"
					placeholder="Estimate, Installation, Followup, etc."
				/>
			</Grid>

			{showTitleSuggestions && (
				<EventNameSuggestions
					onSelect={(name) => {
						setFieldValue('title', name);
					}}
				/>
			)}

			<Grid item md={12} xs={12}>
				<DateTimePicker
					{...propsFn('startTime')}
					format={DateTimeFormat}
					fullWidth
					inputVariant="outlined"
					label="Start time"
					minutesStep={5}
					variant="inline"
					autoOk={true}
					onChange={(date) => {
						// First, calculate the delta between the previous start and
						// end times in order to "shift" the new end time relative to
						// the new start time.
						let deltaMs = 60 * 60 * 1000;
						if (values['endTime']) {
							deltaMs = new Date(values['endTime']) - new Date(values['startTime']);
						}

						setFieldValue('startTime', date);
						setFieldValue('endTime', date.clone().add(deltaMs, 'ms'));
					}}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<DateTimePicker
					{...propsFn('endTime')}
					format={DateTimeFormat}
					fullWidth
					inputVariant="outlined"
					label="End time"
					minutesStep={5}
					variant="inline"
					autoOk={true}
					onChange={(date) => setFieldValue('endTime', date)}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<EventColorPicker color={values['color']} onChange={(color) => setFieldValue('color', color)} />
			</Grid>

			{/* Do not render the reminder checkbox if the company is not allowed to send SMSs */}
			{companyAllowSmsReminders && (
				<Grid item md={12} xs={12}>
					<FormControlLabel
						aria-label="Send SMS reminder"
						onClick={(event) => event.stopPropagation()}
						onFocus={(event) => event.stopPropagation()}
						control={<Checkbox checked={values['allowSmsReminder']} name="allowSmsReminder" onChange={onChange} />}
						label="Send SMS reminder to customer 24 hours before."
					/>
				</Grid>
			)}
		</Grid>
	);
};

const EventForm = ({
	submitButtonLabel,
	companyAllowSmsReminders,
	title,
	startTime,
	endTime,
	color,
	allowSmsReminder,
	onSubmit,
	showTitleSuggestions,
}) => (
	<Formik
		validateOnChange={false}
		validateOnBlur={false}
		initialValues={{ title, startTime, endTime, color, allowSmsReminder }}
		validationSchema={Yup.object().shape({
			title: Yup.string().max(512).required('A title is required to create this event.'),
			startTime: Yup.date(),
			endTime: Yup.date().min(Yup.ref('startTime'), "End date can't be before start date"),
		})}
		onSubmit={(values, actions) => {
			actions.setSubmitting(true);
			onSubmit(_.pick(values, ['title', 'startTime', 'endTime', 'color', 'allowSmsReminder']))
				.then((response) => {
					if (!response.success) {
						actions.setErrors({
							submit: response?.data?.message || messages.failure,
						});
					}
				})
				.catch(() => {
					actions.setErrors({ submit: messages.failure });
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<EventFormFields
							companyAllowSmsReminders={companyAllowSmsReminders}
							errors={errors}
							isSubmitting={isSubmitting}
							onBlur={handleBlur}
							onChange={handleChange}
							setFieldValue={setFieldValue}
							touched={touched}
							values={values}
							showTitleSuggestions={showTitleSuggestions}
						/>

						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="100%"
								height="50px"
							>
								{submitButtonLabel}
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const styles = {
	drawer: {
		width: 500,
		maxWidth: '100%',
	},
};

export default withStyles(styles)(EventFormDrawer);
