import React, { Component } from 'react';
import { create } from 'jss';
import { Box, withStyles } from '@material-ui/core';
import { jssPreset, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SharedSnackbarProvider } from 'context/SnackbarsContext';
import Routes from './routes';
import { createTheme } from 'theme';
import GlobalStyles from 'theme/GlobalStyles';
import { AppStyles } from 'styles/main';
import { scroll } from 'styles/shared';
import MomentUtils from '@date-io/moment';
import { routes } from 'routes';
import { matchPath, withRouter } from 'react-router';
import AppAndNavBars from 'components/bars/index';

class App extends Component {
	render() {
		const { location, classes } = this.props;
		const theme = createTheme({
			responsiveFontSizes: true,
			theme: 'LIGHT',
		});

		const jss = create({ plugins: [...jssPreset().plugins] });

		const { appBar: showAppBar, navBar: showNavBar } = routes.find(
			(r) => matchPath(location.pathname, r.path)?.isExact
		) ?? {
			appBar: true,
			navBar: false,
			chatBot: false,
		};

		return (
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<SharedSnackbarProvider>
							<GlobalStyles />

							<AppStyles>
								<AppAndNavBars showAppBar={showAppBar} showNavBar={showNavBar} />

								<Box
									display={showAppBar ? 'block' : 'contents'}
									height={showAppBar ? 'calc(100vh - 80px)' : '100%'}
									width="100%"
									mt={showAppBar ? '80px' : 0}
									pr={showNavBar ? '10px' : 0}
									mr={showNavBar ? '10px' : 0}
									className={showAppBar && showNavBar ? classes.container : null}
								>
									<Routes setShowBars={this.setShowBars} />
								</Box>
							</AppStyles>
						</SharedSnackbarProvider>
					</MuiPickersUtilsProvider>
				</StylesProvider>
			</ThemeProvider>
		);
	}
}

const styles = (theme) => ({
	container: {
		...scroll,
		[theme.breakpoints.down('xs')]: {
			marginRight: 0,
			paddingRight: 0,
		},
	},
});

export default withStyles(styles)(withRouter(App));
