const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		width: '100%',
	},
	featuresTitle: {
		fontSize: 40,
		fontWeight: 700,
		marginBottom: 10,
	},
	featuresSubtitle: {
		color: '#5A607F',
		fontWeight: 400,
		width: 700,
	},
	experienceContainer: {
		padding: '0 200px 0 250px',
		[theme.breakpoints.down('lg')]: {
			padding: '0 100px 0 200px',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0 50px 0 20px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 20px',
		},
	},
	experienceTextContainer: {
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
		},
	},
	experienceText: {
		color: '#272933',
		fontWeight: 400,
		marginBottom: 30,
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
	experienceTitle: {
		fontSize: 40,
		fontWeight: 700,
		color: '#011842',
		marginBottom: 20,
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
	playImageContainer: {
		textAlign: 'right',
	},
	playImage: {
		height: 480,
		width: 480,
		[theme.breakpoints.down('md')]: {
			height: 400,
			width: 400,
		},
		[theme.breakpoints.down('md')]: {
			marginTop: 50,
			height: 300,
			width: 300,
		},
	},
	buyAppSectionContainer: {
		[theme.breakpoints.down('md')]: {
			padding: '0 20px',
		},
	},
});

export default styles;
