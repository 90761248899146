import React, { Component } from 'react';
import { Add as AddIcon, Send as SendIcon, MoreVert } from '@material-ui/icons';
import { Box, IconButton, CircularProgress, Grid } from '@material-ui/core';

import { Button, Menu } from 'components/common';
import { DefaultPageSize } from 'utils/search';
import { Search } from 'components/filters';
import { withIsMobile } from 'hoc/IsMobileHOC';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import CreateWorkerDrawer from 'modules/Worker/Create/index';
import InviteModal from 'modules/Worker/List/InviteModal';
import Pagination from 'components/pagination';
import Table from 'modules/Worker/List/Table';
import TitleSection from 'components/TitleSection';
import ConfirmationAlert from 'components/confirmationAlert';

const messages = {
	workers: 'Workers',
	addWorker: 'Add worker',
	somethingWentWrong: 'Something went wrong!',
	sendInviteEmail: 'Send invite email',
	workerDeletedSuccesfully: 'Worker deleted succesfully!',
};

class CustomerList extends Component {
	state = {
		// List of current workers rendered in the table.
		workers: [],

		isSendingInvitation: false,

		// True whenever we are waiting for an action/request to
		// finish (e.g initial loading, search,  go to a different
		// page, etc.)
		isLoading: false,

		// Total number of filtered workers that the current user
		// can navigate to using the pagination footer. Used to
		// calculate the total number of pages.
		total: 0,

		// Pagination object containing the number of elements per
		// page that will be rendered (`limit`) and what is the index
		// of the very first element to render (`start`). The index
		// are 0-based and `limit` can be 5, 10, or 20.
		pagination: {
			start: 0,
			limit: DefaultPageSize,
		},

		// Search parameter to filter down workers. This query will be
		// used to search for names, address, and worker phones. No fancy
		// search is done at the server side, just substrings searching.
		search: {
			query: '',
		},

		open: false,
		workerToUpdate: null,
		workerToDelete: null,
	};

	componentDidMount = () => {
		this.fetchWorkers();
	};

	//----------------------------------------------------------------------
	// List worker helpers
	//----------------------------------------------------------------------

	fetchWorkersInternal = () => {
		const { pagination, search } = this.state;

		client
			.getWorkers({ pagination, search })
			.then((response) => {
				if (response.success) {
					const { workers, total } = response.data;
					this.setState({ workers, total });
				}
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				snackbarContext.failure(messages.somethingWentWrong);

				console.error(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	fetchWorkers = () => {
		this.setState({ isLoading: true }, () => this.fetchWorkersInternal());
	};

	//----------------------------------------------------------------------
	// Pagination & filtering
	//----------------------------------------------------------------------

	handlePaginationLimitChanged = (limit) => {
		this.setState({ pagination: { start: 0, limit } }, () => this.fetchWorkers());
	};

	handlePageChanged = (page) => {
		this.setState(
			({ pagination: { limit } }) => ({
				pagination: { start: (page - 1) * limit, limit },
			}),
			() => this.fetchWorkers()
		);
	};

	handleQueryChanged = (query) => {
		this.setState(
			({ pagination: { limit } }) => ({
				search: { query },
				pagination: { start: 0, limit },
			}),
			() => this.fetchWorkers()
		);
	};

	//----------------------------------------------------------------------
	// Create worker helpers
	//----------------------------------------------------------------------

	handleWorkerAction = (worker) => {
		const { workerToUpdate, workers } = this.state;
		if (workerToUpdate === null) {
			this.setState((previousState) => ({
				workers: [worker, ...previousState.workers],
				open: false,
			}));
		} else {
			const updatedWorkers = workers.map((elem) => {
				if (elem.id === worker.id) {
					return worker;
				}
				return elem;
			});

			this.setState({
				workers: updatedWorkers,
				open: false,
				workerToUpdate: null,
			});
		}
	};

	handleWorkerUpdate = (worker) => {
		const currentOpenValue = this.state.open;
		this.setState({
			open: !currentOpenValue,
			workerToUpdate: currentOpenValue ? null : worker,
		});
	};

	renderHeaderForMobile = () => {
		return (
			<Menu
				items={[
					{
						value: 'send-invite-email',
						label: messages.sendInviteEmail,
						onClick: () => this.setState({ isSendingInvitation: true }),
					},
					{
						value: 'add-worker',
						label: messages.addWorker,
						onClick: () => this.setState({ open: true }),
					},
				]}
				button={
					<IconButton>
						<MoreVert />
					</IconButton>
				}
			/>
		);
	};

	renderHeaderForWeb = () => {
		return (
			<div style={{ marginLeft: 'auto' }}>
				<Button
					onClick={() => this.setState({ isSendingInvitation: true })}
					variant="contained"
					color="primary"
					startIcon={<SendIcon />}
					height={38}
					style={{ marginRight: '10px' }}
				>
					{messages.sendInviteEmail}
				</Button>

				<Button
					onClick={() => this.setState({ open: true })}
					variant="contained"
					color="primary"
					startIcon={<AddIcon />}
					height={38}
				>
					{messages.addWorker}
				</Button>
			</div>
		);
	};

	handleInviteWorker = (newWorker) => {
		if (newWorker) {
			const { workers } = this.state;
			this.setState({
				isSendingInvitation: false,
				workers: [...workers, newWorker],
			});
		} else {
			this.setState({ isSendingInvitation: false });
		}
	};

	//----------------------------------------------------------------------
	// Deletion
	//----------------------------------------------------------------------

	handleDeleteWorkerIntent = (worker) => {
		this.setState({ workerToDelete: worker });
	};

	removeSelectedWorker = () => {
		const { id: workerId } = this.state.workerToDelete;
		this.setState({ workerToDelete: null }, () => {
			const { snackbarContext } = this.props;

			client
				.deleteWorker({ workerId })
				.then((response) => {
					if (response.success) {
						snackbarContext.success(messages.workerDeletedSuccesfully);
						this.setState(({ workers }) => ({
							workers: workers.filter(({ id }) => id !== workerId),
						}));
					} else {
						snackbarContext.failure(response.data.message || messages.somethingWentWrong);
					}
				})
				.catch((error) => {
					console.error(error);
					snackbarContext.failure(messages.somethingWentWrong);
				});
		});
	};

	render = () => {
		const { isMobile } = this.props;
		const { workers, total, pagination, open, isLoading, isSendingInvitation, workerToUpdate, workerToDelete } =
			this.state;

		const pages = Math.ceil(total / pagination.limit);
		const currentPage = Math.floor(pagination.start / pagination.limit) + 1;

		return (
			<>
				<InviteModal handleClose={this.handleInviteWorker} open={isSendingInvitation} />

				{!!workerToDelete && (
					<ConfirmationAlert
						openAlert={true}
						onCancelation={() => this.setState({ workerToDelete: null })}
						onConfirmation={() => this.removeSelectedWorker()}
						message={`Are you sure you want to delete "${workerToDelete.fullName}"?`}
					/>
				)}

				<CreateWorkerDrawer
					onWorkerAction={this.handleWorkerAction}
					workerToUpdate={workerToUpdate}
					open={open}
					onClose={() => this.setState({ open: false, workerToUpdate: null })}
				/>

				<TitleSection title={messages.workers}>{isMobile && this.renderHeaderForMobile()}</TitleSection>

				<Box mt="20px" mb="20px">
					<Grid container wrap="nowrap">
						<Search
							placeholder="Search workers"
							onQueryChange={this.handleQueryChanged}
							disabled={isLoading}
							fullWidth={isMobile}
						/>
						{!isMobile && this.renderHeaderForWeb()}
					</Grid>
				</Box>

				{/* Render table or lading screen */}

				{isLoading ? (
					<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
						<CircularProgress size={60} />
					</Box>
				) : (
					<>
						<Table
							workers={workers}
							onUpdateWorker={this.handleWorkerUpdate}
							onDeleteWorkerIntent={this.handleDeleteWorkerIntent}
						/>

						<Box mt="40px">
							<Pagination
								currentPage={currentPage}
								pages={pages}
								itemsPerPage={pagination.limit}
								onChangeItemsPerPage={this.handlePaginationLimitChanged}
								goToPage={this.handlePageChanged}
							/>
						</Box>
					</>
				)}
			</>
		);
	};
}

export default withIsMobile(withSnackbarContext(CustomerList));
