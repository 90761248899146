const styles = (theme) => ({
	container: {
		backgroundColor: '#1FC9B909',
		padding: 100,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: 30,
		},
	},
	textContainer: {
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
		},
	},
	title: {
		color: '#011842',
		fontWeight: 700,
		fontSize: 50,
		lineHeight: '60px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	subtitle: {
		marginTop: 10,
		marginBottom: 30,
		color: '#5A607F',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
});

export default styles;
