import React from 'react';
import { TableContainer, TableBody, TableRow, TableHead, TableCell, Table, withStyles } from '@material-ui/core';
import { ClientInvoiceStyles } from './style';
import { InvoicePaymentMethod } from 'types/enums';
import { displayAmount } from 'utils/format';
import { withIsMobile } from 'hoc/IsMobileHOC';

const WebInvoicePaymentsSection = ({ invoice, classes, isMobile }) => (
	<div className={classes.totals} style={{ padding: 0, paddingBottom: '15px' }}>
		<TableContainer className={classes.table} style={{ width: isMobile ? '100%' : 'auto', padding: 0, margin: 0 }}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell className={classes.header} colSpan={3}>
							Payments
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{invoice.payments.map((payment) => (
						<TableRow key={`payment-${payment.id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell>{InvoicePaymentMethod[payment.paymentMethod]}</TableCell>
							<TableCell>{payment.issuedDate}</TableCell>
							<TableCell>{displayAmount(payment.amount / 100)}</TableCell>
						</TableRow>
					))}
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell colSpan={2}>
							<strong>Total paid</strong>
						</TableCell>
						<TableCell>{displayAmount(invoice.amountPaid / 100)}</TableCell>
					</TableRow>
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell colSpan={2}>
							<strong>Amount due</strong>
						</TableCell>
						<TableCell>{displayAmount(invoice.amountDue / 100)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	</div>
);

export default withStyles(ClientInvoiceStyles)(withIsMobile(WebInvoicePaymentsSection));
