import { post, GenericSuccess, GenericError } from 'api/core';
import * as models from 'types/models/models';

//----------------------------------------------------------------------
// Get Event Colors
//----------------------------------------------------------------------

export type GetEventColorsResult =
	| GenericSuccess<{
			colors: models.EventColor[];
	  }>
	| GenericError;

export const getEventColors = (): Promise<GetEventColorsResult> => {
	return post('eventcolor/list/', {});
};
