import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Select as MuiSelect, MenuItem, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import styles from 'components/common/styles/select';

class Select extends Component {
	state = {
		showPlaceholder: true,
	};

	render() {
		const { items, value: menuValue, placeholder, onChange, disabled, height, classes } = this.props;
		const { showPlaceholder } = this.state;

		return (
			<MuiSelect
				defaultValue="none"
				variant="outlined"
				value="none"
				onFocus={() => this.setState({ showPlaceholder: false })}
				onClose={() => this.setState({ showPlaceholder: false })}
				onChange={(e) => onChange(e.target.value)}
				disabled={disabled}
				MenuProps={{
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					classes: { paper: classes.menuPaper, list: classes.menuList },
				}}
				classes={{ select: classes.select }}
				className={classes.root}
				style={{ height }}
			>
				<MenuItem
					value="none"
					key="none"
					className={classNames(classes.menuItem, showPlaceholder ? null : classes.hidePlaceholder)}
				>
					<Typography variant="h5" style={{ width: '100%' }}>
						{placeholder}
					</Typography>
				</MenuItem>
				{items.map(({ value, label }) => (
					<MenuItem key={value} value={value} classes={{ root: classes.menuItem }} className={classes.menuItem}>
						<Typography variant="h5" className={menuValue === value ? classes.selected : classes.label}>
							{label}
						</Typography>
					</MenuItem>
				))}
			</MuiSelect>
		);
	}
}

Select.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		})
	).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(Select);
