import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import { AuthProvider } from 'context/AuthContext';
import * as FullStory from '@fullstory/browser';
import { IsProduction } from 'constant';

// Initialize fullstory to start recording user actions. In order to
// reduce the number of sessions used, we are disabling fullstory in
// development.
FullStory.init({ orgId: '17ZAJB', devMode: !IsProduction });

const app = document.createElement('div');
document.body.appendChild(app);
document.title = 'WerkGo';

ReactDOM.render(
	<BrowserRouter>
		<AuthProvider>
			<App />
		</AuthProvider>
	</BrowserRouter>,
	app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
