const styles = (theme) => ({
	bar: {
		[theme.breakpoints.down('sm')]: {
			padding: '0 5px',
		},
	},
	container: {
		height: '100%',
	},
	logoContainer: {
		[theme.breakpoints.down('sm')]: {
			padding: '0 5px',
			justifyContent: ({ showNavBar }) => (showNavBar ? 'center' : 'flex-start'),
		},
	},
	werkgoText: {
		color: '#fff',
		fontWeight: 800,
		marginLeft: 10,
	},
});

export default styles;
