import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import { ellipsisText } from 'styles/shared';

const styles = {
	content: {
		fontWeight: 400,
		color: '#000',
	},
	ellipsis: {
		...ellipsisText,
	},
};

const Content = ({ children, component, href, classes, ...props }) => (
	<Typography
		variant="h5"
		title={children}
		component={children && component}
		href={children && href}
		className={classNames(classes.ellipsis, classes.content)}
		{...props}
	>
		{children || '---'}
	</Typography>
);

export default withStyles(styles)(Content);
