import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@material-ui/core';
import JWTSignUp from 'components/auth/JWTSignUp';
import BarnWrapper from 'components/auth/BarnWrapper';

const Signup = ({
	match: {
		params: { invitationToken },
	},
}) => (
	<BarnWrapper title="Sign Up | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" gutterBottom variant="h2">
							Sign Up in Werkgo
						</Typography>
					</Box>

					<JWTSignUp isInvite={!!invitationToken} />

					<Box my={3}>
						<Divider />
					</Box>

					{!invitationToken && (
						<Link component={RouterLink} to="/signin" variant="body2" color="textSecondary">
							Having an account
						</Link>
					)}
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default Signup;
