import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link as MuiLink, withStyles, Box, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Row, Column, Header, Content } from 'components/table';
import Actions from 'components/common/Actions';
import styles from 'modules/Customer/List/styles/table';
import { getInitials } from 'utils/string';
import { getImageUrl } from 'utils/image';
import * as ls from 'utils/localStorage';
import NoCustomersEmptyState from 'modules/Customer/List/NoCustomersEmptyState';

const headerInfo = [
	{ text: 'Name', xs: 3 },
	{ text: 'Phone', xs: 3 },
	{ text: 'Address', xs: 4 },
	{ text: '', xs: 2 },
];

class Table extends Component {
	state = {
		customerAvatarEnabled: false,
	};

	componentDidMount = () => {
		ls.getCompanyDetailsFromLocalStorage().then((company) => {
			this.setState({ customerAvatarEnabled: company.customerAvatarEnabled });
		});
	};

	renderCustomersList = () => {
		const { customers, isSearch, onRemoveCustomer, onCustomerUpdate, classes } = this.props;

		if (customers.length === 0) {
			return (
				<Row>
					<Column justifyContent="center" xs={12}>
						<NoCustomersEmptyState isSearch={isSearch} />
					</Column>
				</Row>
			);
		}

		return customers.map((customer) => (
			<Row key={customer.id}>
				<Column justifyContent="flex-start" xs={3}>
					<Grid container direction="column" wrap="nowrap">
						<Box display="flex" alignItems="center">
							{this.state.customerAvatarEnabled && (
								<Avatar style={{ marginRight: '15px' }} src={getImageUrl(customer.avatar)}>
									{getInitials(customer.fullName)}
								</Avatar>
							)}

							<div>
								<Content component={Link} to={`/customer/${customer.id}/details`}>
									{customer?.fullName || 'Unnamed Customer'}
								</Content>
								{customer?.email && (
									<div>
										<Content component={MuiLink} href={`mailto:${customer.email}`} className={classes.emailText}>
											{customer.email}
										</Content>
									</div>
								)}
							</div>
						</Box>
					</Grid>
				</Column>
				<Column xs={3}>
					<Content component={MuiLink} href={`tel:${customer.phone}`}>
						{customer?.phone}
					</Content>
				</Column>
				<Column xs={4}>
					<Content
						component={MuiLink}
						target="_blank"
						rel="noopener"
						href={`https://maps.google.com?q=${customer.address}`}
					>
						{customer?.address}
					</Content>
				</Column>
				<Column justifyContent="flex-end" xs={2}>
					<Actions updateOnClick={() => onCustomerUpdate(customer)} removeOnClick={() => onRemoveCustomer(customer)} />
				</Column>
			</Row>
		));
	};

	render() {
		return (
			<>
				<Header headerInfo={headerInfo} />
				{this.renderCustomersList()}
			</>
		);
	}
}

Table.propTypes = {
	customers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			fullName: PropTypes.string.isRequired,
			email: PropTypes.string,
			phone: PropTypes.string,
			address: PropTypes.string,
		})
	).isRequired,
	onRemoveCustomer: PropTypes.func.isRequired,
	onCustomerUpdate: PropTypes.func.isRequired,
};

export default withStyles(styles)(Table);
