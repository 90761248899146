/* eslint-disable max-len */

import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getImageUrl } from 'utils/image';
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	Grid,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { withStyles } from '@material-ui/core/styles';
import * as client from 'api/client';
import * as ls from 'utils/localStorage';
import BusinessIcon from '@material-ui/icons/Business';
import { Button } from 'components/common';

const messages = {
	title: 'Company details',
	companyName: 'Name',
	companyDomain: 'Domain',
	saveChanges: 'Save Changes',
	updateCompanyDetailsSuccess: 'Company details were update successfully',
	updateCompanyDetailsFailure: 'Unexpected errors while trying to update company details',
	updateCompanyLogoSuccess: 'Company logo was update successfully',
	updateCompanyLogoFailure: 'Unexpected errors while trying to update company logo',
	email: 'Email',
	phone: 'Phone',
	address: 'Address',
	invoiceTermsAndConditions: 'Terms and conditions',
	invoiceCustomerNote: 'Default customer note',
	appointmentTemplate: 'Appointment template',
	appointmentNote: 'Appointments default note',
};

const AppointmentTemplatePlaceholders = [
	{
		name: '{firstName}',
		description: "Refers to the customer's first name. Utilize this for a personalized touch in your reminders.",
	},
	{
		name: '{lastName}',
		description:
			"Represents the customer's last name. Adding this adds another layer of personalization to your reminders.",
	},
	{
		name: '{fullName}',
		description: "Stands for the customer's complete name. Employ this for a highly personalized and warm reminder.",
	},
	{
		name: '{company}',
		description: "Insert your company's name to reinforce your brand in the communication.",
	},
	{
		name: '{appointmentName}',
		description: 'The specific name of the appointment/event.',
	},
	{
		name: '{appointmentLink}',
		description:
			'Offers a public link to grant your customer easy access to the appointment details (recommended for convenience).',
	},
	{
		name: '{appointmentTime}',
		description:
			'Dynamically displays the appointment\'s start and end dates, such as "Thursday, Feb 10 ⋅ 3:00 - 5:00PM". This adds clarity to the timing of the event.',
	},
];

class Company extends Component {
	state = {
		company: null,
	};

	componentDidMount = () => {
		ls.getCompanyDetailsFromLocalStorage(true /* forceFetch */).then((company) => {
			this.setState({ company });
		});
	};

	handleSubmit = (values) => {
		const { snackbarContext } = this.props;

		const {
			name,
			domain,
			email,
			phone,
			invoiceTermsAndConditions,
			invoiceCustomerNote,
			address,
			appointmentTemplate,
			appointmentNote,
			appointmentUseCompanyAddress,
		} = values;

		return client
			.editCompany({
				name,
				domain,
				email,
				phone,
				invoiceTermsAndConditions,
				invoiceCustomerNote,
				address,
				appointmentTemplate,
				appointmentNote,
				appointmentUseCompanyAddress,
			})
			.then((response) => {
				if (response.success) {
					// Keep up-to-date the company information retained in local-storage.
					ls.setCompanyDetailsToLocalStorage(response.data.company);

					this.setState({ company: response.data.company });

					snackbarContext.success(messages.updateCompanyDetailsSuccess);
				} else {
					snackbarContext.failure(messages.updateCompanyDetailsFailure);
				}
				return response;
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure(messages.updateCompanyDetailsFailure);
				return error;
			});
	};

	handleFileChange = (e) => {
		const { snackbarContext } = this.props;

		const file = e.target.files[0];
		client
			.updateCompanyLogo({ logo: file })
			.then((response) => {
				if (response.success) {
					this.setState({ company: response.data.company });
					snackbarContext.success(messages.updateCompanyLogoSuccess);
				} else {
					snackbarContext.failure(messages.updateCompanyLogoFailure);
				}
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure(messages.updateCompanyLogoFailure);
			});
	};

	renderEditLogoComponent = () => {
		const { classes } = this.props;
		const { company } = this.state;
		return (
			<Card>
				<CardContent>
					<Box display="flex" alignItems="center" flexDirection="column" textAlign="center">
						<Avatar variant="square" className={classes.logo} src={company.logo ? getImageUrl(company.logo) : null}>
							<BusinessIcon className={classes.icon} />
						</Avatar>
						<Typography className={classes.name} color="textPrimary" variant="h3">
							{company.name}
						</Typography>
					</Box>
				</CardContent>
				<CardActions
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						marginBottom: '15px',
					}}
				>
					<Button style={{ marginBottom: '15px' }} variant="contained" color="primary" component="label" height="50px">
						Update logo
						<input accept="image/*" type="file" onChange={this.handleFileChange} hidden />
					</Button>

					<Typography className="wg-change-photo-caption" align="center" variant="caption" display="block" gutterBottom>
						Max. 5MB
						<br />
						JPG, GIF, PNG or BMP formats
						<br />
						Recommended size: 512px x 512px
					</Typography>
				</CardActions>
			</Card>
		);
	};

	renderEditFormComponent = () => {
		const { company } = this.state;

		return (
			<Formik
				enableReinitialize
				initialValues={{
					name: company.name || '',
					domain: company.domain || '',
					email: company.email || '',
					phone: company.phone || '',
					address: company.address || '',
					invoiceTermsAndConditions: company.invoiceTermsAndConditions || '',
					invoiceCustomerNote: company.invoiceCustomerNote || '',
					appointmentTemplate: company.appointmentTemplate || '',
					appointmentNote: company.appointmentNote || '',
					appointmentUseCompanyAddress: company.appointmentUseCompanyAddress || false,
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().max(128).required("Company's name is required"),
					domain: Yup.string().url(),
					email: Yup.string().email().nullable(true),
					phone: Yup.string().nullable(true),
					address: Yup.string().nullable(true),
					invoiceTermsAndConditions: Yup.string().nullable(true),
					invoiceCustomerNote: Yup.string().nullable(true),
					appointmentTemplate: Yup.string().max(320),
					appointmentNote: Yup.string().nullable(true),
					appointmentUseCompanyAddress: Yup.bool(),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					this.handleSubmit(values)
						.then(() => setSubmitting(false))
						.catch(() => setSubmitting(false));
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardHeader title={messages.title} />
							<Divider />
							<CardContent>
								<Grid container spacing={4}>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.name && errors.name)}
											helperText={touched.name && errors.name}
											label={messages.companyName}
											fullWidth
											name="name"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.name}
											variant="outlined"
											required
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.email && errors.email)}
											helperText={touched.email && errors.email}
											label={messages.email}
											fullWidth
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.phone && errors.phone)}
											helperText={touched.phone && errors.phone}
											label={messages.phone}
											fullWidth
											name="phone"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.phone}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.domain && errors.domain)}
											helperText={touched.domain && errors.domain}
											label={messages.companyDomain}
											fullWidth
											name="domain"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.domain}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.address && errors.address)}
											helperText={touched.address && errors.address}
											label={messages.address}
											fullWidth
											name="address"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.address}
											variant="outlined"
										/>
									</Grid>
								</Grid>
							</CardContent>
							<Divider />
						</Card>

						<Card style={{ marginTop: '10px' }}>
							<CardHeader title="Appointment settings (mobile only)" />
							<Divider />
							<CardContent>
								<Grid container>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.appointmentTemplate && errors.appointmentTemplate)}
											helperText={touched.appointmentTemplate && errors.appointmentTemplate}
											label={messages.appointmentTemplate}
											multiline={true}
											rows={5}
											fullWidth
											name="appointmentTemplate"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.appointmentTemplate}
											variant="outlined"
										/>

										<Typography variant="caption" align="justify" paragraph={true}>
											(*) The appointment template provided above serves as the foundation for communicating with your
											customers before scheduled events. Feel free to tailor the content to your preferences while
											adhering to the 320-character limit. Enhance personalization by incorporating the following
											placeholders (brackets included):
										</Typography>

										{AppointmentTemplatePlaceholders.map(({ name, description }) => (
											<Grid container key={name} style={{ marginTop: '10px' }}>
												<Grid item md={3} xs={3}>
													<span className={this.props.classes.code}>{name}</span>
												</Grid>
												<Grid item md={9} xs={9}>
													{description}
												</Grid>
											</Grid>
										))}
									</Grid>

									<Grid item md={12} xs={12} style={{ marginTop: 10 }}>
										<TextField
											error={Boolean(touched.appointmentNote && errors.appointmentNote)}
											helperText={touched.appointmentNote && errors.appointmentNote}
											label={messages.appointmentNote}
											multiline={true}
											rows={10}
											fullWidth
											name="appointmentNote"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.appointmentNote}
											variant="outlined"
										/>
									</Grid>

									<Grid item md={12} xs={12} style={{ marginTop: 10 }}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Switch
														checked={values.appointmentUseCompanyAddress}
														onChange={handleChange}
														name="appointmentUseCompanyAddress"
													/>
												}
												label={`Use company address (${company.address}) for all appointments instead of customer address.`}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						<Card style={{ marginTop: '10px' }}>
							<CardHeader title="Invoice settings" />
							<Divider />
							<CardContent>
								<Grid container>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.invoiceTermsAndConditions && errors.invoiceTermsAndConditions)}
											helperText={touched.invoiceTermsAndConditions && errors.invoiceTermsAndConditions}
											label={messages.invoiceTermsAndConditions}
											multiline={true}
											rows={10}
											fullWidth
											name="invoiceTermsAndConditions"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.invoiceTermsAndConditions}
											variant="outlined"
										/>
									</Grid>

									<Grid item md={12} xs={12} style={{ marginTop: 10 }}>
										<TextField
											error={Boolean(touched.invoiceCustomerNote && errors.invoiceCustomerNote)}
											helperText={touched.invoiceCustomerNote && errors.invoiceCustomerNote}
											label={messages.invoiceCustomerNote}
											multiline={true}
											rows={10}
											fullWidth
											name="invoiceCustomerNote"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.invoiceCustomerNote}
											variant="outlined"
											placeholder="Thanks for doing business with us!"
										/>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						<Card style={{ marginTop: '10px' }}>
							{errors.submit && (
								<Box mt={3}>
									<FormHelperText error>{errors.submit}</FormHelperText>
								</Box>
							)}

							<Box p={2} display="flex" justifyContent="flex-end">
								<Button
									color="primary"
									disabled={isSubmitting || !!Object.keys(errors).length}
									type="submit"
									variant="contained"
									height="50px"
								>
									{messages.saveChanges}
								</Button>
							</Box>
						</Card>
					</form>
				)}
			</Formik>
		);
	};

	render() {
		if (!this.state.company) {
			return null;
		}

		return (
			<Grid container spacing={3}>
				<Grid item lg={4} md={6} xl={3} xs={12}>
					{this.renderEditLogoComponent()}
				</Grid>
				<Grid item lg={8} md={6} xl={9} xs={12}>
					{this.renderEditFormComponent()}
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	name: {
		marginTop: theme.spacing(1),
	},
	logo: {
		height: 'auto',
		width: 256,
	},
	icon: {
		height: 256,
		width: 256,
		padding: 40,
	},
	code: {
		fontFamily: 'monospace',
		backgroundColor: theme.palette.white,
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
	},
});

export default withStyles(styles)(withSnackbarContext(Company));
