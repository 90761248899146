import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ArtTrackOutlinedIcon from '@material-ui/icons/ArtTrackOutlined';

class ProjectItemSearch extends Component {
	render = () => {
		const { content } = this.props;

		return (
			<Grid container direction="row" spacing={2}>
				<Grid item>
					<ArtTrackOutlinedIcon />
				</Grid>
				<Grid item>
					<Typography variant="h6">{content}</Typography>
				</Grid>
			</Grid>
		);
	};
}

ProjectItemSearch.propTypes = {
	content: PropTypes.string.isRequired,
};

export default withRouter(ProjectItemSearch);
