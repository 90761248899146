import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Drawer,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import * as Yup from 'yup';

import { Button } from 'components/common';
import { OutlinedSelect } from 'components/common';
import { ProjectStates } from 'utils/states';
import { useCommonProps } from 'utils/form';
import * as client from 'api/client';
import { PaymentStatus } from 'types/enums';

const messages = {
	failure: 'Something failed, please refresh the page and try again.',
};

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

const EditProject = ({ project, classes, onEdit, onClose }) => (
	<Drawer
		anchor="right"
		classes={{ paper: classes.drawer }}
		onClose={onClose}
		open={Boolean(project)}
		variant="temporary"
	>
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				Edit project
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<EditProjectForm project={project} onEdit={onEdit} />
		</Box>
	</Drawer>
);

// Pass the project as a prop and let formik handle states internally since
// <Formik /> is an uncontrolled component.
const EditProjectForm = ({ project, onEdit }) => (
	<Formik
		initialValues={project}
		validationSchema={Yup.object().shape({
			title: Yup.string().max(512),
			price: Yup.number().nullable(),
			address: Yup.string().max(1024).nullable(),
			status: Yup.number(),
			paymentMethod: Yup.number(),
		})}
		onSubmit={(values, actions) => {
			actions.setSubmitting(true);
			client
				.editProject({
					projectId: project.projectId,
					project: values,
				})
				.then((response) => {
					if (response.success) {
						onEdit(response.data.project);
					} else {
						actions.setErrors({ submit: messages.failure });
					}
				})
				.catch((error) => {
					console.error(error);
					actions.setErrors({ submit: messages.failure });
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<EditProjectFormFields
							values={values}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
							isSubmitting={isSubmitting}
						/>

						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="100%"
								height="50px"
							>
								Edit
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const EditProjectFormFields = ({ values, onChange, onBlur, errors, touched, isSubmitting }) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('title')} variant="outlined" label="Title" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('price')} variant="outlined" label="Price" type="number" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('address')} variant="outlined" label="Address" />
			</Grid>

			<Grid item md={12} xs={12}>
				<InputLabel>Project status</InputLabel>
				<OutlinedSelect
					{...propsFn('status')}
					placeholder="Project status"
					fullWidth
					items={Object.keys(ProjectStates).map((state) => ({
						value: state,
						label: ProjectStates[state].name,
					}))}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<InputLabel>Payment method</InputLabel>
				<OutlinedSelect
					{...propsFn('paymentMethod')}
					placeholder="Payment method"
					fullWidth
					items={Object.keys(PaymentStatus)
						.filter((entry) => !isNaN(entry))
						.map((value) => ({
							value: value,
							label: PaymentStatus[value],
						}))}
				/>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(EditProject);
