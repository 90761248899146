import React from 'react';
import clsx from 'clsx';
import { Chip, Grid, withStyles } from '@material-ui/core';

const NameSuggestions = ({ names, onSelect, classes }) =>
	names.length > 0 ? (
		<Grid item md={12} xs={12} className={clsx(classes.scrollable, 'hidescrollbar')}>
			{names.map((name) => (
				<Chip key={name} label={name} style={{ marginLeft: 5 }} onClick={() => onSelect(name)} />
			))}
		</Grid>
	) : null;

const styles = () => ({
	scrollable: {
		overflowX: 'auto',
		overflowY: 'hidden',
		whiteSpace: 'nowrap',
	},
});

export default withStyles(styles)(NameSuggestions);
