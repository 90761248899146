import React, { Component } from 'react';
import { Box, Container, Divider, Tab, Tabs } from '@material-ui/core';
import Company from './Company';
import CustomersImport from './CustomersImport';
import General from './General';
import Security from './Security';
import Subscriptions from './Subscriptions';
import { getIsAdmin } from 'utils/localStorage';

const TabsInfo = {
	general: {
		label: 'General',
		enabled: () => true,
		component: General,
	},
	company: {
		label: 'Company',
		enabled: getIsAdmin,
		component: Company,
	},
	subscriptions: {
		label: 'Subscriptions',
		enabled: getIsAdmin,
		component: Subscriptions,
	},
	import: {
		label: 'Import',
		enabled: getIsAdmin,
		component: CustomersImport,
	},
	security: {
		label: 'Security',
		enabled: () => true,
		component: Security,
	},
};

const TabsOrder = ['general', 'company', 'import', 'subscriptions', 'security'];

class AccountView extends Component {
	state = {
		currentTab: 'general',
	};

	setCurrentTab = (_, tab) => {
		this.setState({ currentTab: tab });
	};

	renderTabs = () => {
		const tabs = [];
		TabsOrder.forEach((key) => {
			const { enabled, label } = TabsInfo[key];
			if (enabled()) {
				tabs.push(<Tab key={key} label={label} value={key} />);
			}
		});

		return (
			<Tabs
				onChange={this.setCurrentTab}
				scrollButtons="auto"
				value={this.state.currentTab}
				variant="scrollable"
				textColor="primary"
				indicatorColor="primary"
			>
				{tabs}
			</Tabs>
		);
	};

	render() {
		const { currentTab } = this.state;
		const { component: TabComponent } = TabsInfo[currentTab];

		return (
			<Container maxWidth="lg">
				<Box mt={3}>{this.renderTabs()}</Box>
				<Divider />
				<Box mt={3}>
					<TabComponent />
				</Box>
			</Container>
		);
	}
}

export default AccountView;
