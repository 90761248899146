const styles = {
	menuPaper: {
		borderRadius: 4,
	},
	menuList: {
		padding: 0,
	},
	menuItem: {
		padding: '5px 0',
		backgroundColor: '#fff !important',
		'& :hover': {
			color: '#00B2A190 !important',
		},
	},
	label: {
		padding: '6px 16px',
		width: '100%',
		height: '100%',
	},
};

export default styles;
