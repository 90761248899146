/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paper, Divider, withStyles } from '@material-ui/core';

const styles = {
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		width: '100%',
		padding: '15px',
		margin: '8px 0',
	},
};

const Row = ({ addDividers, children, classes, ...props }) => (
	<Paper elevation={2} className={classes.row} {...props}>
		{addDividers
			? children.flat().map((col, index) =>
					index > 0 && index < children.flat().length - 1 ? (
						<Fragment key={index}>
							{col}
							<Divider orientation="vertical" />
						</Fragment>
					) : (
						col
					)
			  )
			: children}
	</Paper>
);

Row.propTypes = {
	addDividers: PropTypes.bool,
};

export default withStyles(styles)(Row);
