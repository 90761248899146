import React from 'react';
import { withSnackbarContext } from 'context/SnackbarsContext';
import VerifyAccountManualVerification from 'modules/Auth/Verify/VerifyAccountManualVerification';
import VerifyAccountAutomaticVerification from 'modules/Auth/Verify/VerifyAccountAutomaticVerification';

const Verify = ({ match }) =>
	match.params.token && match.params.userId ? (
		<VerifyAccountAutomaticVerification userId={+match.params.userId} token={match.params.token} />
	) : (
		<VerifyAccountManualVerification />
	);

export default withSnackbarContext(Verify);
