import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Box, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { withAuthContext } from 'context/AuthContext';
import { withStyles } from '@material-ui/core/styles';
import { getImageUrl } from 'utils/image';
import * as client from 'api/client';
import { Button } from 'components/common';

const styles = (theme) => ({
	root: {},
	name: {
		marginTop: theme.spacing(1),
	},
	avatar: {
		height: 100,
		width: 100,
	},
});

class ProfileDetails extends Component {
	handleFileChange = (e) => {
		const file = e.target.files[0];
		client.updateAvatar({ avatar: file }).then((response) => {
			if (response.success) {
				const { updateUser } = this.props.authContext;
				updateUser({ avatar: response.data.avatar });
			}
		});
	};

	render() {
		const {
			user,
			classes,
			className,
			authContext: { avatar },
		} = this.props;

		return (
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<Box display="flex" alignItems="center" flexDirection="column" textAlign="center">
						<Avatar className={classes.avatar} src={avatar ? getImageUrl(avatar) : null} />
						<Typography className={classes.name} color="textPrimary" gutterBottom variant="h3">
							{user.firstName}
						</Typography>
					</Box>
				</CardContent>
				<CardActions
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						marginBottom: '15px',
					}}
				>
					<Button style={{ marginBottom: '15px' }} variant="contained" color="primary" component="label" height="50px">
						Update Photo
						<input accept="image/*" type="file" onChange={this.handleFileChange} hidden />
					</Button>

					<Typography className="wg-change-photo-caption" align="center" variant="caption" display="block" gutterBottom>
						Max. 5MB
						<br />
						JPG, GIF, PNG or BMP formats
						<br />
						Recommended size: 512px x 512px
					</Typography>
				</CardActions>
			</Card>
		);
	}
}

ProfileDetails.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object.isRequired,
};

export default withStyles(styles)(withAuthContext(ProfileDetails));
