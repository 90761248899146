import React from 'react';
import { Grid, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { LocationOn, Phone, Email, Label, Cake } from '@material-ui/icons';
import { Content } from 'components/table';
import IconLink from 'components/icons/IconLink';
import moment from 'moment';

const CustomerCompactedInfo = ({ customer, includeName, ...props }) => (
	<Grid container direction="column" wrap="nowrap" {...props}>
		{includeName && (
			<Content component={Link} to={`/customer/${customer.id}/details`}>
				{customer?.fullName || 'Unnamed Customer'}
			</Content>
		)}
		<IconLink icon={Email} text={customer?.email} href={`mailto:${customer.email}`} />
		{(customer?.phones || []).map(({ id, phone, isPrimary }) => (
			<IconLink
				key={id}
				icon={Phone}
				text={phone}
				href={`tel:${phone}`}
				suffix={
					isPrimary && (
						<span>
							{' '}
							(<small>primary</small>)
						</span>
					)
				}
			/>
		))}
		{customer?.birthday && (
			<Grid style={{ padding: '2px' }}>
				<Cake style={{ color: '#535353', height: 18, width: 18 }} />
				<span style={{ marginLeft: '5px' }}>{moment(customer.birthday).format('MMM D')}</span>
			</Grid>
		)}
		<IconLink icon={LocationOn} text={customer?.address} href={`https://maps.google.com?q=${customer.address}`} />
		{customer.tags.length > 0 && (
			<Grid style={{ padding: '2px' }}>
				<Label style={{ color: '#535353', height: 18, width: 18 }} />
				{customer.tags.map((tag) => (
					<Chip key={tag} label={tag} size="small" variant="outlined" style={{ marginLeft: '5px' }} />
				))}
			</Grid>
		)}
	</Grid>
);

export default CustomerCompactedInfo;
