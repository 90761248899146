import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Divider,
	FormHelperText,
	Link,
	TextField,
	Typography,
} from '@material-ui/core';
import * as client from 'api/client';
import { withSnackbarContext } from 'context/SnackbarsContext';
import BarnWrapper from 'components/auth/BarnWrapper';

const Forgot = ({ history, snackbarContext }) => (
	<BarnWrapper title="Forgot password | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" justifyContent="space-between">
						<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
							<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
							<Typography color="textPrimary" gutterBottom variant="h2">
								Forgot your password?
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Enter your email address to reset your password. You may need to check your spam folder.
							</Typography>
						</Box>
					</Box>

					<Formik
						initialValues={{
							email: '',
							submit: null,
						}}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);

							const { email } = values;
							client
								.forgotPassword({ email })
								.then((response) => {
									if (response.success) {
										snackbarContext.success(
											`If a Werkgo account exists for ${email} an e-mail will be sent with next steps.`
										);
										history.push('/signin');
									} else {
										snackbarContext.failure('Something went wrong');
									}
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
							<form noValidate onSubmit={handleSubmit}>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									autoFocus
									helperText={touched.email && errors.email}
									label="Email address"
									margin="normal"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									type="email"
									value={values.email}
									variant="outlined"
								/>

								{errors.submit && (
									<Box mb={2}>
										<FormHelperText error>{errors.submit}</FormHelperText>
									</Box>
								)}

								<Box flexGrow={1} mt={2}>
									<Button
										color="secondary"
										disabled={isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Submit
									</Button>
								</Box>
							</form>
						)}
					</Formik>

					<Box my={3}>
						<Divider />
					</Box>

					<Link component={RouterLink} to="/signin" variant="body2" color="textSecondary">
						Having an account
					</Link>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default withSnackbarContext(Forgot);
