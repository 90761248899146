import React from 'react';
import { Box, Button, Card, CardContent, Container, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';

const VerifyAccountFailure = () => (
	<BarnWrapper title="Failed to verify account | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" variant="h2">
							Failed to verify account
						</Typography>
						<Typography variant="body1" color="textSecondary">
							The token is invalid, expired, or your account was deleted. Please, reach out your company&apos;s
							administrators.
						</Typography>
					</Box>
					<Box flexGrow={1} mt={2}>
						<Button href="/" fullWidth size="large" variant="outlined" color="primary">
							← Go back
						</Button>
					</Box>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default VerifyAccountFailure;
