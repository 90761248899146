import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput, InputAdornment, SvgIcon, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';

import styles from 'components/filters/styles/search';

class Search extends Component {
	debounceHandleQuery = _.debounce((value) => {
		const { onQueryChange } = this.props;
		return onQueryChange(value);
	}, 750);

	render() {
		const { placeholder, disabled, fullWidth, classes } = this.props;

		return (
			<OutlinedInput
				startAdornment={
					<InputAdornment position="start">
						<SvgIcon fontSize="small" color="action">
							<SearchIcon />
						</SvgIcon>
					</InputAdornment>
				}
				onChange={({ target: { value } }) => this.debounceHandleQuery(value)}
				placeholder={placeholder}
				variant="outlined"
				classes={{ notchedOutline: classes.notchedOutline }}
				readOnly={disabled}
				fullWidth={fullWidth}
				className={classes.input}
			/>
		);
	}
}

Search.propTypes = {
	placeholder: PropTypes.string,
	onQueryChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default withStyles(styles)(Search);
