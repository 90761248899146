const styles = {
	checkboxChecked: {
		color: '#00B2A1 !important',
	},
	priorityLabelText: {
		color: '#fff',
		marginBottom: 2,
	},
	textField: {
		fontSize: 16,
		fontFamily: 'Sarabun',
		fontWeight: 400,
	},
	cancel: {
		marginLeft: 20,
	},
	doneText: {
		textDecoration: 'line-through',
		opacity: 0.4,
	},
	description: {
		whiteSpace: 'pre-wrap',
		overflowWrap: 'break-word',
		wordBreak: 'break-word',
	},
};

export default styles;
