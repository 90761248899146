import React, { Component } from 'react';
import { Button, Paper, withStyles } from '@material-ui/core';
import { ElementsConsumer, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { StripePublishableKey } from 'constant';
import { CheckoutButtonStyle, ClientInvoiceStyles } from './style';

class CheckoutForm extends Component {
	state = {
		paymentFormReady: false,
		makingPayment: false,
	};

	handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		this.setState({ makingPayment: true }, async () => {
			const { stripe, elements } = this.props;

			const result = await stripe.confirmPayment({
				// `Elements` instance that was used to create the Payment Element
				elements,
				confirmParams: {
					return_url: window.location.href,
				},
			});

			if (result.error) {
				// Show error to your customer (e.g., payment details incomplete)
				this.props.snackbarContext.failure(result.error.message);
				this.setState({ makingPayment: false });
			}
		});
	};

	stripeFormReady = () => {
		this.setState({ paymentFormReady: true });
	};

	render() {
		const { classes } = this.props;
		const { paymentFormReady, makingPayment } = this.state;

		return (
			<Paper className={(classes.paper, classes.payment)} style={{ display: paymentFormReady ? 'initial' : 'none' }}>
				<>
					<div className={classes.paymentSection}>
						<form onSubmit={this.handleSubmit}>
							<PaymentElement onReady={this.stripeFormReady} />
							<Button
								disabled={makingPayment}
								type="submit"
								variant="contained"
								style={{
									...CheckoutButtonStyle,
									backgroundColor: makingPayment ? '#F2F6F8' : '#00B5A1',
								}}
							>
								{this.props.payButtonLabel}
							</Button>
						</form>
					</div>
				</>
			</Paper>
		);
	}
}

class InjectedCheckoutForm extends Component {
	stripePromise = loadStripe(StripePublishableKey, {
		stripeAccount: this.props.stripeAccount,
	});

	appearance = {
		theme: 'stripe',
	};
	options = {
		clientSecret: this.props.stripeClientSecret,
		appearance: this.appearance,
	};

	render() {
		const { snackbarContext, payButtonLabel, classes } = this.props;

		return (
			<Elements stripe={this.stripePromise} options={this.options}>
				<ElementsConsumer>
					{({ stripe, elements }) => (
						<CheckoutForm
							stripe={stripe}
							classes={classes}
							elements={elements}
							// HACK: Passing snackbarContext doesn't seem right here. Think
							// a better way to do this.
							snackbarContext={snackbarContext}
							payButtonLabel={payButtonLabel}
						/>
					)}
				</ElementsConsumer>
			</Elements>
		);
	}
}

export default withSnackbarContext(withStyles(ClientInvoiceStyles)(InjectedCheckoutForm));
