import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';
import JWTSignIn from 'components/auth/JWTSignIn';

const Signin = () => (
	<BarnWrapper title="Sign In | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" gutterBottom variant="h2">
							Sign In to your Werkgo account
						</Typography>
					</Box>
					<JWTSignIn />
					<Box my={3}>
						<Divider />
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Link component={RouterLink} to="/signup" variant="body2" color="textSecondary">
							Create new account
						</Link>
						<Link component={RouterLink} to="/forgot" variant="body2">
							Forgot your password?
						</Link>
					</Box>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default Signin;
