const styles = () => ({
	title: {
		fontWeight: 600,
		marginBottom: 5,
		fontSize: 22,
	},
	subtitle: {
		fontSize: 20,
	},
	info: {
		color: '#535353',
		marginTop: 5,
		marginLeft: 5,
		lineHeight: '17px',
	},
	icon: {
		color: '#535353',
		height: 18,
		width: 18,
		marginTop: 5,
	},
	attachmentsHeader: {
		margin: '20px 0',
	},
});

export default styles;
