import React from 'react';
import EmptyState from 'components/EmptyState';

const NoCustomersEmptyState = ({ isSearch }) =>
	isSearch ? (
		<EmptyState name="es-no-search-result" title={'No results found'}>
			Try adjusting your search or filter to find what you&apos;re looking for.
		</EmptyState>
	) : (
		<EmptyState name="es-no-customers" title={'Start by adding a customer'}>
			Create a customer by clicking the &quot;+ Add customer&quot; button above and it will show up here.
		</EmptyState>
	);

export default NoCustomersEmptyState;
