const styles = (theme) => ({
	container: {
		width: 1200,
		margin: '40px 0 40px 150px',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('md')]: {
			width: 1000,
			marginLeft: 50,
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			justifyContent: 'center',
			width: 800,
			margin: '50px 0',
		},
	},
	title: {
		fontWeight: 600,
		marginBottom: 10,
	},
	text: {
		color: '#46495D',
		fontWeight: 400,
		marginBottom: 20,
	},
	textContainer: {
		padding: '0 50px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	link: {
		color: '#011842',
		cursor: 'pointer',
		fontWeight: 600,
	},
	image: {
		height: 360,
		width: 450,
		[theme.breakpoints.down('sm')]: {
			height: 300,
			width: 300,
		},
	},
});

export default styles;
