const styles = {
	input: {
		height: 38,
		backgroundColor: '#fff',
	},
	notchedOutline: {
		borderColor: '#011842 !important',
	},
};

export default styles;
