/* eslint-disable max-len */
import React, { Component } from 'react';

import clsx from 'clsx';
import { Grid, withStyles, Typography } from '@material-ui/core';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

import { getImageUrl } from 'utils/image';
import { styles } from 'components/AttachmentsList/styles';
import { getPreviewUrl } from 'utils/attachments';

class AttachmentsList extends Component {
	state = {
		containerRef: null,
	};

	onInit = (details) => {
		// This method setups the button to delete items in the gallery. The
		// gallery is agnostic on how the items are actually deleted. This is
		// up to the caller (e.g making a request to the server). The caller must
		// provide an `onDeleteItem` callback in order to render the button.
		//
		// If the user clicks on the delete button, `onDeleteItem` is called with
		// the appropiated item IDs and removed from the gallery only if `onDeleteItem`
		// was succesfull.
		const { onDeleteItem } = this.props;
		if (!details || !onDeleteItem) {
			return;
		}

		const lightGallery = details.instance;

		const deleteButtonId = `lg-delete-${lightGallery.lgId}`;

		const deleteButton = `<button class="lg-icon lg-delete-icon" type="button" aria-label="Remove slide" id="${deleteButtonId}">
				<svg
				  version="1.1"
				  xmlns="http://www.w3.org/2000/svg"
				  width="24"
				  height="24"
				  viewBox="0 0 24 24"
				>
				  <path
					d="M23 12c0-3.037-1.232-5.789-3.222-7.778s-4.741-3.222-7.778-3.222-5.789 1.232-7.778 3.222-3.222 4.741-3.222 7.778 1.232 5.789 3.222 7.778 4.741 3.222 7.778 3.222 5.789-1.232 7.778-3.222 3.222-4.741 3.222-7.778zM21 12c0 2.486-1.006 4.734-2.636 6.364s-3.878 2.636-6.364 2.636-4.734-1.006-6.364-2.636-2.636-3.878-2.636-6.364 1.006-4.734 2.636-6.364 3.878-2.636 6.364-2.636 4.734 1.006 6.364 2.636 2.636 3.878 2.636 6.364zM8 13h8c0.552 0 1-0.448 1-1s-0.448-1-1-1h-8c-0.552 0-1 0.448-1 1s0.448 1 1 1z"
				  ></path>
				</svg>
			  </button>`;

		lightGallery.outer.find('.lg-toolbar').append(deleteButton);
		lightGallery.outer.find(`#${deleteButtonId}`).on('click', () => {
			const { index } = lightGallery;

			// Probably not the best way to get the attachment ID, but meh :/
			const id = lightGallery.items[index].getAttribute('data-id');
			onDeleteItem(id).then((deleted) => {
				if (deleted) {
					// First, we get a copy of all items in the gallery and
					// remove the one at the given index (*). After this, we
					// need to figure out what's the next index to showcase.
					//
					// (*) As recommended in https://www.lightgalleryjs.com/docs/methods/,
					// we shouldn't modify the items reference of the current gallery but
					// rather create a copy.
					const galleryItems = JSON.parse(JSON.stringify(lightGallery.galleryItems));
					galleryItems.splice(index, 1);
					const nextIndexToShowcase = Math.min(index + 1, galleryItems.length - 1);
					lightGallery.updateSlides(galleryItems, nextIndexToShowcase);

					// We need to refresh to re-adjust element indexes external thumbnails.
					lightGallery.refresh();
				}
			});
		});
	};

	renderList = () => {
		const { classes, attachments } = this.props;

		const size = ['sm', 'md'].indexOf(this.props.size) >= 0 ? this.props.size : 'md';

		// Compute the classes needed to shape thumbnails and the +overlay
		// with the expected size.
		const shapeClass = clsx(classes.thumbnailShape, {
			[classes.thumbnailShapeSm]: size === 'sm',
			[classes.thumbnailShapeMm]: size === 'md',
		});

		const thumbnailSize = {
			sm: 40,
			md: 100,
		}[size];

		// Calculate the number of thumbnails that we can fit in
		// the available space. This takes into consideration few
		// factors (e.g max width/height of each thumbnail, max number of
		// thumbnails we are willing to display, margin between thumbnails,
		// etc.)
		const { containerRef } = this.state;
		const availableWidth = containerRef?.offsetWidth;
		const thumbnailsToShow = Math.floor(availableWidth / thumbnailSize);
		const hiddenThumbnails = Math.max(0, attachments.length - thumbnailsToShow);

		// Sort attachments from the most recent to the older one. The user will be able to
		// know the status of those previews that they recently uploaded without having to
		// open the gallery.
		const sortedAttachments = attachments.sort((a, b) => b.id - a.id);

		return (
			<LightGallery onInit={this.onInit} plugins={[lgThumbnail]} elementClassNames={classes.thumbnailsWrapper}>
				{sortedAttachments.map((attachment, index) => {
					const attachmentUrl = getImageUrl(attachment.attachmentUrl);
					const previewUrl = getPreviewUrl(attachment);
					const mustShowPlusOverlay = index === thumbnailsToShow - 1 && hiddenThumbnails > 0;

					return (
						<a
							key={attachment.id}
							data-src={previewUrl}
							data-download-url={attachmentUrl}
							data-sub-html={attachment.name}
							data-id={attachment.id}
							href={previewUrl}
							className={clsx({
								[classes.thumbnailHidden]: index >= thumbnailsToShow,
								[classes.thumbnailExpand]: mustShowPlusOverlay,
							})}
						>
							{mustShowPlusOverlay && (
								<div className={clsx(classes.thumbnailPlusOverlay, shapeClass)}>
									<Typography variant="h1" style={{ color: 'white', fontSize: thumbnailSize / 2.85 }}>
										+{hiddenThumbnails}
									</Typography>
								</div>
							)}
							<img className={shapeClass} src={previewUrl} alt={attachment.name} />
						</a>
					);
				})}
			</LightGallery>
		);
	};

	render() {
		const { containerRef } = this.state;
		return (
			<Grid
				container
				innerRef={(ref) => {
					if (!containerRef && ref) {
						this.setState({ containerRef: ref });
					}
				}}
			>
				{!!containerRef && this.renderList()}
			</Grid>
		);
	}
}

export default withStyles(styles)(AttachmentsList);
