/* eslint-disable no-mixed-spaces-and-tabs */
import { postMultipart, GenericSuccess, GenericError } from 'api/core';

//***************
// TYPES
//***************

//
// updateAvatar
//

export interface UpdateAvatarArgs {
	avatar: File;
}

export type UpdateAvatarResult =
	| GenericSuccess<{
			avatar: string;
	  }>
	| GenericError;

//***************
// REQUESTS
//***************

export const updateAvatar = (args: UpdateAvatarArgs): Promise<UpdateAvatarResult> => {
	const data = new FormData();
	data.append('avatar', args.avatar);
	return postMultipart('avatar/update/', data);
};
