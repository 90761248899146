import React, { Component } from 'react';
import { getUserInfoLocalStorage, setUserInfoLocalStorage } from 'utils/localStorage';

const { Provider: AuthContextProvider, Consumer: AuthConsumer } = React.createContext();

const withAuthContext = (Component) => {
	return function WrapperComponent(props) {
		return <AuthConsumer>{(state) => <Component {...props} authContext={state} />}</AuthConsumer>;
	};
};

class AuthProvider extends Component {
	state = {
		id: 0,
		token: '',
		companyId: 0,
		lastName: '',
		firstName: '',
		company: {},
	};

	componentDidMount = () => {
		const localStorage = getUserInfoLocalStorage();

		if (localStorage.token) {
			this.setState({ ...localStorage });
		}
	};

	updateContext = (newContext) => {
		setUserInfoLocalStorage({ ...getUserInfoLocalStorage(), ...newContext });
		this.setState({ ...this.state, ...newContext });
	};

	cleanContext = () => {
		const prevState = this.state;
		for (const key in prevState) {
			if (typeof prevState[key] === 'string') {
				prevState[key] = '';
			} else if (typeof prevState[key] === 'number') {
				prevState[key] = 0;
			} else if (typeof prevState[key] === 'boolean') {
				prevState[key] = false;
			}
		}
		setUserInfoLocalStorage({ ...prevState });
		this.setState({ ...prevState });
	};

	render() {
		return (
			<AuthContextProvider
				value={{
					...this.state,
					updateUser: this.updateContext,
					cleanUserInfo: this.cleanContext,
				}}
			>
				{this.props.children}
			</AuthContextProvider>
		);
	}
}

export { AuthProvider, AuthConsumer, withAuthContext };
