import React from 'react';
import { Avatar, Box, Card, Grid, Typography, withStyles } from '@material-ui/core';
import Linkify from 'react-linkify';

import { getImageUrl } from 'utils/image';
import { getInitials } from 'utils/string';
import { withIsMobile } from 'hoc/IsMobileHOC';
import CustomerCompactedInfo from 'components/customer/CustomerCompactedInfo';

const CustomerInfoMobile = ({ customer, classes }) => (
	<Card className={classes.root}>
		<Grid container direction="column" className={classes.mainSection}>
			<Box display="flex" alignItems="center">
				<Typography variant="h4">{customer.fullName || 'Unnamed Customer'}</Typography>
			</Box>
			<Typography variant="caption">
				<Linkify>{customer.note}</Linkify>
			</Typography>
		</Grid>
		<CustomerCompactedInfo customer={customer} includeName={false} style={{ padding: '15px' }} />
	</Card>
);

const CustomerInfoWeb = ({ customer, classes, onAvatarClicked, customerAvatarEnabled }) => (
	<Card className={classes.root}>
		<Grid container>
			<Grid item className={classes.mainSection} xs={4}>
				{customerAvatarEnabled && (
					<Avatar onClick={onAvatarClicked} className={classes.customerAvatar} src={getImageUrl(customer.avatar)}>
						{getInitials(customer.fullName)}
					</Avatar>
				)}

				<Grid container display="flex" direction="column">
					<Typography variant="h4">{customer.fullName || 'Unnamed Customer'}</Typography>
					<Typography variant="caption">
						<Linkify>{customer.note}</Linkify>
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={8}>
				<CustomerCompactedInfo customer={customer} includeName={false} style={{ padding: '15px' }} />
			</Grid>
		</Grid>
	</Card>
);

// Simple wrapper to decide how to render the customer info section depending
// whether we are in a mobile vs web context. Two things are expected in addition
// to `isMobile` (rest): `customer` and `onAvatarClicked`.
const CustomerInformationSection = ({ isMobile, ...rest }) =>
	isMobile ? <CustomerInfoMobile {...rest} /> : <CustomerInfoWeb {...rest} />;

const styles = {
	root: {
		marginTop: '10px',
	},
	mainSection: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#00B2A12F',
		padding: '15px',
	},
	customerAvatar: {
		height: 75,
		width: 75,
		borderRadius: 100,
		marginRight: '15px',
		cursor: 'pointer',
	},
};

export default withIsMobile(withStyles(styles)(CustomerInformationSection));
