import React, { Component } from 'react';
import AppBar from 'components/bars/AppBar';
import NavBar from 'components/bars/NavBar';

class AppAndNavBars extends Component {
	state = {
		isMobileNavBarOpen: false,
	};

	toggleMobileNavBar = () => {
		this.setState((prevState) => ({
			isMobileNavBarOpen: !prevState.isMobileNavBarOpen,
		}));
	};

	render() {
		const { showNavBar, showAppBar } = this.props;
		const { isMobileNavBarOpen } = this.state;

		return (
			<>
				{showAppBar && <AppBar toggleMobileNavBar={this.toggleMobileNavBar} showNavBar={showNavBar} />}
				{showNavBar && <NavBar isMobileNavBarOpen={isMobileNavBarOpen} toggleMobileNavBar={this.toggleMobileNavBar} />}
			</>
		);
	}
}

export default AppAndNavBars;
