import React, { Component } from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { Button } from 'components/common';
import { withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const messages = {
	heading: "Here's what you planned",
	addEvent: 'Add event',
	calendar: 'Calendar',
};

class Header extends Component {
	renderBreadcrumbs = () => {
		return (
			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Typography variant="body1" color="textPrimary">
					{messages.calendar}
				</Typography>
			</Breadcrumbs>
		);
	};

	render() {
		const { className, onAddClick, ...rest } = this.props;

		return (
			<Grid className={clsx(styles.root, className)} container justify="space-between" spacing={3} {...rest}>
				<Grid item>
					{this.renderBreadcrumbs()}
					<Typography variant="h3" color="textPrimary">
						{messages.heading}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						onClick={onAddClick}
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						height={38}
						style={{ marginLeft: 'auto' }}
					>
						{messages.addEvent}
					</Button>
				</Grid>
			</Grid>
		);
	}
}

const styles = (theme) => ({
	root: {},
	action: {
		backgroundColor: '#00B2A1',
		marginBottom: theme.spacing(1),
		'& + &': {
			marginLeft: theme.spacing(1),
		},
	},
});

Header.propTypes = {
	className: PropTypes.string,
	onAddClick: PropTypes.func,
};

Header.defaultProps = {
	onAddClick: () => {},
};

export default withStyles(styles)(Header);
