import React, { Component } from 'react';
import { CircularProgress, TextField, Grid } from '@material-ui/core';
import * as _ from 'lodash';
import * as client from 'api/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CreateCustomerDrawer from 'modules/Customer/Create';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { DefaultPageSize } from 'utils/search';

class CustomerAutocomplete extends Component {
	searchDebounced = null;
	createCustomerOption = {
		fullName: '+ Create new customer',
		createOption: true,
	};

	state = {
		isCreating: false,
		loading: false,
		open: false,
		options: [this.createCustomerOption],
		current: this.props.initialValue,
	};

	componentDidMount = () => {
		this.searchDebounced = _.debounce(this.search, 300);
		this.searchDebounced();
	};

	/**
	 * Called once the user types in the input.
	 */
	handleChange = (event) => {
		this.updateCurrent(event.target.value, true /* triggerSearch */);
		const { onSelect } = this.props;
		if (onSelect) {
			onSelect(event, {});
		}
	};

	/**
	 * Called once the user selects a value from the dropdown. Since this
	 * is a choice from the list of options, no search needs to be done.
	 */
	handleSelect = (e, selectedValue) => {
		if (selectedValue && selectedValue.createOption) {
			this.setState({ isCreating: true });
		} else {
			this.updateCurrent(selectedValue, false);
			const { onSelect } = this.props;
			if (onSelect) {
				onSelect(e, selectedValue);
			}
		}
	};

	/**
	 * This method updates the state of the current value in the input
	 * box and notifies the parent component about this change. It also
	 * triggers a search if requested.
	 */
	updateCurrent = (current, triggerSearch) => {
		this.setState({ current, loading: triggerSearch }, () => {
			if (triggerSearch) {
				this.searchDebounced();
			}
		});
	};

	search = () => {
		let { current } = this.state;

		this.setState({ loading: true });

		if (typeof current === 'object') {
			current = current.fullName;
		}

		client
			.getCustomers({
				pagination: { start: 0, limit: DefaultPageSize },
				search: { query: current },
			})
			.then((response) => {
				const { customers } = response.data;
				this.setState((prevState) => ({
					...prevState,
					options: [this.createCustomerOption, ...customers],
					loading: false,
				}));
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				this.setState({ loading: false });
				snackbarContext.failure('Unable to fetch customers, refresh the page.');
				console.error(error);
			});
	};

	handleCustomerCreated = (newCustomer) => {
		const { snackbarContext } = this.props;
		const { options } = this.state;

		this.setState(
			{
				isCreating: false,
				options: [...options.slice(0, 1), newCustomer, ...options.slice(1, options.length)],
			},
			() => {
				snackbarContext.success('Customer created');
			}
		);
	};

	render() {
		const { loading, open, current, options } = this.state;
		const { className, label } = this.props;

		return (
			<>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<Autocomplete
							className={className}
							open={open}
							onOpen={() => {
								this.setState({ open: true });
							}}
							onClose={() => {
								this.setState({ open: false });
							}}
							freeSolo={false}
							getOptionSelected={(option, value) => {
								if (!value || typeof value !== 'object') {
									return false;
								}

								return option.id === value.id;
							}}
							getOptionLabel={getCustomerLabel}
							options={options}
							loading={loading}
							onChange={this.handleSelect}
							value={current}
							renderOption={(props) => {
								const optionLabel = props.address ? `${props.fullName} - ${props.address}` : props.fullName;
								return props.createOption ? <b>{optionLabel}</b> : optionLabel;
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									label={label || 'Customer'}
									name="customer"
									variant="outlined"
									onChange={this.handleChange}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<CreateCustomerDrawer
					isCreating={this.state.isCreating}
					onCustomerCreated={this.handleCustomerCreated}
					onClose={() => this.setState({ isCreating: false })}
				/>
			</>
		);
	}
}

const getCustomerLabel = (customer) => {
	if (typeof customer !== 'object') {
		return customer || '';
	}
	return customer.fullName;
};

export default withSnackbarContext(CustomerAutocomplete);
