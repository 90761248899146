import * as client from 'api/client';

const AuthTokenLocalStorage = 'token';
const CompanyIdLocalStorage = 'companyId';
const CompanyLocalStorage = 'company';
const EventColorsLocalStorage = 'eventColors';
const IsAdminLocalStorage = 'isAdmin';
const MobilePromptLastClosed = 'mobilePromptLastClosed';
const PreferredCalendarViewStorage = 'preferredCalendarView';
const UserKeyLocalStorage = 'user';

const AllLocalStorageFields = [
	CompanyLocalStorage,
	EventColorsLocalStorage,
	PreferredCalendarViewStorage,
	UserKeyLocalStorage,
];

export const setUserInfoLocalStorage = (args) => {
	window.localStorage.setItem(UserKeyLocalStorage, JSON.stringify(args));
};

export const getUserInfoLocalStorage = () => {
	return JSON.parse(window.localStorage.getItem(UserKeyLocalStorage)) || {};
};

export const clearLocalStorage = () => {
	AllLocalStorageFields.forEach((field) => window.localStorage.removeItem(field));
};

// ----------------------------------------------------------------------
// Get/Set functions for specific fields
// ----------------------------------------------------------------------

export const getAuthKey = () => {
	return getUserInfoLocalStorage()[AuthTokenLocalStorage];
};

export const getIsAdmin = () => {
	return getUserInfoLocalStorage()[IsAdminLocalStorage];
};

export const getCompanyId = () => {
	return getUserInfoLocalStorage()[CompanyIdLocalStorage];
};

export const setCalendarView = (value) => {
	return window.localStorage.setItem(PreferredCalendarViewStorage, value);
};

export const getCalendarView = () => {
	let viewStored = window.localStorage.getItem(PreferredCalendarViewStorage);
	if (['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek'].indexOf(viewStored) < 0) {
		viewStored = 'dayGridMonth';
	}
	return viewStored;
};

export const setMobilePromptLastClosed = (timeMs) => {
	window.localStorage.setItem(MobilePromptLastClosed, timeMs);
};

export const getMobilePromptLastClosed = () => {
	const value = window.localStorage.getItem(MobilePromptLastClosed);
	return value || 0;
};

// ----------------------------------------------------------------------
// Company
// ----------------------------------------------------------------------

export const setCompanyDetailsToLocalStorage = (company) => {
	window.localStorage.setItem(CompanyLocalStorage, JSON.stringify(company));
};

export const getCompanyDetailsFromLocalStorage = async (forceFetch = false) => {
	let raw = undefined;
	if (forceFetch || !(raw = window.localStorage.getItem(CompanyLocalStorage))) {
		try {
			const response = await client.companyDetails();
			if (response.success) {
				setCompanyDetailsToLocalStorage(response.data.company);
				raw = JSON.stringify(response.data.company);
			}
		} catch (error) {
			console.error(error);
		}
	}
	return JSON.parse(raw);
};

// ----------------------------------------------------------------------
// Other helpers
// ----------------------------------------------------------------------

/** Return true if the current user has a stored token, false otherwise. Take
 * into account that the token doesn't mean the user is in fact authenticated,
 * it can be the case of an invalid token but still saved in local-storage. */
export const isAuthenticated = () => {
	const token = getUserInfoLocalStorage()[AuthTokenLocalStorage];
	return !!token && !!token.length;
};

// ----------------------------------------------------------------------
// Event Colors
// ----------------------------------------------------------------------

function getValue(key) {
	const value = window.localStorage.getItem(key);
	if (!!value) {
		const { expr, data } = JSON.parse(value);
		if (!!expr || expr > new Date().getTime()) {
			return data;
		}
		window.localStorage.removeItem(key);
	}
}

function setValue(key, value, ttlSeconds) {
	window.localStorage.setItem(
		key,
		JSON.stringify({
			data: value,
			expr: new Date(new Date().getTime() + ttlSeconds * 1000).getTime(),
		})
	);
}

export const getEventColorsCached = async () => {
	var result = getValue(EventColorsLocalStorage);
	if (!!result) {
		return result;
	}

	result = await client.getEventColors();
	if (result.success) {
		setValue(EventColorsLocalStorage, result.data.colors, 3600 /* 1 hour */);
		return result.data.colors;
	}

	return [];
};
