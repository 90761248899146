import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import { AndroidStoreLink, iOSStoreLink } from 'constant';
import { getMobileOperatingSystem } from 'utils/mobile';
import { setMobilePromptLastClosed, getMobilePromptLastClosed } from 'utils/localStorage';

class MobilePrompt extends Component {
	state = {
		showPrompt: false,
		link: null,
	};

	componentDidMount() {
		const os = getMobileOperatingSystem();
		if (os === 'Android' || os === 'iOS') {
			const lastClosed = getMobilePromptLastClosed();
			if (Date.now() - lastClosed > 604800000 /* 1 week */) {
				this.setState({
					showPrompt: true,
					link: os === 'Android' ? AndroidStoreLink : iOSStoreLink,
				});
			}
		}
	}

	handlePromptClosed = () => {
		this.setState({ showPrompt: false }, () => setMobilePromptLastClosed(Date.now()));
	};

	render() {
		return this.state.showPrompt ? (
			<Alert severity="info" onClose={this.handlePromptClosed}>
				<Link href={this.state.link}>
					Use <strong>Werkgo</strong> app for a better experience.
				</Link>
			</Alert>
		) : null;
	}
}

export default MobilePrompt;
