import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Page extends Component {
	render() {
		const { children, title, ref, ...rest } = this.props;
		return (
			<div ref={ref} {...rest}>
				<Helmet>
					<title>{title}</title>
				</Helmet>
				{children}
			</div>
		);
	}
}

export default React.forwardRef((props, ref) => <Page ref={ref} {...props} />);
