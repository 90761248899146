import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Select, MenuItem, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import styles from 'components/pagination/styles';

const Section = withStyles(styles)(({ selected, onClick, children, classes }) => (
	<Paper
		onClick={onClick}
		elevation={2}
		className={classNames(classes.paper, selected && classes.selectedPaper, onClick && classes.button)}
	>
		<Typography variant="h6" className={classNames(classes.text, selected && classes.selectedText)}>
			{children}
		</Typography>
	</Paper>
));

Section.propTypes = {
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};

class Pagination extends Component {
	render() {
		const { currentPage, pages, goToPage, itemsPerPage, onChangeItemsPerPage, classes } = this.props;

		const listToShow = [
			...new Set(
				[1, 2, currentPage - 1, currentPage, currentPage + 1, pages - 1, pages].filter((p) => p > 0 && p <= pages)
			),
		];

		return (
			<Grid container justify="center" wrap="nowrap">
				<Grid container justify="flex-end" className={classes.paginatorContainer}>
					{listToShow.length > 1 && (
						<>
							{currentPage > 1 && <Section onClick={() => goToPage(currentPage - 1)}>Prev</Section>}
							{listToShow.map((page, i) => (
								<Fragment key={page}>
									{i > 0 && page > listToShow[i - 1] + 1 && <Section>...</Section>}
									<Section onClick={page !== currentPage ? () => goToPage(page) : null} selected={page === currentPage}>
										{page}
									</Section>
								</Fragment>
							))}
							{currentPage < pages && <Section onClick={() => goToPage(currentPage + 1)}>Next</Section>}
						</>
					)}
				</Grid>
				<Grid container justify="flex-end" alignItems="center">
					<Typography variant="h6" className={classes.showRowsText}>
						Show rows
					</Typography>
					<Select
						defaultValue={10}
						value={itemsPerPage}
						variant="outlined"
						onChange={(e) => onChangeItemsPerPage(e.target.value)}
						classes={{ select: classes.selectFocus }}
						className={classes.select}
					>
						<MenuItem value={5}>
							<Typography variant="h5">5</Typography>
						</MenuItem>
						<MenuItem value={10}>
							<Typography variant="h5">10</Typography>
						</MenuItem>
						<MenuItem value={20}>
							<Typography variant="h5">20</Typography>
						</MenuItem>
					</Select>
				</Grid>
			</Grid>
		);
	}
}

Pagination.propTypes = {
	currentPage: PropTypes.number,
	pages: PropTypes.number,
	goToPage: PropTypes.func,
	itemsPerPage: PropTypes.number,
	onChangeItemsPerPage: PropTypes.func,
};

export default withStyles(styles)(Pagination);
