import React from 'react';
import { ClientInvoiceStyles } from './style';
import { withStyles } from '@material-ui/core';

const TextVerticalAlignment = withStyles(ClientInvoiceStyles)(({ rows, classes, align = 'right' }) => {
	const style = align !== 'right' ? classes.invoiceInfoTableSpaceBetweenAlign : classes.invoiceInfoTableRightAlign;

	return (
		<table className={style}>
			<tbody>
				{rows.map((row, index) => (
					<tr key={index}>
						<td>{row.label}</td>
						<td>&nbsp;{row.value}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
});

export default TextVerticalAlignment;
