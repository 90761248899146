import React, { Component } from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import Page from 'components/Page';
import Footer from 'components/Footer';

class P extends Component {
	render() {
		const { children, ...extra } = this.props;
		return (
			<Typography display="inline-block" paragraph {...extra}>
				{children}
			</Typography>
		);
	}
}

class Privacy extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Page title="Privacy policy">
				<Card className={classes.root}>
					<P>
						<Typography variant="h1">Privacy policy</Typography>
						<Typography variant="h5">Last updated June 13, 2023</Typography>
					</P>
					<P>
						Thank you for choosing to be part of our community at WERKGO (<b>&quot;Website&quot;</b>,{' '}
						<b>&quot;we&quot;</b>, <b>&quot;us&quot;</b>, <b>&quot;our&quot;</b>). We are committed to protecting your
						personal information and your right to privacy. If you have any questions or concerns about this privacy
						notice, or our practices with regards to your personal information, please contact us at{' '}
						<a href="info@werkgo.com">info@werkgo.com</a>.
					</P>
					<P>
						When you visit our website <a href="/">https://werkgo.com</a> (the <b>&quot;Website&quot;</b>), use our
						mobile application, as the case may be (the <b>&quot;App&quot;</b>) and more generally, use any of our
						services (the <b>&quot;Services&quot;</b>, which include the Website and App), we appreciate that you are
						trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we
						seek to explain to you in the clearest way possible what information we collect, how we use it and what
						rights you have in relation to it. We hope you take some time to read through it carefully, as it is
						important. If there are any terms in this privacy notice that you do not agree with, please discontinue use
						of our Services immediately.
					</P>
					<P>
						This privacy notice applies to all information collected through our Services (which, as described above,
						includes our Website and App), as well as, any related services, sales, marketing or events.
					</P>
					<P style={{ fontWeight: 'bold' }}>
						Please read this privacy notice carefully as it will help you understand what we do with the information
						that we collect.
					</P>

					{/* Table of Contents */}
					<P variant={'h3'}>TABLE OF CONTENTS</P>
					<ol style={{ listStyle: 'inside decimal', marginLeft: '32px' }}>
						<li>
							<a href="#point-01">WHAT INFORMATION DO WE COLLECT?</a>
						</li>
						<li>
							<a href="#point-02">HOW DO WE USE YOUR INFORMATION?</a>
						</li>
						<li>
							<a href="#point-03">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
						</li>
						<li>
							<a href="#point-04">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
						</li>
						<li>
							<a href="#point-05">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
						</li>
						<li>
							<a href="#point-06">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
						</li>
						<li>
							<a href="#point-07">DO WE COLLECT INFORMATION FROM MINORS?</a>
						</li>
						<li>
							<a href="#point-08">WHAT ARE YOUR PRIVACY RIGHTS?</a>
						</li>
						<li>
							<a href="#point-09">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
						</li>
						<li>
							<a href="#point-10">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
						</li>
						<li>
							<a href="#point-11">DO WE MAKE UPDATES TO THIS NOTICE?</a>
						</li>
						<li>
							<a href="#point-12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
						</li>
						<li>
							<a href="#point-13">HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a>
						</li>
					</ol>
					<P variant={'h3'} id="point-01">
						1. WHAT INFORMATION DO WE COLLECT?
					</P>
					<P>
						<b>Personal information you disclose to us</b>
					</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We collect personal information that you provide to us.
					</P>
					<P>
						We collect personal information that you voluntarily provide to us when you register on the Services,
						express an interest in obtaining information about us or our products and Services, when you participate in
						activities on the Services or otherwise when you contact us.
					</P>
					<P>
						The personal information that we collect depends on the context of your interactions with us and the
						Services, the choices you make and the products and features you use. The personal information we collect
						may include the following:
					</P>
					<P>
						<b>Personal Information Provided by You.</b> We collect names; phone numbers; email addresses; mailing
						addresses; usernames; passwords; job titles; contact or authentication data; billing addresses; debit/credit
						card numbers; contact preferences; photos and other similar information.
					</P>
					<P>
						<b>Contact list.</b> We may request access or permission to contact list to import contact information in
						the creation of a new customer. If you wish to change our access or permissions, you may do so in your
						device&apos;s settings.
					</P>
					<P>
						<b>Payment Data.</b> We may collect data necessary to process your payment if you make purchases, such as
						your payment instrument number (such as a credit card number), and the security code associated with your
						payment instrument. All payment data is stored by Stripe Inc.. You may find their privacy notice link(s)
						here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
					</P>
					<P>
						All personal information that you provide to us must be true, complete and accurate, and you must notify us
						of any changes to such personal information.
					</P>
					<P style={{ fontWeight: 'bold' }}>Information automatically collected</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: Some information — such as your Internet Protocol (IP) address and/or browser and device
						characteristics — is collected automatically when you visit our Services.
					</P>
					<P>
						We automatically collect certain information when you visit, use or navigate the Services. This information
						does not reveal your specific identity (like your name or contact information) but may include device and
						usage information, such as your IP address, browser and device characteristics, operating system, language
						preferences, referring URLs, device name, country, location, information about how and when you use our
						Services and other technical information. This information is primarily needed to maintain the security and
						operation of our Services, and for our internal analytics and reporting purposes.
					</P>
					<P>Like many websites, we also collect information through cookies and similar technologies.</P>
					<P>The information we collect includes:</P>

					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>
								<i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage and performance
								information our servers automatically collect when you access or use our Services and which we record in
								log files. Depending on how you interact with us, this log data may include your IP address, device
								information, browser type and settings and information about your activity in the Services (such as the
								date/time stamps associated with your usage, pages and files viewed, searches and other actions you take
								such as which features you use), device event information (such as system activity, error reports
								(sometimes called &apos;crash dumps&apos;) and hardware settings).
							</P>
						</li>

						<li>
							<P>
								<i>Device Data.</i> We collect device data such as information about your computer, phone, tablet or
								other device you use to access the Services. Depending on the device used, this device data may include
								information such as your IP address (or proxy server), device and application identification numbers,
								location, browser type, hardware model Internet service provider and/or mobile carrier, operating system
								and system configuration information.
							</P>
						</li>

						<li>
							<P>
								<i>Location Data.</i> We collect location data such as information about your device&apos;s location,
								which can be either precise or imprecise. How much information we collect depends on the type and
								settings of the device you use to access the Services. For example, we may use GPS and other
								technologies to collect geolocation data that tells us your current location (based on your IP address).
								You can opt out of allowing us to collect this information either by refusing access to the information
								or by disabling your Location setting on your device. Note however, if you choose to opt out, you may
								not be able to use certain aspects of the Services.
							</P>
						</li>
					</ul>

					<P>
						<b>Information collected through our App</b>
					</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We collect information regarding your geolocation, mobile device, push notifications, when
						you use our App.
					</P>
					<P>If you use our App, we also collect the following information:</P>

					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>
								<i>Geolocation Information.</i> We may request access or permission to and track location-based
								information from your mobile device, either continuously or while you are using our App, to provide
								certain location-based services. If you wish to change our access or permissions, you may do so in your
								device&apos;s settings.
							</P>
						</li>
						<li>
							<P>
								<i>Mobile Device Access.</i> We may request access or permission to certain features from your mobile
								device, including your mobile device&apos;s calendar, camera, contacts, microphone, storage, and other
								features. If you wish to change our access or permissions, you may do so in your device&apos;s settings.
							</P>
						</li>
						<li>
							<P>
								<i>Mobile Device Data.</i> We automatically collect device information (such as your mobile device ID,
								model and manufacturer), operating system, version information and system configuration information,
								device and application identification numbers, browser type and version, hardware model Internet service
								provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using
								our App, we may also collect information about the phone network associated with your mobile device,
								your mobile device’s operating system or platform, the type of mobile device you use, your mobile
								device’s unique device ID and information about the features of our App you accessed.
							</P>
						</li>
						<li>
							<P>
								<i>Push Notifications.</i> We may request to send you push notifications regarding your account or
								certain features of the App. If you wish to opt-out from receiving these types of communications, you
								may turn them off in your device&apos;s settings.
							</P>
						</li>
					</ul>

					<P>
						This information is primarily needed to maintain the security and operation of our App, for troubleshooting
						and for our internal analytics and reporting purposes.
					</P>
					<P variant={'h3'} id="point-02">
						2. HOW DO WE USE YOUR INFORMATION?
					</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We process your information for purposes based on legitimate interests, the fulfillment of
						our contract with you, compliance with our legal obligations, and/or your consent.
					</P>
					<P>
						We use personal information collected via our Services for a variety of purposes described below. We process
						your personal information for these purposes in reliance on our legitimate interests, in order to enter into
						or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We
						indicate the specific processing grounds we rely on next to each purpose listed below.
					</P>
					<P>We use the information we collect or receive:</P>

					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>
								<b>For other purposes.</b> We may use your information for other purposes, such as data analysis,
								identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and
								improve our Services, products, marketing and your experience. We may use and store this information in
								aggregated and anonymized form so that it is not associated with individual end users and does not
								include personal information. We will not use identifiable personal information without your consent.
							</P>
						</li>
					</ul>

					<P variant={'h3'} id="point-03">
						3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
					</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We only share information with your consent, to comply with laws, to provide you with
						services, to protect your rights, or to fulfill obligations.
					</P>
					<P>We may process or share your data that we hold based on the following legal basis:</P>
					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>
								<b>Consent:</b> We may process your data if you have given us specific consent to use your personal
								information for a specific purpose.
							</P>
						</li>
						<li>
							<P>
								<b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our
								legitimate interests.
							</P>
						</li>
						<li>
							<P>
								<b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your
								personal information to fulfill the terms of our contract.
							</P>
						</li>
						<li>
							<P>
								<b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in
								order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
								process, such as in response to a court order or a subpoena (including in response to public authorities
								to meet national security or law enforcement requirements).
							</P>
						</li>
						<li>
							<P>
								<b>Vital Interests:</b> We may disclose your information where we believe it is necessary to
								investigate, prevent, or take action regarding potential violations of our policies, suspected fraud,
								situations involving potential threats to the safety of any person and illegal activities, or as
								evidence in litigation in which we are involved.
							</P>
						</li>
					</ul>

					<P>
						More specifically, we may need to process your data or share your personal information in the following
						situations:
					</P>
					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>
								<b>Business Transfers.</b> We may share or transfer your information in connection with, or during
								negotiations of, any merger, sale of website assets, financing, or acquisition of all or a portion of
								our business to another company.
							</P>
						</li>
					</ul>

					<P variant={'h3'} id="point-04">
						4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
					</P>
					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We may use cookies and other tracking technologies to collect and store your information.
					</P>
					<P>
						We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
						information. Specific information about how we use such technologies and how you can refuse certain cookies
						is set out in our Cookie Notice.
					</P>
					<P variant={'h3'} id="point-05">
						5. HOW LONG DO WE KEEP YOUR INFORMATION?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We keep your information for as long as necessary to fulfill the purposes outlined in this
						privacy notice unless otherwise required by law.
					</P>

					<P>
						We will only keep your personal information for as long as it is necessary for the purposes set out in this
						privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
						other legal requirements). No purpose in this notice will require us keeping your personal information for
						longer than the period of time in which users have an account with us.
					</P>

					<P>
						When we have no ongoing legitimate needs to process your personal information, we will either delete or
						anonymize such information, or, if this is not possible (for example, because your personal information has
						been stored in backup archives), then we will securely store your personal information and isolate it from
						any further processing until deletion is possible.
					</P>

					<P variant={'h3'} id="point-06">
						6. HOW DO WE KEEP YOUR INFORMATION SAFE?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We aim to protect your personal information through a system of organizational and
						technical security measures.
					</P>

					<P>
						We have implemented appropriate technical and organizational security measures designed to protect the
						security of any personal information we process. However, despite our safeguards and efforts to secure your
						information, no electronic transmission over the Internet or information storage technology can be
						guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
						unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal,
						or modify your information. Although we will do our best to protect your personal information, transmission
						of personal information to and from our Services is at your own risk. You should only access the Services
						within a secure environment.
					</P>

					<P variant={'h3'} id="point-07">
						7. DO WE COLLECT INFORMATION FROM MINORS?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: We do not knowingly collect data from or market to children under 18 years of age.
					</P>

					<P>
						We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
						you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to
						such minor dependent’s use of the Services. If we learn that personal information from users less than 18
						years of age has been collected, we will deactivate the account and take reasonable measures to promptly
						delete such data from our records. If you become aware of any data we may have collected from children under
						age 18, please contact us at <a href="mailto:privacy@werkgo.com">privacy@werkgo.com</a>.
					</P>

					<P variant={'h3'} id="point-08">
						8. WHAT ARE YOUR PRIVACY RIGHTS?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: You may review, change, or terminate your account at any time.
					</P>

					<P>
						 If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal
						information, you also have the right to complain to your local data protection supervisory authority. You
						can find their contact details here:
						<a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
							http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
						</a>
						.
					</P>

					<P>
						If you are a resident in Switzerland, the contact details for the data protection authorities are available
						here:{' '}
						<a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
					</P>

					<P>
						If you have questions or comments about your privacy rights, you may email us at{' '}
						<a href="mailto:privacy@werkgo.com">privacy@werkgo.com</a>.
					</P>

					<P>Account Information</P>

					<P>
						If you would at any time like to review or change the information in your account or terminate your account,
						you can:
					</P>

					<ul style={{ marginLeft: '32px' }}>
						<li>
							<P>Sign In to your account settings and update your user account.</P>
						</li>
					</ul>

					<P>
						Upon your request to terminate your account, we will deactivate or delete your account and information from
						our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
						problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal
						requirements.
					</P>

					<P>
						Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you
						can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove
						cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of
						interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.
					</P>

					<P>
						Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on
						the unsubscribe link in the emails that we send or by contacting us using the details provided below. You
						will then be removed from the marketing email list — however, we may still communicate with you, for example
						to send you service-related emails that are necessary for the administration and use of your account, to
						respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
					</P>

					<P>- Access your account settings and update your preferences.</P>

					<P variant={'h3'} id="point-09">
						9. CONTROLS FOR DO-NOT-TRACK FEATURES
					</P>

					<P>
						Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
						(&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data
						about your online browsing activities monitored and collected. At this stage no uniform technology standard
						for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
						browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
						If a standard for online tracking is adopted that we must follow in the future, we will inform you about
						that practice in a revised version of this privacy notice.
					</P>

					<P variant={'h3'} id="point-10">
						10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>:  Yes, if you are a resident of California, you are granted specific rights regarding
						access to your personal information.
					</P>

					<P>
						California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our users
						who are California residents to request and obtain from us, once a year and free of charge, information
						about categories of personal information (if any) we disclosed to third parties for direct marketing
						purposes and the names and addresses of all third parties with which we shared personal information in the
						immediately preceding calendar year. If you are a California resident and would like to make such a request,
						please submit your request in writing to us using the contact information provided below.
					</P>

					<P>
						If you are under 18 years of age, reside in California, and have a registered account with a Service, you
						have the right to request removal of unwanted data that you publicly post on the Services. To request
						removal of such data, please contact us using the contact information provided below, and include the email
						address associated with your account and a statement that you reside in California. We will make sure the
						data is not publicly displayed on the Services, but please be aware that the data may not be completely or
						comprehensively removed from all our systems (e.g. backups, etc.).
					</P>

					<P variant={'h3'} id="point-11">
						11. DO WE MAKE UPDATES TO THIS NOTICE?
					</P>

					<P style={{ fontStyle: 'italic' }}>
						<b> In Short</b>: Yes, we will update this notice as necessary to stay compliant with relevant laws.
					</P>

					<P>
						We may update this privacy notice from time to time. The updated version will be indicated by an updated
						&quot;Revised&quot; date and the updated version will be effective as soon as it is accessible. If we make
						material changes to this privacy notice, we may notify you either by prominently posting a notice of such
						changes or by directly sending you a notification. We encourage you to review this privacy notice frequently
						to be informed of how we are protecting your information.
					</P>

					<P variant={'h3'} id="point-12">
						12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
					</P>

					<P>
						If you have questions or comments about this notice, you may email us at{' '}
						<a href="mailto:privacy@werkgo.com">privacy@werkgo.com</a>
					</P>

					<P variant={'h3'} id="point-13">
						13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
					</P>

					<P>
						Based on the applicable laws of your country, you may have the right to request access to the personal
						information we collect from you, change that information, or delete it in some circumstances. To request to
						review, update, or delete your personal information, please visit:{' '}
						<a href="/profile">https://werkgo.com/profile</a>.
					</P>
				</Card>

				<Footer />
			</Page>
		);
	}
}

const styles = (theme) => ({
	root: {
		width: '1024px',
		maxWidth: '100%',
		padding: theme.spacing(3),
		margin: 'auto',
	},
});

export default withStyles(styles)(Privacy);
