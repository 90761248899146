import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'*': {
				boxSizing: 'border-box',
				margin: 0,
				padding: 0,
			},
			html: {
				'-webkit-font-smoothing': 'antialiased',
				'-moz-osx-font-smoothing': 'grayscale',
				height: '100%',
				width: '100%',
			},
			body: {
				height: '100%',
				width: '100%',
			},
			'#root': {
				height: '100%',
				width: '100%',
			},
			// Light Gallery
			'.lg-delete-icon': {
				display: 'flex',
				'justify-content': 'center',
				'align-items': 'center',
			},
			'.lg-delete-icon > svg': {
				fill: '#999',
				width: '20px',
				height: '20px',
			},
		},
	})
);

const GlobalStyles = () => {
	useStyles();

	return null;
};

export default GlobalStyles;
