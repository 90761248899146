const styles = {
	text: {
		color: '#011842',
		fontSize: 16,
		fontFamily: 'Sarabun',
		fontWeight: 500,
		lineHeight: 1.334,
		letterSpacing: '-0.05px',
	},
	noButton: {
		color: '#808588 !important',
	},
};

export default styles;
