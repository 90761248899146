import { post, GenericSuccess, GenericError } from 'api/core';

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
type SubscriptionStatus =
	| 'incomplete'
	| 'incomplete_expired'
	| 'trialing'
	| 'active'
	| 'past_due'
	| 'canceled'
	| 'unpaid';

export interface SubscribeToPlanArgs {
	paymentMethodId: string;
}

export type SubscribeToPlanResult =
	| GenericSuccess<{
			clientSecret: string;
			subscriptions: {
				id: string;
				status: SubscriptionStatus;
				currentPeriodStart: number;
				currentPeriodEnd: number;
				amount: number;
			};
	  }>
	| GenericError;

export type SubscriptionsResult =
	| GenericSuccess<{
			clientSecret: string;
			subscriptions: {
				id: string;
				status: SubscriptionStatus;
				currentPeriodStart: number;
				currentPeriodEnd: number;
				amount: number;
			};
	  }>[]
	| GenericError;

export const subscribeToPlan = (args: SubscribeToPlanArgs): Promise<SubscriptionsResult> => {
	return post('subscribe/', args);
};

export const getSubscriptions = (): Promise<SubscriptionsResult> => {
	return post('subscriptions/', {});
};
