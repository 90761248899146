import * as models from 'types/models/models';
import * as enums from 'types/enums';
import { post, GenericSuccess } from 'api/core';
import Tax from 'types/models/Tax';

//
// api/invoice/create
//

interface InvoiceItemForCreation {
	name: string;
	description: string;
	quantity: number;
	price: number;
}

export interface CreateInvoiceArgs {
	customerId: number;
	projectId?: number;
	issuedDate: Date;
	paymentDueDateTerm: Date;
	invoiceCustomerNote?: string;
	items: InvoiceItemForCreation[];
	status: enums.InvoiceStatus;
	stripePaymentEnabled: boolean;
}

export type CreateInvoiceResult = GenericSuccess<{
	invoice: models.PublicInvoice;
}>;

export const createInvoice = (args: CreateInvoiceArgs): Promise<CreateInvoiceResult> => {
	return post('invoice/create/', args);
};

//
// api/invoice/list
//

interface GetInvoicesArgs {
	status?: enums.InvoiceStatus;
}

type GetInvoicesResult = GenericSuccess<{
	invoices: models.PublicInvoice;
}>;

export const getInvoices = (args: GetInvoicesArgs): Promise<GetInvoicesResult> => {
	return post('invoice/list/', args);
};

//
// api/invoice/get
//

interface GetInvoiceArgs {
	invoiceId?: number;
}

type GetInvoiceResult = GenericSuccess<{
	invoice: models.PublicInvoice;
}>;

export const getInvoice = (args: GetInvoiceArgs): Promise<GetInvoiceResult> => {
	return post('invoice/public/details/', args);
};

//
// api/invoice/record-opened/
//

interface RecordOpenedInvoiceArgs {
	invoiceUUID: string;
}

export const recordOpenedInvoice = (args: RecordOpenedInvoiceArgs): Promise<GenericSuccess<{}>> => {
	return post('invoice/record-opened/', args);
};

//
// api/invoice/item/list/
//

export const getInvoiceItems = (): Promise<GenericSuccess<{}>> => {
	return post('invoice/item/list/', {});
};

//
// api/invoice/item/create/
//

interface CreateInvoiceOriginalItemArgs {
	name: string;
	description: string;
	quantity: number;
	price: number;
	taxes: Tax[];
}

export const createInvoiceOriginalItem = (args: CreateInvoiceOriginalItemArgs): Promise<GenericSuccess<{}>> => {
	return post('invoice/item/create/', args);
};
