export const styles = () => ({
	thumbnailsWrapper: {
		display: 'flex',
	},
	thumbnailShape: {
		'object-fit': 'cover',
		borderRadius: 10,
		padding: 5,
		cursor: 'pointer',
		height: '100px',
		width: '100px',
	},
	thumbnailShapeSm: {
		height: '40px',
		width: '40px',
		// Avoid pronounced borders on small
		// thumbnails by setting a smaller border
		// radius.
		borderRadius: 5,
	},
	thumbnailShapeMd: {
		height: '100px',
		width: '100px',
	},
	thumbnailHidden: {
		display: 'none',
		visibility: 'hidden',
	},
	thumbnailExpand: {
		position: 'relative',
	},
	thumbnailPlusOverlay: {
		position: 'absolute',
		color: 'white',
		cursor: 'pointer',
		backgroundColor: '#0000007F',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
