import { scroll } from 'styles/shared';

const styles = {
	dialogContent: {
		...scroll,
	},
	titleDialog: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: '#f3f6f8',
		height: 50,
	},
	titleText: {
		fontWeight: 700,
	},
};

export default styles;
