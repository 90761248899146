import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { InfoOutlined, LocationOn, MonetizationOnOutlined } from '@material-ui/icons';
import { displayAmount } from 'utils/format';
import { Content } from 'components/table';
import IconLink from 'components/icons/IconLink';
import { ProjectStates } from 'utils/states';

const CustomerProjectMobileInfo = ({ project, ...props }) => (
	<Grid container direction="column" wrap="nowrap" {...props}>
		<Content component={Link} to={`/project/${project.projectId}/details`}>
			{project.title}
		</Content>
		<IconLink icon={MonetizationOnOutlined} text={displayAmount(project.price)} />
		<IconLink icon={InfoOutlined} text={ProjectStates[project.status].name} />
		<IconLink icon={LocationOn} text={project.address} href={`https://maps.google.com?q=${project.address}`} />
	</Grid>
);

export default CustomerProjectMobileInfo;
