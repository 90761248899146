// Get a link to create a Google Calendar event with all specified parameters.
export const getEventEditLink = ({ text, startTime, endTime, location, details, isMobile }) => {
	const dates = startTime.replace(/[:-]/g, '') + '/' + endTime.replace(/[:-]/g, '');

	const params = new URLSearchParams({
		text,
		location,
		dates,
		details,
		// ?action=create is only relevant for mobile deeplinks.
		action: 'create',
	});

	const baseUrl = isMobile ? 'com.google.calendar://?' : 'https://calendar.google.com/calendar/r/eventedit?';

	return baseUrl + params.toString();
};
