/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, GenericSuccess, GenericError } from 'api/core';

//***************
// TYPES
//***************

//
// getPhases
//

export interface GetPhasesArgs {}

export type GetPhasesResult =
	| GenericSuccess<{
			phases: models.ProjectPhase[];
	  }>
	| GenericError;

//
// phaseDetails
//

export interface PhaseDetailsArgs {
	id: number;
}

export type PhaseDetailsResult =
	| GenericSuccess<{
			phase: models.ProjectPhaseExtended;
	  }>
	| GenericError;

//
// createPhase
//

export interface CreatePhaseArgs {
	projectId: number;
	phase: {
		title: string;
		startTime: string;
		endTime: string;
		allowSmsReminder: boolean;
		color: string;
	};
}

export type CreatePhaseResult =
	| GenericSuccess<{
			phase: models.ProjectPhase;
	  }>
	| GenericError;

//
// ceatePhaseFromCalendar
//

export interface CreatePhaseFromCalendarArgs {
	customerId?: number;
	projectId?: number;
	phase: {
		title: string;
		start: string;
		end: string;
		color: string;
		allowSmsReminder: boolean;
	};
}

export type CreatePhaseFromCalendarResult =
	| GenericSuccess<{
			phase: models.ProjectPhase;
	  }>
	| GenericError;

//
// editPhase
//

export interface EditPhaseArgs {
	phaseId: number;
	title?: string;
	startTime: string;
	endTime: string;
	allowSmsReminder: boolean;
	color: string;
	extraFields: boolean;
	customerId?: number;
	projectId?: number;
}

export type EditPhaseResult =
	| GenericSuccess<{
			phase: models.ProjectPhase;
	  }>
	| GenericError;

//
// deletePhase
//

export interface DeletePhaseArgs {
	phaseId: number;
}

export type DeletePhaseResult = { success: true } | GenericError;

//
// getMostUsedEventNames
//

export type GetMostUsedEventNamesResult = GenericSuccess<{ names: string[] }> | GenericError;

//***************
// REQUESTS
//***************

export const getPhases = (args: GetPhasesArgs = {}): Promise<GetPhasesResult> => {
	return post('phases/', args);
};

export const phaseDetails = (args: PhaseDetailsArgs): Promise<PhaseDetailsResult> => {
	return post('phase/details/', args);
};

export const createPhase = (args: CreatePhaseArgs): Promise<CreatePhaseResult> => {
	return post('project/phase/create/', args);
};

export const createPhaseFromCalendar = (args: CreatePhaseFromCalendarArgs): Promise<CreatePhaseFromCalendarResult> => {
	return post('project/phase/create-from-calendar/', args);
};

export const editPhase = (args: EditPhaseArgs): Promise<EditPhaseResult> => {
	return post('project/phase/edit/', args);
};

export const deletePhase = (args: DeletePhaseArgs): Promise<DeletePhaseResult> => {
	return post('project/phase/delete/', args);
};

export const getMostUsedEventNames = (): Promise<GetMostUsedEventNamesResult> => {
	return post('event/most-used-names/', {});
};
