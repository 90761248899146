/* eslint-disable no-mixed-spaces-and-tabs */
import { post, postMultipart, GenericSuccess, GenericError } from 'api/core';
import * as models from 'types/models/models';

//***************
// TYPES
//***************

//
// companyDetails
//

export type CompanyDetailsResult =
	| GenericSuccess<{
			company: models.Company;
	  }>
	| GenericError;

//
// editCompany
//

export interface CompanyEditArgs {
	name: string;
	domain: string;
	email: string;
	phone: string;
	address: string;
	invoiceTermsAndConditions: string;
	invoiceCustomerNote: string;
	appointmentTemplate: string;
	appointmentNote: string;
	appointmentUseCompanyAddress: boolean;
}

export type CompanyEditResult =
	| GenericSuccess<{
			company: models.Company;
	  }>
	| GenericError;

//
// editCompany
//

export interface UpdateCompanyLogoArgs {
	logo: File;
}

export type UpdateCompanyLogoResult =
	| GenericSuccess<{
			company: models.Company;
	  }>
	| GenericError;

//
// deleteCompany
//

export type DeleteCompanyResult = GenericSuccess<{}> | GenericError;

//***************
// REQUESTS
//***************

export const companyDetails = (): Promise<CompanyDetailsResult> => {
	return post('company/details/', {});
};

export const editCompany = (args: CompanyEditArgs): Promise<CompanyEditResult> => {
	return post('company/edit/', args);
};

export const updateCompanyLogo = (args: UpdateCompanyLogoArgs): Promise<UpdateCompanyLogoResult> => {
	const data = new FormData();
	data.append('logo', args.logo);
	return postMultipart('company/update-logo/', data);
};

export const deleteCompany = (): Promise<DeleteCompanyResult> => {
	return post('company/delete/', {});
};
