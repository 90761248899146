const styles = (theme) => ({
	container: {
		background: 'linear-gradient(#1DDD7D, #00B2A1)',
		[theme.breakpoints.down('sm')]: {
			padding: '100px 20px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '40px 20px',
		},
	},
	imageContainer: {
		textAlign: 'center',
	},
	title: {
		fontWeight: 800,
		fontSize: 40,
		color: '#fff',
		textAlign: 'center',
	},
	subtitle: {
		color: '#fff',
		textAlign: 'center',
		margin: '20px 0 30px',
		fontWeight: 600,
	},
	werkgoWord: {
		color: '#011842',
	},
	playStoreAppBadge: {
		height: '99px',
	},
	appStoreAppBadge: {
		height: '68px',
	},
});

export default styles;
