import React from 'react';
import { Box, Button, Card, CardContent, Container, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';

const VerifyAccountSuccess = () => (
	<BarnWrapper title="Account verified | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" variant="h2">
							Account verified
						</Typography>
						<Typography variant="body1" color="textSecondary">
							Your account is now verified and you can start using werkgo. Login to start contributing to your company.
						</Typography>
					</Box>
					<Box flexGrow={1} mt={2}>
						<Button href="/signin" fullWidth size="large" variant="outlined" color="primary">
							← Sign In
						</Button>
					</Box>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default VerifyAccountSuccess;
