import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import { emphasize, withStyles } from '@material-ui/core/styles';

export const ActionsStyle = styled.div`
	text-align: center;
	display: flex;

	a {
		padding: 5px;
	}
`;

export const StyledBreadcrumb = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.grey[1],
		height: theme.spacing(3),
		color: theme.palette.grey[800],
		'&:hover, &:focus': {
			backgroundColor: theme.palette.grey[300],
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(theme.palette.grey[300], 0.12),
		},
	},
	// TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
}))(Chip);

export const ellipsisText = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

export const scroll = {
	overflowY: 'auto',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		width: 5,
		borderRadius: 4,
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#00B2A120',
		borderRadius: 4,
		margin: '10px 0',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#00B2A150',
		width: 10,
		borderRadius: 10,
	},
};
