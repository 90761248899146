import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box, Grid, Typography, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { MoreVert } from '@material-ui/icons';
import * as client from 'api/client';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { Button, Menu } from 'components/common';
import { Row, Column, Header } from 'components/table';
import styles from 'modules/Customer/Details/styles/projectSection';
import EditProject from 'modules/Project/Edit';
import CreateProjectDrawer from 'modules/Project/CreateProjectDrawer';
import { withRouter } from 'react-router-dom';
import CustomerProjectMobileInfo from 'modules/Customer/Details/CustomerProjectMobileInfo';
import EmptyState from 'components/EmptyState';

const headerInfo = [
	{ text: 'Project', xs: 10 },
	{ text: 'Action', xs: 2 },
];

class CustomerProjectsMobileTable extends Component {
	state = {
		projects: this.props.projects,
		editingProject: null,
		isCreating: false,
	};

	getMenuItemsForProject = (project) => {
		return [
			{
				value: 'edit',
				label: 'Edit',
				onClick: () => this.setState({ editingProject: project }),
			},
			{
				value: 'delete',
				label: 'Delete',
				onClick: () => this.deleteProject(project.projectId),
			},
		];
	};

	//----------------------------------------------------------------------
	// Delete project handlers
	//----------------------------------------------------------------------

	deleteProject = (projectId) => {
		const { snackbarContext } = this.props;

		client.deleteProject({ projectId }).then((res) => {
			if (res.success) {
				snackbarContext.success('Project deleted successfully!');

				this.setState((prevState) => ({
					projects: prevState.projects.filter((p) => p.projectId !== projectId),
				}));
			} else {
				snackbarContext.failure('Error deleting project!');
			}
		});
	};

	//----------------------------------------------------------------------
	// Edit project handlers
	//----------------------------------------------------------------------

	handleProjectEdited = (editedProject) => {
		this.setState((previousState) => ({
			editingProject: null,
			projects: previousState.projects.map((project) =>
				project.projectId === editedProject.projectId ? editedProject : project
			),
		}));
	};

	handleCloseEditProjectDrawer = () => {
		this.setState({ editingProject: null });
	};

	//----------------------------------------------------------------------
	// Create project handlers
	//----------------------------------------------------------------------

	handleClickCreateProjectIntention = (e) => {
		e.preventDefault();
		this.setState({ isCreating: true });
	};

	handleProjectCreated = (project) => {
		const { history } = this.props;
		history.push(`/project/${project.projectId}/details`);
	};

	handleCloseCreateProjectDrawer = () => {
		this.setState({ isCreating: false });
	};

	//----------------------------------------------------------------------
	// Render
	//----------------------------------------------------------------------

	renderProjectRows = () => {
		const { projects } = this.state;

		if (!projects.length) {
			return (
				<Row>
					<Column xs={12}>
						<EmptyState name="es-no-projects" title={'No projects yet'}>
							Use the &ldquo;+ Add Project&rdquo; button to create a new project for this customer.
						</EmptyState>
					</Column>
				</Row>
			);
		}

		return projects.map((project) => (
			<Row key={project.projectId}>
				<Column justifyContent="flex-start" xs={10}>
					<CustomerProjectMobileInfo project={project} />
				</Column>
				<Column justifyContent="flex-end" xs={2}>
					<Menu
						items={this.getMenuItemsForProject(project)}
						button={
							<IconButton>
								<MoreVert />
							</IconButton>
						}
					/>
				</Column>
			</Row>
		));
	};

	render() {
		const { customer, classes } = this.props;
		const { editingProject } = this.state;

		return (
			<Grid item xs={12}>
				<EditProject
					project={editingProject}
					onEdit={this.handleProjectEdited}
					onClose={this.handleCloseEditProjectDrawer}
				/>

				<CreateProjectDrawer
					customerId={customer.id}
					customerAddress={customer.address}
					isCreating={this.state.isCreating}
					onProjectCreated={this.handleProjectCreated}
					onClose={this.handleCloseCreateProjectDrawer}
				/>

				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h4" className={classes.title}>
						Projects
					</Typography>
					<Button
						component={Link}
						onClick={this.handleClickCreateProjectIntention}
						variant="contained"
						color="primary"
						startIcon={<Add />}
						height={38}
					>
						Add Project
					</Button>
				</Box>
				<Box mt="5px">
					<Grid container>
						<Header headerInfo={headerInfo} />
						{this.renderProjectRows()}
					</Grid>
				</Box>
			</Grid>
		);
	}
}

CustomerProjectsMobileTable.propTypes = {
	customer: PropTypes.object.isRequired,
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			status: PropTypes.number,
			files: PropTypes.arrayOf(PropTypes.string),
		})
	),
};

export default withRouter(withStyles(styles)(withSnackbarContext(CustomerProjectsMobileTable)));
