/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, GenericSuccess, GenericError } from 'api/core';
import { Pagination, SearchFilter, SearchTerm } from 'api/shared';

//***************
// TYPES
//***************

//
// getProjects
//

export interface ProjectListArgs {
	filters?: SearchFilter;
	pagination?: Pagination;
	search?: SearchTerm;
	withCustomer?: boolean;
}

export type ProjectDetailsResult =
	| GenericSuccess<{
			project: models.Project;
	  }>
	| GenericError;

//
// projectDetails
//

export interface ProjectDetailsArgs {
	projectId: number;
	withCustomer: boolean;
}

//
// editProject
//

interface EditProjectArgs {
	projectId: number;
	project: {
		price: number;
		title: string;
		address: string;
		status: any;
		paymentMethod: any;
	};
}

//
// createNote
//

export interface CreateNoteArgs {
	projectId: number;
	note: string;
}

export type CreateNoteResult =
	| GenericSuccess<{
			note: models.ProjectNote;
	  }>
	| GenericError;

//
// deleteNote
//

export interface DeleteNoteArgs {
	noteId: number;
}

export type DeleteNoteResult = GenericSuccess<{}> | GenericError;

//
// getMostUsedProjectNames
//

export type GetMostUsedProjectResult = GenericSuccess<{ names: string[] }> | GenericError;

//***************
// REQUESTS
//***************

export const getProjects = (args: ProjectListArgs = {}): Promise<ProjectDetailsResult> => {
	return post('projects/', args);
};

export const projectDetails = (args: ProjectDetailsArgs): Promise<ProjectDetailsResult> => {
	return post('project/details/', args);
};

export const editProject = (args: EditProjectArgs): Promise<EditProjectArgs> => {
	return post('project/edit/', args);
};

export const deleteProject = (args: ProjectDetailsArgs): Promise<ProjectDetailsResult> => {
	return post('project/remove/', args);
};

export const createNote = (args: CreateNoteArgs): Promise<CreateNoteResult> => {
	return post('note/create/', args);
};

export const deleteNote = (args: DeleteNoteArgs): Promise<DeleteNoteResult> => {
	return post('note/delete/', args);
};

export const getMostUsedProjectNames = (): Promise<GetMostUsedProjectResult> => {
	return post('project/most-used-names/', {});
};
