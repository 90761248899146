import React from 'react';
import { withStyles, Link } from '@material-ui/core';

const IconLink = ({ icon: Icon, suffix: SuffixComponent, text, href, classes }) =>
	!!text && (
		<div className={classes.root}>
			<Icon className={classes.icon} />
			<Link
				component="a"
				href={href}
				color="secondary"
				className={classes.anchor}
				style={{
					textDecoration: 'none',
				}}
			>
				{text}
			</Link>
			{SuffixComponent}
		</div>
	);

const styles = () => ({
	anchor: {
		color: '#535353',
		lineHeight: '17px',
		marginLeft: 5,
		marginTop: 5,
		fontSize: '16px',
	},
	icon: {
		color: '#535353',
		height: 18,
		width: 18,
	},
	root: {
		padding: '2px',
	},
});

export default withStyles(styles)(IconLink);
