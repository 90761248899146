import React from 'react';
import { Box, Typography, CardContent } from '@material-ui/core';

const StripeSuccessfullyConnected = () => (
	<Box mx="auto" p={2} bgcolor="background.paper" style={{ maxWidth: '512px' }}>
		<CardContent
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<img alt="stripe-logo" style={{ maxHeight: '64px' }} src={require('statics/images/integrations/stripe.svg')} />
			<span style={{ fontSize: '32px' }}>/</span>
			<img alt="werkgo-logo" style={{ maxHeight: '64px' }} src={require('statics/images/home/logo.png')} />
		</CardContent>

		<Typography variant="h4" component="h4" align="center">
			Your company just integrated with Stripe!
		</Typography>

		<Typography paragraph={true} align="justify">
			Once Stripe validates your information you can start accepting online payments using invoices directly from
			Werkgo.
			<b>
				{' '}
				Please allow a few minutes for it. Login into <a href="https://dashboard.stripe.com/login">Stripe.com</a> to
				check your account validation stage.
			</b>{' '}
			Also, make sure to review Stripe&apos;s fees (<a href="https://stripe.com/pricing">link</a>) and schedule your
			payouts (<a href="https://dashboard.stripe.com/settings/payouts">link</a>).
		</Typography>
	</Box>
);

export default StripeSuccessfullyConnected;
