import React from 'react';
import { useMediaQuery } from '@material-ui/core';

/**
 * Creates a Higher-Order Component that injects `isMobile` prop into
 * the wrapped component. With this approach, we just need to set the
 * breaking point in a single place (here) and that will be used for all
 * interested parties.
 */
export const withIsMobile = (WrappedComponent) => {
	const Component = (props) => {
		const isMobile = useMediaQuery('(max-width:480px)');
		return <WrappedComponent {...props} isMobile={isMobile} />;
	};
	Component.displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
	return Component;
};
