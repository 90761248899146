export const capitalizeFirstLetter = (str) => {
	if (!str || typeof str !== 'string') {
		return str;
	}

	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getInitials = (name = '') => {
	return name
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map((v) => v && v[0].toUpperCase())
		.join('');
};
