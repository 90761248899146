import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link, Typography, withStyles } from '@material-ui/core';
import { LocationOnOutlined, PersonOutlineOutlined, AttachMoneyOutlined } from '@material-ui/icons';

import { ExtendedStatus } from 'utils/project';
import AttachmentsList from 'components/AttachmentsList';
import styles from 'modules/Project/Details/styles/infoSection';
import { displayAmount } from 'utils/format';
import UploadButton from 'components/uploadButton';
import { Add } from '@material-ui/icons';

class InfoSection extends Component {
	render() {
		const { project, onUploadFiles, onDeleteAttachment, classes } = this.props;

		return (
			<>
				<Box bgcolor="#fff" borderRadius="10px" p="20px 40px">
					<Grid container direction="column" justify="space-between">
						{/* Customer & Project Details */}
						<Grid item container direction="row" justify="space-between" alignItems="center">
							<Typography variant="h3" className={classes.title}>
								{project.title}
							</Typography>
							<ExtendedStatus status={project.status} paymentMethod={project.paymentMethod} />
						</Grid>

						<Grid item container direction="row" wrap="nowrap" alignItems="center">
							<PersonOutlineOutlined className={classes.icon} />
							<Link
								component="a"
								href={`/customer/${project.customer.id}/details`}
								color="secondary"
								className={classes.info}
								style={{
									textDecoration: 'none',
								}}
							>
								{project.customer.fullName}
							</Link>
						</Grid>

						{project.address && (
							<Grid item container direction="row" wrap="nowrap" alignItems="center">
								<LocationOnOutlined className={classes.icon} />
								<Link
									component="a"
									href={`https://maps.google.com?q=${project.address}`}
									color="secondary"
									className={classes.info}
									style={{
										textDecoration: 'none',
									}}
								>
									{project.address}
								</Link>
							</Grid>
						)}

						<Grid item container direction="row" wrap="nowrap" alignItems="center">
							<AttachMoneyOutlined className={classes.icon} />
							<span className={classes.info}>{!project.price ? '---' : displayAmount(project.price)}</span>
						</Grid>

						{/* Project Attachments Section */}
						<Grid
							alignItems="center"
							className={classes.attachmentsHeader}
							container
							direction="row"
							item
							justify="space-between"
						>
							<Typography variant="h3" className={classes.subtitle}>
								Project files
							</Typography>
							<UploadButton
								onUploadFiles={onUploadFiles}
								variant="contained"
								color="primary"
								startIcon={<Add />}
								height={38}
							>
								Upload File
							</UploadButton>
						</Grid>

						<Grid item container direction="row" justify="space-between" alignItems="center">
							<AttachmentsList attachments={project.attachments} onDeleteItem={onDeleteAttachment} />
						</Grid>
					</Grid>
				</Box>
			</>
		);
	}
}

InfoSection.propTypes = {
	project: PropTypes.shape({
		title: PropTypes.string.isRequired,
		customer: PropTypes.shape({
			fullName: PropTypes.string.isRequired,
			address: PropTypes.string,
		}).isRequired,
		status: PropTypes.number.isRequired,
		attachments: PropTypes.arrayOf(PropTypes.object),
	}).isRequired,
	onUploadFiles: PropTypes.func.isRequired,
};

export default withStyles(styles)(InfoSection);
