import * as enums from 'types/enums';

export const ProjectStates = {
	[enums.ProjectStatus.NeedProposal]: {
		name: 'Need estimate',
		foregroundColor: '#536572',
		backgroundColor: '#B1C2CC',
	},
	[enums.ProjectStatus.WaitingForAcceptance]: {
		name: 'Pending',
		foregroundColor: '#A2773B',
		backgroundColor: '#F5D8B0',
	},
	[enums.ProjectStatus.NeedInstallation]: {
		name: 'In progress',
		foregroundColor: '#19647F',
		backgroundColor: '#B3E3F7',
	},
	[enums.ProjectStatus.InstallationCompleted]: {
		name: 'Done',
		foregroundColor: '#3A9166',
		backgroundColor: '#ADEFCC',
	},
	[enums.ProjectStatus.PaidToCompany]: {
		name: 'Paid',
		foregroundColor: '#3A9166',
		backgroundColor: '#ADEFCC',
	},
	[enums.ProjectStatus.Canceled]: {
		name: 'Canceled',
		foregroundColor: '#893237',
		backgroundColor: '#EEBBBD',
	},
};

export const PaymentDueDateType = {
	[enums.PaymentDueTerm.OnReceipt]: 'On receipt',
	[enums.PaymentDueTerm.Within07Days]: 'Within 7 days',
	[enums.PaymentDueTerm.Within14Days]: 'Within 14 days',
	[enums.PaymentDueTerm.Within21Days]: 'Within 21 days',
	[enums.PaymentDueTerm.Within30Days]: 'Within 30 days',
	[enums.PaymentDueTerm.Within60Days]: 'Within 60 days',
	[enums.PaymentDueTerm.Within90Days]: 'Within 90 days',
};

// Used to filter projects down by status. This is just a list of objects
// to be used in <Select /> components where each item has the following
// structure: { value: number|string, label: string }. In this case, we need
// to add a special status (All) with value -1 to instruct the list endpoint
// to not filter by status and return all projects instead.
export const ProjectStatusSelect = [
	{ label: 'All', value: -1 },
	...Object.keys(ProjectStates)
		.filter((status) => Object.prototype.hasOwnProperty.call(ProjectStates, status))
		.map((status) => ({ value: +status, label: ProjectStates[status].name })),
];

export const PaymentDueDateSelect = Object.keys(PaymentDueDateType)
	.filter((paymentDueDate) => Object.prototype.hasOwnProperty.call(PaymentDueDateType, paymentDueDate))
	.map((paymentDueDate) => ({ value: +paymentDueDate, label: PaymentDueDateType[paymentDueDate] }));

export const InvoiceStatusLabelInfo = {
	[enums.InvoiceStatus.Draft]: {
		name: 'Draft',
		foregroundColor: '#536572',
		backgroundColor: '#B1C2CC',
	},
	[enums.InvoiceStatus.Open]: {
		name: 'Open',
		foregroundColor: '#A2773B',
		backgroundColor: '#F5D8B0',
	},
	[enums.InvoiceStatus.Deleted]: {
		name: 'Deleted',
		foregroundColor: '#893237',
		backgroundColor: '#EEBBBD',
	},
	[enums.InvoiceStatus.Paid]: {
		name: 'Paid',
		foregroundColor: '#3A9166',
		backgroundColor: '#ADEFCC',
	},
	[enums.InvoiceStatus.Sent]: {
		name: 'Sent',
		foregroundColor: '#19647F',
		backgroundColor: '#B3E3F7',
	},
	[enums.InvoiceStatus.Uncollectible]: {
		name: 'Uncollectible',
		foregroundColor: '#893237',
		backgroundColor: '#EEBBBD',
	},
	[enums.InvoiceStatus.Void]: {
		name: 'Void',
		foregroundColor: '#536572',
		backgroundColor: '#B1C2CC',
	},
};
