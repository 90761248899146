import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Card, Grid, Divider, TextField, CardHeader, CardContent, FormHelperText } from '@material-ui/core';
import { Button } from 'components/common';
import ConfirmationAlert from 'components/confirmationAlert';
import * as client from 'api/client';
import { withSnackbarContext } from 'context/SnackbarsContext';

const messages = {
	deleteAccountConfirmationMsg: `Are you sure you want to delete your account? This action is irreversible.`,
	// eslint-disable-next-line max-len
	failureDeletingAccountMsg: `Oops! We couldn't delete your account. Please try again or contact info@werkgo.com for assistance.`,
	successDeletingAccountMsg: `Account deleted successfully!`,
};

class GeneralSettings extends Component {
	state = {
		showAccountDeletionPopup: false,
	};

	onDeleteAccount() {
		client
			.deleteCompany()
			.then((response) => {
				const { snackbarContext } = this.props;
				if (response.success) {
					snackbarContext.success(messages.successDeletingAccountMsg);
					document.location.reload();
				} else {
					snackbarContext.failure(messages.failureDeletingAccountMsg);
				}
			})
			.finally(() => {
				this.setState({ showAccountDeletionPopup: false });
			});
	}

	render() {
		const { className, user, userUpdate, ...rest } = this.props;
		const { showAccountDeletionPopup } = this.state;

		return (
			<>
				<ConfirmationAlert
					openAlert={showAccountDeletionPopup}
					onCancelation={() => this.setState({ showAccountDeletionPopup: false })}
					onConfirmation={() => this.onDeleteAccount()}
					message={messages.deleteAccountConfirmationMsg}
				/>

				<Formik
					enableReinitialize
					initialValues={{
						email: user.email || '',
						firstName: user.firstName || '',
						lastName: user.lastName || '',
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
						firstName: Yup.string().max(255).required('First Name is required'),
						lastName: Yup.string().max(255),
					})}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true);
						userUpdate(values)
							.then(() => setSubmitting(false))
							.catch(() => setSubmitting(false));
					}}
				>
					{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
						<form onSubmit={handleSubmit}>
							<Card className={className} {...rest}>
								<CardHeader title="Profile" />
								<Divider />
								<CardContent>
									<Grid container spacing={4}>
										<Grid item md={6} xs={12}>
											<TextField
												error={Boolean(touched.firstName && errors.firstName)}
												fullWidth
												helperText={touched.firstName && errors.firstName}
												label="First Name"
												name="firstName"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.firstName}
												variant="outlined"
												required
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												fullWidth
												label="Last Name"
												name="lastName"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.lastName}
												variant="outlined"
											/>
										</Grid>
										<Grid item md={6} xs={12}>
											<TextField
												fullWidth
												helperText="We will use this email to contact you"
												label="Email Address"
												name="email"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												value={values.email}
												variant="outlined"
											/>
										</Grid>
									</Grid>
									{errors.submit && (
										<Box mt={3}>
											<FormHelperText error>{errors.submit}</FormHelperText>
										</Box>
									)}
								</CardContent>
								<Divider />
								<Box p={2} display="flex" justifyContent="space-between">
									<Button
										color="secondary"
										disabled={isSubmitting}
										variant="link"
										height="50px"
										onClick={() => this.setState({ showAccountDeletionPopup: true })}
									>
										Delete account
									</Button>

									<Button color="primary" disabled={isSubmitting} type="submit" variant="contained" height="50px">
										Save Changes
									</Button>
								</Box>
							</Card>
						</form>
					)}
				</Formik>
			</>
		);
	}
}

GeneralSettings.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object.isRequired,
};

export default withSnackbarContext(GeneralSettings);
