import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Typography, withStyles } from '@material-ui/core';

import styles from 'modules/Home/styles/section';

class Section extends Component {
	render() {
		const { title, text, leftImage, rightImage, classes } = this.props;

		return (
			<Grid container justify="center">
				<Grid container direction="row" alignItems="center" className={classes.container}>
					{leftImage && (
						<Grid item md={6}>
							<img alt="Left side" src={leftImage} className={classes.image} />
						</Grid>
					)}
					<Hidden mdUp>
						{rightImage ? (
							<Grid item md={6}>
								<img alt="Right side" src={rightImage} className={classes.image} />
							</Grid>
						) : null}
					</Hidden>
					<Grid container item md={6} direction="column" className={classes.textContainer}>
						<Typography variant="h2" className={classes.title}>
							{title}
						</Typography>
						<Typography variant="h5" className={classes.text}>
							{text}
						</Typography>
						{/* <Link href={url} variant="h4" className={classes.link}>
							<u>L</u>earn more
						</Link> */}
					</Grid>
					<Hidden smDown>
						{rightImage ? (
							<Grid item md={6}>
								<img alt="Right side" src={rightImage} className={classes.image} />
							</Grid>
						) : null}
					</Hidden>
				</Grid>
			</Grid>
		);
	}
}

Section.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	leftImage: PropTypes.string,
	rightImage: PropTypes.string,
	url: PropTypes.string,
	classes: PropTypes.object,
};

export default withStyles(styles)(Section);
