import React from 'react';
import { Chip, Typography, Box } from '@material-ui/core';
import { ProjectStates } from 'utils/states';
import { PaymentStatus } from 'types/enums';
import { InvoiceStatusLabelInfo } from 'utils/states';

export const Status = ({ status }) => (
	<Chip
		size="small"
		label={ProjectStates[status].name}
		style={{
			color: ProjectStates[status].foregroundColor,
			background: ProjectStates[status].backgroundColor,
			fontWeight: 'bold',
		}}
	/>
);

export const InvoiceStatusLabel = ({ status }) => (
	<Chip
		size="small"
		label={InvoiceStatusLabelInfo[status].name}
		style={{
			color: InvoiceStatusLabelInfo[status].foregroundColor,
			background: InvoiceStatusLabelInfo[status].backgroundColor,
			fontWeight: 'bold',
		}}
	/>
);

// Similar to the simple status but includes the payment information. This component is
// used only in ProjectInfo.
export const ExtendedStatus = ({ status, paymentMethod }) => (
	<Box borderRadius="20px" p="5px 20px" bgcolor={ProjectStates[status].backgroundColor}>
		<Typography variant="h5" align="center" style={{ color: ProjectStates[status].foregroundColor }}>
			{ProjectStates[status].name}
			<strong>{+paymentMethod ? ` via ${PaymentStatus[paymentMethod]}` : null}</strong>
		</Typography>
	</Box>
);
