import React, { Component } from 'react';
import { Grid, Link as MuiLink, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Row, Column, Header, Content } from 'components/table';
import Actions from 'components/common/Actions';
import { displayAmount } from 'utils/format';
import { Status } from 'utils/project';

const headerInfo = [
	{ text: 'Name', xs: 4 },
	{ text: 'Customer', xs: 2 },
	{ text: 'Price', xs: 2 },
	{ text: 'Status', xs: 2 },
	{ text: '', xs: 2 },
];

class Table extends Component {
	render() {
		const { projects, classes, onRemoveProject, onUpdateProject } = this.props;

		return (
			<>
				<Header headerInfo={headerInfo} />
				{projects.map((project) => {
					const { projectId, title, address, customer, price, status } = project;
					return (
						<Row key={projectId}>
							<Column justifyContent="flex-start" xs={4}>
								<Grid container direction="column" wrap="nowrap">
									<Content component={Link} to={`/project/${projectId}/details`}>
										{title}
									</Content>
									{address && (
										<Content
											component={MuiLink}
											href={`https://maps.google.com?q=${address}`}
											className={classes.secondary}
										>
											{address}
										</Content>
									)}
								</Grid>
							</Column>

							<Column xs={2}>{customer?.fullName}</Column>

							<Column xs={2}>
								<Content>{displayAmount(price)}</Content>
							</Column>

							<Column xs={2}>
								<Status status={status} />
							</Column>

							<Column justifyContent="flex-end" xs={2}>
								<Actions
									updateOnClick={() => onUpdateProject(project)}
									removeOnClick={() => onRemoveProject(project)}
								/>
							</Column>
						</Row>
					);
				})}
			</>
		);
	}
}

Table.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			projectId: PropTypes.number.isRequired,
			title: PropTypes.string,
			address: PropTypes.string,
			customer: PropTypes.shape({
				fullName: PropTypes.string,
			}),
			price: PropTypes.number,
			status: PropTypes.number,
			lastUpdated: PropTypes.string,
		})
	).isRequired,
	onRemoveProject: PropTypes.func.isRequired,
};

const styles = {
	secondary: {
		fontSize: 14,
		color: '#9b9b9b',
	},
};

export default withStyles(styles)(Table);
