import React from 'react';

import * as client from 'api/client';
import EventFormDrawer from 'modules/Event/EventFormDrawer';

const CreateEventDrawer = ({ projectId, isCreating, companyAllowSmsReminders, onEventCreated, onClose }) => (
	<EventFormDrawer
		drawerTitle={'Create event'}
		submitButtonLabel={'Create'}
		companyAllowSmsReminders={companyAllowSmsReminders}
		isOpen={isCreating}
		onClose={onClose}
		title={''}
		startTime={null}
		endTime={null}
		allowSmsReminder={false}
		color={null}
		showTitleSuggestions={true}
		onSubmit={(phase) =>
			client.createPhase({ projectId, phase }).then((response) => {
				if (response.success) {
					onEventCreated(response.data.phase);
				}
				return response;
			})
		}
	/>
);

export default CreateEventDrawer;
