import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withAuthContext } from 'context/AuthContext';
import { withSnackbarContext } from 'context/SnackbarsContext';
import ProfileDetails from './ProfileDetails';
import GeneralSettings from './GeneralSettings';
import * as client from 'api/client';

class General extends Component {
	state = {
		email: '',
		lastName: '',
		firstName: '',
	};

	componentDidMount = () => {
		const { snackbarContext } = this.props;

		client
			.getCurrentUserInfo()
			.then((response) => {
				if (response.success) {
					const { email, firstName, lastName } = response.data.user;
					this.setState({
						email,
						lastName,
						firstName,
					});
				} else {
					snackbarContext.failure('Something went wrong');
				}
			})
			.catch((error) => {
				snackbarContext.failure('Something went wrong');
				console.error(error);
			});
	};

	userUpdate = (values) => {
		const {
			authContext: { updateUser },
			snackbarContext,
		} = this.props;

		const { firstName, lastName, email } = values;

		client
			.editCurrentUserInfo({ firstName, lastName, email })
			.then((response) => {
				if (response.success) {
					snackbarContext.success('User updated');
					updateUser(response.data.user);
				} else {
					snackbarContext.failure('Something went wrong');
				}
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure('Something went wrong');
			});
	};

	render() {
		const { className } = this.props;
		const user = { ...this.state };

		return (
			<Grid className={className} container spacing={3}>
				<Grid item lg={4} md={6} xl={3} xs={12}>
					<ProfileDetails user={user} />
				</Grid>
				<Grid item lg={8} md={6} xl={9} xs={12}>
					<GeneralSettings userUpdate={this.userUpdate} user={user} />
				</Grid>
			</Grid>
		);
	}
}

General.propTypes = {
	className: PropTypes.string,
};

export default withAuthContext(withSnackbarContext(General));
