import React, { Component } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import {
	Box,
	CircularProgress,
	Drawer,
	IconButton,
	InputAdornment,
	Link,
	TextField,
	Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SearchOutlined, HighlightOffOutlined } from '@material-ui/icons';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import CustomerSearchItem from 'modules/GlobalSearch/CustomerItemSeach';
import ProjectItemSearch from 'modules/GlobalSearch/ProjectItemSearch';
import NoteItemSearch from 'modules/GlobalSearch/NoteItemSearch';

import { Button } from 'components/common';

const styles = {
	drawer: {
		width: 500,
		maxWidth: '100%',
	},
	header: {
		overflow: 'hidden',
	},
	content: {
		overflow: 'auto',
		height: '100%',
	},
	notchedOutline: {
		borderColor: '#00B2A1 !important',
	},
	item: {
		padding: '15px 8px',
		'& div': {
			flexWrap: 'nowrap',
		},
		'& a': {
			color: 'inherit',
			'&:hover': {
				textDecoration: 'inherit',
			},
		},
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: 'rgba(38, 50, 56, 0.04)',
			cursor: 'pointer',
			color: '#0056b3',
		},
	},
};

class Search extends Component {
	state = {
		isOpen: false,
		isLoading: false,
		results: [],
		query: '',
	};

	handleSearch = () => {
		const { query } = this.state;
		this.setState({ isLoading: true });
		const { snackbarContext } = this.props;

		client.searchElements({ query }).then((res) => {
			if (res.success) {
				this.setState({ results: res.data.results, isLoading: false });
			} else {
				snackbarContext.failure('Error retrieving data.');
			}
		});
	};

	getContent = (element) => {
		// return the main content to draw from customers, project and notes.
		if (element.type === 'customers') {
			return element.fullName;
		} else if (element.type === 'projects') {
			// TODO(leandro): Render customer full name along with the project. For this we
			// will need to return such info from the backend. We are returning just the customerId
			// at this moment.
			return `${element.title}`;
		} else if (element.type === 'notes') {
			return `${element.content} - ${element.author.fullName}`;
		} else {
			return 'No data';
		}
	};

	getHrefLink = (element) => {
		// return link to element
		if (element.type === 'customers') {
			return `/customer/${element.id}/details`;
		} else if (element.type === 'projects') {
			return `/project/${element.projectId}/details`;
		} else if (element.type === 'notes') {
			return `/project/${element.project.projectId}/details`;
		} else {
			return '#';
		}
	};

	renderElement = (element) => {
		if (element.type === 'customers') {
			return <CustomerSearchItem content={this.getContent(element)} />;
		}
		if (element.type === 'projects') {
			return <ProjectItemSearch content={this.getContent(element)} />;
		}
		if (element.type === 'notes') {
			return <NoteItemSearch content={this.getContent(element)} />;
		}
	};

	renderElements = (results) => {
		const { classes, history } = this.props;

		if (results.length === 0) {
			return <Typography>No data</Typography>;
		}

		return results.map((element, i) => (
			<Box className={classes.item} key={i}>
				<Link
					component={RouterLink}
					to={this.getHrefLink(element)}
					onClick={() => {
						history.push(this.getHrefLink(element));
						this.setState({ isOpen: false });
					}}
				>
					{this.renderElement(element)}
				</Link>
			</Box>
		));
	};

	render = () => {
		const { isOpen, isLoading, results, query } = this.state;
		const { classes } = this.props;

		return (
			<>
				<IconButton color="inherit" onClick={() => this.setState({ isOpen: true })}>
					<SearchOutlined style={{ color: 'white' }} />
				</IconButton>

				<Drawer
					anchor="right"
					classes={{ paper: classes.drawer }}
					ModalProps={{ BackdropProps: { invisible: true } }}
					onClose={() => this.setState({ isOpen: false })}
					open={isOpen}
					variant="temporary"
				>
					<Box p={3} overflow="auto">
						<Box>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="h4" color="textPrimary">
									Search
								</Typography>
								<IconButton onClick={() => this.setState({ isOpen: false })}>
									<HighlightOffOutlined />
								</IconButton>
							</Box>
							<Box mt={2}>
								<TextField
									fullWidth
									autoFocus
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchOutlined />
											</InputAdornment>
										),
										classes: { notchedOutline: classes.notchedOutline },
									}}
									onChange={(event) => this.setState({ query: event.target.value })}
									onKeyPress={(ev) => {
										if (ev.key === 'Enter') {
											ev.preventDefault();
											this.handleSearch();
										}
									}}
									placeholder="Search"
									value={query}
									variant="outlined"
								/>
							</Box>
							<Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="h6" color="textPrimary">
									Results: {results.length}
								</Typography>
								<Button color="primary" variant="contained" width={90} height={36} onClick={this.handleSearch}>
									Search
								</Button>
							</Box>
						</Box>
						<Box height="80%" overflow="auto" mt={4}>
							{isLoading ? (
								<Box display="flex" justifyContent="center">
									<CircularProgress />
								</Box>
							) : (
								this.renderElements(results)
							)}
						</Box>
					</Box>
				</Drawer>
			</>
		);
	};
}

export default withStyles(styles)(withRouter(withSnackbarContext(Search)));
