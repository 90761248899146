import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

const innerStyles = {
	column: {
		padding: '0 10px',
	},
};

const Column = ({ justifyContent, alignItems, children, classes, ...props }) => (
	<Grid
		item
		container
		justify={justifyContent || 'center'}
		alignItems={alignItems || 'center'}
		className={classes.column}
		{...props}
	>
		{children}
	</Grid>
);

Column.propTypes = {
	justifyContent: PropTypes.string,
};

export default withStyles(innerStyles)(Column);
