import React, { Component } from 'react';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Paper, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as client from 'api/client';
import Header from './Header';
import moment from 'moment';
import styles from './styles';
import Toolbar from './Toolbar';
import { setCalendarView, getCalendarView } from 'utils/localStorage';
import AddCustomerAndEventForm from 'modules/Calendar/AddCustomerAndEventForm';
import EventDetailsModal from 'modules/Calendar/EventDetailsModal';

class PhasesCalendar extends Component {
	calendarRef = React.createRef();
	fetchEventsDebounced = null;

	state = {
		events: [],
		isModalOpen: false,
		selectedEventId: null,
		selectedRange: null,
		view: getCalendarView(),
		date: moment().toDate(),
	};

	//----------------------------------------------------------------------
	// Initial actions
	//----------------------------------------------------------------------

	getInitialConfiguration = () => {
		var [view, date] = [null, null];

		// Parse the current URL and try to convert it to a meaningful view/date
		// combination. The regex looks like /calendar/(d|w|m)/:year/:month/:day. The
		// resolution parameter can be `d`, `w`, or `m` for day, week, or month
		// correspondly.
		//
		// If the parameters combination is invalid, then we fallback to today date
		// along with whatever resolution is stored in local storage.
		const { resolution, year, month, day } = this.props.match.params;

		const dateInUrl =
			['d', 'w', 'm'].indexOf(resolution) >= 0 &&
			!isNaN(year) &&
			!isNaN(month) &&
			!isNaN(day) &&
			moment({ year: +year, month: +month - 1, day: +day });

		if (dateInUrl && dateInUrl.isValid()) {
			[view, date] = [this.getViewFromResolution(resolution), dateInUrl.toDate()];
		} else {
			[view, date] = [getCalendarView(), moment().toDate()];
		}

		if (this.props.mobileDevice) {
			view = 'listWeek';
		}

		return { view, date };
	};

	enforceInitialState = () => {
		const { view, date } = this.getInitialConfiguration();

		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			calendarApi.changeView(view);
			calendarApi.gotoDate(date);
			this.setState({ view, date }, () => this.fetchEventsDebounced());
		}
	};

	componentDidMount = () => {
		this.fetchEventsDebounced = _.debounce(this.fetchEvents, 300);
		this.enforceInitialState();
	};

	enforceView = () => {
		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			const { mobileDevice } = this.props;
			const view = mobileDevice ? 'listWeek' : this.state.view;
			calendarApi.changeView(view);
		}
	};

	fetchEvents = () => {
		const { view, date } = this.state;

		client
			.getPhases({
				timeUnit: CalendarViewToTimeUnit[view],
				selectedDate: moment(date).format('Y-M-D'),
			})
			.then((response) => {
				const isSameRequest = view === this.state.view && date === this.state.date;
				if (response.success && isSameRequest) {
					const { phases } = response.data;
					this.setState({ events: phases.map(phaseToEvent) });
				}
			});
	};

	componentDidUpdate = () => {
		this.enforceView();
	};

	//----------------------------------------------------------------------
	// Helpers
	//----------------------------------------------------------------------

	getResolutionFromView = (view) =>
		({
			timeGridDay: 'd',
			timeGridWeek: 'w',
			listWeek: 'w',
			dayGridMonth: 'm',
		}[view]);

	getViewFromResolution = (resolution) =>
		({
			d: 'timeGridDay',
			w: 'timeGridWeek',
			m: 'dayGridMonth',
		}[resolution]);

	getCalendarUrl = () =>
		`/calendar/${this.getResolutionFromView(this.state.view)}/${moment(this.state.date).format('YYYY/MM/DD')}`;

	getCalendarApi = () => {
		return this.calendarRef?.current ? this.calendarRef?.current.getApi() : null;
	};

	// This helper is used after an event was resized or dragged around the calendar
	// after which only the start and end time got modified.
	editStartAndEndTime = (event) => {
		client
			.editPhase({
				phaseId: +event.id,
				startTime: event.start.toISOString(),
				endTime: event.end.toISOString(),
			})
			.then((result) => {
				// TODO(leandro): Probably render an snackbar with the appropiate error
				// and revert the operation.
				if (result.success) {
					const phase = result.data.phase;
					this.setState(({ events }) => ({
						events: events.map((event) => (+event.id === phase.projectPhaseId ? phaseToEvent(phase) : event)),
					}));
				}
			});
	};

	//----------------------------------------------------------------------
	// Modal
	//----------------------------------------------------------------------

	handleAddClick = () => {
		this.setState({ isModalOpen: true });
	};

	handleModalClose = () => {
		this.setState({
			isModalOpen: false,
			selectedRange: null,
			selectedEventId: null,
		});
	};

	//----------------------------------------------------------------------
	// Handlers that modify the view or date range of the calendar.
	//----------------------------------------------------------------------

	navigate = (calendarApi) => {
		window.calendarApi = calendarApi;
		this.setState({ date: calendarApi.getDate() }, () => {
			this.props.history.push(this.getCalendarUrl());
			this.fetchEventsDebounced();
		});
	};

	handleDateToday = () => {
		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			calendarApi.today();
			this.navigate(calendarApi);
		}
	};

	handleDatePrev = () => {
		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			calendarApi.prev();
			this.navigate(calendarApi);
		}
	};

	handleDateNext = () => {
		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			calendarApi.next();
			this.navigate(calendarApi);
		}
	};

	handleViewChange = (view) => {
		const calendarApi = this.getCalendarApi();
		if (calendarApi) {
			calendarApi.changeView(view);
			setCalendarView(view);
			this.setState({ view }, () => this.navigate(calendarApi));
		}
	};

	//----------------------------------------------------------------------
	// Range selection
	//----------------------------------------------------------------------

	handleRangeSelect = (arg) => {
		const calendarApi = this.getCalendarApi();

		if (calendarApi) {
			calendarApi.unselect();
		}

		this.setState({
			isModalOpen: true,
			selectedRange: {
				start: arg.start,
				end: arg.end,
			},
		});
	};

	//----------------------------------------------------------------------
	// Event actions
	//----------------------------------------------------------------------

	handleEventSelect = (arg) => {
		this.setState({ isModalOpen: true, selectedEventId: arg.event.id });
	};

	handleEventDrop = ({ event }) => {
		this.editStartAndEndTime(event);
	};

	handleEventResize = ({ event }) => {
		this.editStartAndEndTime(event);
	};

	//----------------------------------------------------------------------
	// Event specific actions completed (e.g edit, add, delete, etc).
	//----------------------------------------------------------------------

	// This method is called after a new event/phase is added to the calendar. This
	// handler will close the modal, clear selection and add the newly created phase
	// to the list of events.
	handleAddComplete = (phase) => {
		this.setState((prevState) => ({
			isModalOpen: false,
			selectedRange: null,
			selectedEventId: null,
			events: [...prevState.events, phaseToEvent(phase)],
		}));
	};

	// If adding/editing an event gets cancelled, this handler will be called. This
	// method simply close the modal and clear any selection.
	handleCancel = () => {
		this.handleModalClose();
	};

	// Close the modal, clear selection and remove the deleted event from the list
	// of events being displayed.
	handleDeleteComplete = (deletedEventId) => {
		this.setState((prevState) => ({
			isModalOpen: false,
			selectedRange: null,
			selectedEventId: null,
			events: _.filter(prevState.events, (event) => event.id !== deletedEventId),
		}));
	};

	// Called after a phase gets edited, this method closes the modal, clear the
	// selection and replace the event that was just edited by the new one.
	handleEditComplete = (phase) => {
		this.setState((prevState) => ({
			isModalOpen: false,
			selectedRange: null,
			selectedEventId: null,
			events: _.map(prevState.events, (event) => (+event.id === phase.projectPhaseId ? phaseToEvent(phase) : event)),
		}));
	};

	render() {
		const { date, events, view, selectedRange, isModalOpen, selectedEventId } = this.state;

		let selectedEvent = null;
		if (selectedEventId) {
			selectedEvent = _.find(events, ({ id }) => id === +selectedEventId);
		}

		const { classes } = this.props;
		return (
			<>
				<Header onAddClick={this.handleAddClick} />
				<Toolbar
					date={date}
					onDateNext={this.handleDateNext}
					onDatePrev={this.handleDatePrev}
					onDateToday={this.handleDateToday}
					onViewChange={this.handleViewChange}
					view={view}
				/>
				<Paper className={classes.calendar}>
					<FullCalendar
						firstDay={1}
						allDayMaintainDuration
						defaultDate={date}
						defaultView={view}
						droppable
						editable
						eventClick={this.handleEventSelect}
						eventDrop={this.handleEventDrop}
						eventResizableFromStart
						eventResize={this.handleEventResize}
						events={events}
						header={false}
						height={800}
						ref={this.calendarRef}
						rerenderDelay={10}
						select={this.handleRangeSelect}
						selectable
						selectMirror
						// This just set a default color for selected placeholders, events will
						// have an explicit color specified.
						eventBackgroundColor={'#039be5'}
						weekends
						allDaySlot={false}
						nowIndicator={true}
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
						slotDuration={'00:15:00'}
					/>
				</Paper>
				{selectedEvent ? (
					<EventDetailsModal
						isOpen={true}
						event={selectedEvent}
						onClose={this.handleCancel}
						onUpdateComplete={this.handleEditComplete}
						onDeleteComplete={this.handleDeleteComplete}
					/>
				) : (
					<AddCustomerAndEventForm
						open={isModalOpen}
						range={selectedRange}
						onClose={this.handleCancel}
						onAddOrUpdateCompleted={this.handleAddComplete}
					/>
				)}
			</>
		);
	}
}

const CalendarViewToTimeUnit = {
	dayGridMonth: 'month',
	listWeek: 'week',
	timeGridDay: 'day',
	timeGridWeek: 'week',
};

const phaseToEvent = (phase) => {
	let eventTitle = phase.customer ? `${phase.customer.fullName}: ${phase.title}` : phase.title;
	if (phase.appointmentAcceptedTime) {
		eventTitle = '✅ ' + eventTitle;
	} else if (phase.appointmentCanceledTime) {
		eventTitle = '⚠️ ' + eventTitle;
	}

	// Use black color to render the event name if the event has
	// a background light color.
	let textColor = 'white';
	if (phase.color === '#e6e8e6') {
		textColor = 'black';
	}

	return {
		id: phase.projectPhaseId,
		title: eventTitle,
		start: phase.startTime,
		end: phase.endTime,
		backgroundColor: phase.color || '#039be5',
		extra: { phase },
		textColor,
	};
};

function withMediaQuery(...args) {
	// eslint-disable-next-line react/display-name
	return (Component) => (props) => {
		const mediaQuery = useMediaQuery(...args);
		return <Component mobileDevice={mediaQuery} {...props} />;
	};
}

const PhasesCalendarStyled = withStyles(styles)(PhasesCalendar);

export default withMediaQuery((theme) => theme.breakpoints.down('sm'))(PhasesCalendarStyled);
