import React from 'react';
import {
	Box,
	Card,
	CardContent,
	Drawer,
	FormHelperText,
	Grid,
	IconButton,
	Typography,
	TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { HighlightOffOutlined } from '@material-ui/icons';
import { Button } from 'components/common';
import { useCommonProps } from 'utils/form';
import * as client from 'api/client';

const messages = {
	failure: 'Something failed, please retry later.',
};

const CreateCustomerDrawer = ({ isCreating, onCustomerCreated, onClose, classes }) => (
	<Drawer anchor="right" classes={{ paper: classes.drawer }} onClose={onClose} open={isCreating} variant="temporary">
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				Create customer
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<CreateCustomerForm onCustomerCreated={onCustomerCreated} />
		</Box>
	</Drawer>
);

// Pass the customer as a prop and let formik handle states internally since
// <Formik /> is an uncontrolled component.
const CreateCustomerForm = ({ onCustomerCreated }) => (
	<Formik
		initialValues={{
			firstName: '',
			lastName: null,
			phone: null,
			email: null,
			organization: null,
			address: null,
			birthday: null,
			note: null,
		}}
		validationSchema={Yup.object().shape({
			firstName: Yup.string().max(128).nullable(),
			lastName: Yup.string().max(128).nullable(),
			phone: Yup.string().max(32).nullable(),
			email: Yup.string().max(254).nullable(),
			organization: Yup.string().max(128).nullable(),
			address: Yup.string().max(1024).nullable(),
			birthday: Yup.date().nullable(),
			note: Yup.string().nullable(),
		})}
		validateOnBlur={false}
		onSubmit={(values, actions) => {
			actions.setSubmitting(true);
			client
				.createCustomer({
					firstName: values.firstName,
					lastName: values.lastName,
					phones: [{ phone: values.phone, isPrimary: true }],
					email: values.email,
					organization: values.organization,
					address: values.address,
					note: values.note,
					birthday: values.birthday,
				})
				.then((response) => {
					if (response.success) {
						onCustomerCreated(response.data.customer);
					}
				})
				.catch((error) => {
					console.error(error);
					actions.setErrors({ submit: messages.failure });
				})
				.finally(() => {
					actions.setSubmitting(false);
				});
		}}
	>
		{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
			<form onSubmit={handleSubmit}>
				<Card>
					<CardContent>
						<CreateCustomerFormFields
							values={values}
							onChange={handleChange}
							onBlur={handleBlur}
							errors={errors}
							touched={touched}
							isSubmitting={isSubmitting}
							setFieldValue={setFieldValue}
						/>

						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}

						<Box mt={2}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								loading={isSubmitting}
								width="100%"
								height="50px"
							>
								Create
							</Button>
						</Box>
					</CardContent>
				</Card>
			</form>
		)}
	</Formik>
);

const CreateCustomerFormFields = ({ values, onChange, onBlur, errors, touched, isSubmitting, setFieldValue }) => {
	const propsFn = useCommonProps({
		values,
		onChange,
		onBlur,
		errors,
		touched,
		isPending: isSubmitting,
	});

	return (
		<Grid container spacing={3}>
			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('firstName')} variant="outlined" label="First name" autoFocus />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField fullWidth {...propsFn('lastName')} variant="outlined" label="Last name" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('phone')} variant="outlined" label="Phone" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('email')} variant="outlined" label="Email" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('organization')} variant="outlined" label="Organization" />
			</Grid>

			<Grid item md={12} xs={12}>
				<KeyboardDatePicker
					{...propsFn('birthday')}
					format="MM/DD/yyyy"
					fullWidth
					inputVariant="outlined"
					label="Birthday"
					placeholder="MM/DD/YYYY"
					variant="inline"
					onChange={(date) => setFieldValue('birthday', date.format('L'))}
				/>
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('address')} variant="outlined" label="Address" />
			</Grid>

			<Grid item md={12} xs={12}>
				<TextField number fullWidth {...propsFn('note')} variant="outlined" label="Note" multiline rows={4} />
			</Grid>
		</Grid>
	);
};

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

export default withStyles(styles)(CreateCustomerDrawer);
