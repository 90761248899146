import React from 'react';
import { Box, Card, CardContent, Container, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';
import { CircularProgress } from '@material-ui/core';

const VerifyAccountVerifying = () => (
	<BarnWrapper title="Verifying Account | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
						<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
						<Typography color="textPrimary" variant="h2">
							<CircularProgress size={12} style={{ marginRight: 5, marginBottom: 3 }} />
							Verifying account
						</Typography>
						<Typography variant="body1" color="textSecondary">
							Please wait until your account gets verified.
						</Typography>
					</Box>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default VerifyAccountVerifying;
