import { post, GenericSuccess, GenericError } from 'api/core';
import { PublicAppointment } from 'types/models/models';

export type AppointmentDetailsResult =
	| GenericSuccess<{
			appointment: PublicAppointment;
	  }>
	| GenericError;

//----------------------------------------------------------------------
// appointment/details/
//----------------------------------------------------------------------

export interface GetAppointmentDetailsArgs {
	appointmentId: string;
}

export const getAppointmentDetails = (args: GetAppointmentDetailsArgs): Promise<AppointmentDetailsResult> => {
	return post('appointment/details/', args);
};

//----------------------------------------------------------------------
// appointment/record-opened/
//----------------------------------------------------------------------

export interface RecordOpenedAppointment {
	appointmentId: string;
}

export const recordOpenedAppointment = (args: RecordOpenedAppointment): Promise<GenericSuccess<{}>> => {
	return post('appointment/record-opened/', args);
};

//----------------------------------------------------------------------
// appointment/accept/
//----------------------------------------------------------------------

export interface AcceptAppointmentArgs {
	appointmentId: string;
}

export const acceptAppointment = (args: AcceptAppointmentArgs): Promise<AppointmentDetailsResult> => {
	return post('appointment/accept/', args);
};

//----------------------------------------------------------------------
// appointment/cancel/
//----------------------------------------------------------------------

export interface CancelAppointmentArgs {
	appointmentId: string;
}

export const cancelAppointment = (args: CancelAppointmentArgs): Promise<AppointmentDetailsResult> => {
	return post('appointment/cancel/', args);
};
