import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, TextField, Typography, Fade, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Linkify from 'react-linkify';

import { relativeTime } from 'utils/time';
import { Row, Column, Content, Header } from 'components/table';
import Actions from 'components/common/Actions';
import { Button, Select } from 'components/common';
import { TaskStatus } from 'types/enums';
import { taskPriorityOptions, taskPriorityInfo } from 'modules/Task/Utils';
import styles from 'modules/Task/TaskList/styles/table';
import EmptyState from 'components/EmptyState';

const headerInfo = [
	{ text: 'Description', xs: 4 },
	{ text: 'Priority', xs: 3 },
	{ text: 'Created at', xs: 3 },
	{ text: '', xs: 2 },
];

const PriorityLabel = withStyles(styles)(({ priority, done, classes }) => (
	<Box
		display="flex"
		height="30px"
		width="80px"
		bgcolor={taskPriorityInfo[priority].color}
		justifyContent="center"
		alignItems="center"
		borderRadius="5px"
	>
		<Typography variant="h5" className={classNames(classes.priorityLabelText, done ? classes.doneText : null)}>
			{taskPriorityInfo[priority].label}
		</Typography>
	</Box>
));

PriorityLabel.propTypes = {
	priority: PropTypes.number,
	done: PropTypes.bool,
};

const CreateInline = withStyles(styles)(
	({ priority, onChangePriority, status, onChangeStatus, title, onChangeTitle, onCancel, onCreate, classes }) => (
		<Row>
			<Column justifyContent="flex-start" xs={1}>
				<Checkbox
					checked={status === TaskStatus.Done}
					onChange={(e) => onChangeStatus(e.target.checked ? TaskStatus.Done : TaskStatus.Undone)}
					classes={{ checked: classes.checkboxChecked }}
				/>
			</Column>
			<Column justifyContent="flex-start" xs={4}>
				<TextField
					placeholder="Add task description"
					InputProps={{ disableUnderline: true, className: classes.textField }}
					value={title}
					onChange={(e) => onChangeTitle(e.target.value)}
					fullWidth
					multiline
					autoFocus
				/>
			</Column>
			<Column xs={3}>
				<Select
					placeholder="Priority"
					items={taskPriorityOptions}
					value={priority}
					onChange={(value) => onChangePriority(value)}
				/>
			</Column>
			<Column justifyContent="flex-end" xs={5}>
				<Button
					onClick={() => onCreate({ description: title, priority, status })}
					variant="contained"
					color="primary"
					height={38}
					disabled={title === '' || priority === 'none'}
				>
					Create
				</Button>
				<Button onClick={onCancel} variant="text" color="secondary" height={38} className={classes.cancel}>
					Cancel
				</Button>
			</Column>
		</Row>
	)
);

CreateInline.propTypes = {
	priority: PropTypes.number.isRequired,
	status: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	onChangePriority: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onChangeTitle: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
};

class Table extends Component {
	renderTasksList = () => {
		const { tasks, status, isCreating, onDoneTask, onRemoveTask, onEditIntention, classes } = this.props;

		if (!tasks.length && !isCreating) {
			return (
				<Row>
					<Column justifyContent="center" xs={12}>
						<EmptyState name="es-no-tasks" title={'Start by adding a task'}>
							Create a task so you don&apos;t forget.
						</EmptyState>
					</Column>
				</Row>
			);
		}

		return tasks.map((task) => (
			<Fade
				key={task.id}
				in={status === -1 || status === (task.status ? TaskStatus.Done : TaskStatus.Undone)}
				timeout={{ exit: 500 }}
			>
				<Row>
					<Column justifyContent="flex-start" xs={1}>
						<Checkbox
							checked={task.status === TaskStatus.Done}
							onChange={(e) => onDoneTask(task, e.target.checked)}
							classes={{ checked: classes.checkboxChecked }}
						/>
					</Column>
					<Column justifyContent="flex-start" xs={4}>
						<Content
							classes={{
								content: task.status === TaskStatus.Done ? classes.doneText : null,
								ellipsis: classes.description,
							}}
						>
							<Linkify>{task.description}</Linkify>
						</Content>
					</Column>
					<Column xs={3}>
						<PriorityLabel priority={task.priority} done={task.status === TaskStatus.Done} />
					</Column>
					<Column xs={3}>
						<Content
							classes={{
								content: task.status === TaskStatus.Done ? classes.doneText : null,
							}}
						>
							{relativeTime(task.createdTime)}
						</Content>
					</Column>
					<Column justifyContent="flex-end" xs={2}>
						<Actions updateOnClick={() => onEditIntention(task)} removeOnClick={() => onRemoveTask(task)} />
					</Column>
				</Row>
			</Fade>
		));
	};

	render() {
		const { newTask, isCreating, onCreateTask, onCancelCreating, onChangeTitle, onChangePriority, onChangeStatus } =
			this.props;

		return (
			<>
				<Header headerInfo={headerInfo} addExtraColumn />
				{isCreating && (
					<CreateInline
						priority={newTask.priority}
						status={newTask.status}
						title={newTask.description}
						onChangePriority={onChangePriority}
						onChangeStatus={onChangeStatus}
						onChangeTitle={onChangeTitle}
						onCancel={onCancelCreating}
						onCreate={onCreateTask}
					/>
				)}
				{this.renderTasksList()}
			</>
		);
	}
}

Table.propTypes = {
	tasks: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			description: PropTypes.string,
			priority: PropTypes.number,
			createdTime: PropTypes.string,
		})
	).isRequired,
	newTask: PropTypes.shape({
		description: PropTypes.string,
		priority: PropTypes.number,
		createdTime: PropTypes.string,
	}),
	isCreating: PropTypes.bool,
	status: PropTypes.number.isRequired,
	onDoneTask: PropTypes.func.isRequired,
	onRemoveTask: PropTypes.func.isRequired,
	onCancelCreating: PropTypes.func.isRequired,
	onCreateTask: PropTypes.func.isRequired,
	onChangeTitle: PropTypes.func.isRequired,
	onChangePriority: PropTypes.func.isRequired,
	onChangeStatus: PropTypes.func.isRequired,
	onEditIntention: PropTypes.func.isRequired,
};

export default withStyles(styles)(Table);
