import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import { withAuthContext } from 'context/AuthContext';
import * as client from 'api/client';
import { withRouter } from 'react-router-dom';

class JWTSignIn extends Component {
	render() {
		const {
			authContext: { updateUser },
			history,
		} = this.props;

		return (
			<Formik
				initialValues={{
					email: '',
					password: '',
					submit: null,
				}}
				validateOnBlur={false}
				validationSchema={Yup.object().shape({
					email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
					password: Yup.string().max(255).required('Password is required'),
				})}
				onSubmit={async (values, { setErrors, setSubmitting }) => {
					setSubmitting(true);

					const { email, password } = values;
					client
						.login({ email, password })
						.then((response) => {
							if (response.success) {
								if (!response.data.user.isAdmin) {
									setErrors({
										submit: 'Website view is for admins only. Please download the app.',
									});
								} else if (response.data.user.isVerified) {
									updateUser(response.data.user);
									history.push('/');
								} else {
									history.push('/verify');
								}
							} else {
								setErrors({ submit: response.data.message });
							}
						})
						.finally(() => {
							setSubmitting(false);
						});
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form noValidate onSubmit={handleSubmit}>
						<TextField
							error={Boolean(touched.email && errors.email)}
							fullWidth
							autoFocus
							helperText={touched.email && errors.email}
							label="Email Address"
							margin="normal"
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							type="email"
							value={values.email}
							variant="outlined"
						/>
						<TextField
							error={Boolean(touched.password && errors.password)}
							fullWidth
							helperText={touched.password && errors.password}
							label="Password"
							margin="normal"
							name="password"
							onBlur={handleBlur}
							onChange={handleChange}
							type="password"
							value={values.password}
							variant="outlined"
						/>
						{errors.submit && (
							<Box mt={2}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}
						<Box flexGrow={1} mt={2}>
							<Button
								color="secondary"
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
							>
								Sign In
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		);
	}
}

export default withRouter(withAuthContext(JWTSignIn));
