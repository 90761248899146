import React from 'react';
import TextVerticalAlignment from 'modules/Invoice/TextVerticalAlignment';
import { displayAmount } from 'utils/format';

const InvoiceTotalsSection = ({ invoice, align }) => {
	const { subtotal, total, items, taxBreakdown } = invoice;

	const allTaxes = {};
	items.forEach((item) => {
		item.taxes.forEach((tax) => {
			allTaxes[tax.id] = tax;
		});
	});

	const taxRows = [];
	Object.keys(taxBreakdown).forEach((taxId) => {
		if (taxId in allTaxes) {
			const { name, rate } = allTaxes[taxId];
			taxRows.push({
				label: (
					<span>
						{name} <sup>{(+rate).toFixed(2)}%</sup>
					</span>
				),
				value: displayAmount(taxBreakdown[taxId] / 100),
			});
		}
	});

	return (
		<TextVerticalAlignment
			align={align}
			rows={[
				{
					label: <strong>Subtotal</strong>,
					value: displayAmount(subtotal / 100),
				},
				...taxRows,
				{ label: <strong>Total</strong>, value: displayAmount(total / 100) },
			]}
		/>
	);
};

export default InvoiceTotalsSection;
