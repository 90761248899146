import { post, GenericSuccess, GenericError } from 'api/core';
import * as models from 'types/models/models';

//----------------------------------------------------------------------
// getCustomerImports
//----------------------------------------------------------------------

export type GetCustomerImportsResult =
	| GenericSuccess<{
			imports: models.CustomerImport[];
	  }>
	| GenericError;

export const getCustomerImports = (args: {}): Promise<GetCustomerImportsResult> => {
	return post('customer/import/list/', args);
};
