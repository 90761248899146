import React, { Component } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { MoreVert } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { Menu } from 'components/common';
import { Row, Column, Header } from 'components/table';
import styles from 'modules/Customer/List/styles/table';
import CustomerCompactedInfo from 'components/customer/CustomerCompactedInfo';
import NoCustomersEmptyState from 'modules/Customer/List/NoCustomersEmptyState';

const headerInfo = [
	{ text: 'Customer', xs: 9 },
	{ text: 'Action', xs: 3 },
];

class Table extends Component {
	getMenuItemsForCustomer = (customer) => {
		const { onRemoveCustomer, onCustomerUpdate } = this.props;
		return [
			{
				value: 'edit',
				label: 'Edit',
				onClick: () => onCustomerUpdate(customer),
			},
			{
				value: 'delete',
				label: 'Delete',
				onClick: () => onRemoveCustomer(customer),
			},
		];
	};

	renderCustomersList = () => {
		const { customers, isSearch } = this.props;

		if (customers.length === 0) {
			return (
				<Row>
					<Column justifyContent="center" xs={12}>
						<NoCustomersEmptyState isSearch={isSearch} />
					</Column>
				</Row>
			);
		}

		return customers.map((customer) => (
			<Row key={customer.id}>
				<Column justifyContent="flex-start" xs={9}>
					<CustomerCompactedInfo customer={customer} includeName={true} />
				</Column>

				<Column alignItems="flex-start" justifyContent="flex-end" xs={3}>
					<Menu
						items={this.getMenuItemsForCustomer(customer)}
						button={
							<IconButton>
								<MoreVert />
							</IconButton>
						}
					/>
				</Column>
			</Row>
		));
	};

	render() {
		return (
			<>
				<Header headerInfo={headerInfo} />
				{this.renderCustomersList()}
			</>
		);
	}
}

Table.propTypes = {
	customers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			fullName: PropTypes.string.isRequired,
			email: PropTypes.string,
			phone: PropTypes.string,
			address: PropTypes.string,
		})
	).isRequired,
	onRemoveCustomer: PropTypes.func.isRequired,
	onCustomerUpdate: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(Table));
