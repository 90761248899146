import { getImageUrl } from 'utils/image';
import { PreviewProcessingStatus } from 'types/enums';

export function getPreviewUrl({ attachmentUrl, previewUrl, previewProcessingStatus }) {
	if (previewUrl) {
		return getImageUrl(previewUrl);
	}

	// HACK: If there is not a preview available then try to guess if the attachment
	// is an image given its extension. This should improve the user experience reducing
	// the waiting time for users. However, there are at least two disadvantages to
	// this approach: (i) loading will become slower for a large list of images without
	// previews, and (ii) getting the file type wrong might crash the gallery.
	const extension = attachmentUrl.split('.').pop().toLowerCase();
	if (['png', 'jpg', 'jpeg'].indexOf(extension) >= 0) {
		return getImageUrl(attachmentUrl);
	}

	if ([PreviewProcessingStatus.Pending, PreviewProcessingStatus.Processing].indexOf(previewProcessingStatus) >= 0) {
		return require('statics/images/preview/generating-preview.jpg');
	}

	return require('statics/images/preview/unavailable-preview.jpg');
}
