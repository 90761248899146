import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Button } from 'components/common';
import styles from 'components/confirmationAlert/styles';

const ConfirmationAlert = ({ classes, openAlert, onConfirmation, onCancelation, message }) => (
	<Dialog
		open={openAlert}
		onClose={onCancelation}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogContent>
			<DialogContentText id="alert-dialog-description">
				<span className={classes.text}> {message}</span>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onCancelation} variant="text" height={36} autoFocus className={classes.noButton}>
				No
			</Button>
			<Button onClick={onConfirmation} variant="contained" color="primary" height={36}>
				Yes
			</Button>
		</DialogActions>
	</Dialog>
);

ConfirmationAlert.propTypes = {
	message: PropTypes.string,
	onConfirmation: PropTypes.func,
	onCancelation: PropTypes.func,
	openAlert: PropTypes.bool,
};

export default withStyles(styles)(ConfirmationAlert);
