import React, { Component } from 'react';
import { Grid, Box, Link, Typography, Hidden, withStyles } from '@material-ui/core';
import styles from 'modules/Home/styles/buyAppSection';

const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.werkgo.app';
const appStoreLink = 'https://apps.apple.com/us/app/werkgo/id1578570556';

class BuyAppSection extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Box
				width="100%"
				p="100px"
				borderRadius="30px"
				boxShadow="20px 20px 10px #7B717420"
				className={classes.container}
			>
				<Grid container wrap="nowrap">
					<Hidden mdDown>
						<Grid item lg={3} className={classes.imageContainer}>
							<img alt="Block" src={require('statics/images/home/step-block.svg')} />
						</Grid>
					</Hidden>
					<Grid container item direction="column" md={12} lg={6}>
						<Typography variant="h1" className={classes.title}>
							Install the <span className={classes.werkgoWord}>Werkgo</span> app
						</Typography>
						<Typography variant="h5" className={classes.subtitle}>
							Don’t miss any updates of our new features and offers
						</Typography>
						<Grid container alignItems="center" justify="center">
							<Link href={googlePlayLink} target="blank">
								<img
									className={classes.playStoreAppBadge}
									alt="Get it on Google Play"
									src={require('statics/images/google-play-badge.png')}
								/>
							</Link>
							<Link href={appStoreLink} target="blank">
								<img
									className={classes.appStoreAppBadge}
									alt="Get it on App Store"
									src={require('statics/images/app-store-badge.svg')}
								/>
							</Link>
						</Grid>
					</Grid>
					<Hidden mdDown>
						<Grid item lg={3} className={classes.imageContainer}>
							<img alt="Block" src={require('statics/images/home/step-block.svg')} />
						</Grid>
					</Hidden>
				</Grid>
			</Box>
		);
	}
}

export default withStyles(styles)(BuyAppSection);
