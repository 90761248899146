import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Card, CardContent, CardHeader, Divider, FormHelperText, Grid, TextField } from '@material-ui/core';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import { Button } from 'components/common';

class Security extends Component {
	render() {
		const { className, snackbarContext, ...rest } = this.props;

		return (
			<Formik
				validateOnBlur={false}
				initialValues={{
					currentPassword: '',
					newPassword: '',
					confirmNewPassword: '',
				}}
				validationSchema={Yup.object().shape({
					currentPassword: Yup.string().required('Please, enter your current password.'),
					newPassword: Yup.string()
						.min(5, 'Must be at least 5 characters')
						.max(255)
						.required('Please, enter a new password.'),
					confirmNewPassword: Yup.string()
						.oneOf([Yup.ref('newPassword')], 'Passwords must match')
						.required('Passwords must match'),
				})}
				onSubmit={async (values, { resetForm }) => {
					client
						.changePassword({
							currentPassword: values.currentPassword,
							newPassword: values.newPassword,
						})
						.then((response) => {
							if (response.success) {
								snackbarContext.success('Password updated');
								resetForm();
							} else {
								snackbarContext.failure(response.data.message || 'Something went wrong');
							}
						})
						.catch((error) => {
							console.error(error);
							snackbarContext.failure('Something went wrong, try again later.');
						});
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Card className={className} {...rest}>
							<CardHeader title="Change Password" />
							<Divider />
							<CardContent>
								<Grid container spacing={4} md={6}>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											error={Boolean(touched.currentPassword && errors.currentPassword)}
											fullWidth
											helperText={touched.currentPassword && errors.currentPassword}
											label="Current password"
											name="currentPassword"
											onBlur={handleBlur}
											onChange={handleChange}
											type="password"
											value={values.currentPassword}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											error={Boolean(touched.newPassword && errors.newPassword)}
											fullWidth
											helperText={touched.newPassword && errors.newPassword}
											label="New password"
											name="newPassword"
											onBlur={handleBlur}
											onChange={handleChange}
											type="password"
											value={values.newPassword}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={12} sm={12} xs={12}>
										<TextField
											error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
											fullWidth
											helperText={touched.confirmNewPassword && errors.confirmNewPassword}
											label="Confirm new password"
											name="confirmNewPassword"
											onBlur={handleBlur}
											onChange={handleChange}
											type="password"
											value={values.confirmNewPassword}
											variant="outlined"
										/>
									</Grid>
								</Grid>
								{errors.submit && (
									<Box mt={3}>
										<FormHelperText error>{errors.submit}</FormHelperText>
									</Box>
								)}
							</CardContent>
							<Divider />
							<Box p={2} display="flex" justifyContent="flex-end">
								<Button color="primary" disabled={isSubmitting} type="submit" variant="contained" height="50px">
									Change Password
								</Button>
							</Box>
						</Card>
					</form>
				)}
			</Formik>
		);
	}
}

Security.propTypes = {
	className: PropTypes.string,
};

export default withSnackbarContext(Security);
