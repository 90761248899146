import React from 'react';
import Page from 'components/Page';
import { withStyles } from '@material-ui/core';

const BarnWrapper = ({ title, classes, children }) => (
	<Page className={classes.root} title={title}>
		{children}
		<small className={classes.attribution}>
			Photo by{' '}
			<a href="https://unsplash.com/@barnimages?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
				Barn Images
			</a>{' '}
			on{' '}
			<a href="https://unsplash.com/s/photos/tools?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
				Unsplash
			</a>
		</small>
	</Page>
);

const styles = () => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		// https://css-tricks.com/perfect-full-page-background-image/
		background: `no-repeat center center fixed url("${require('statics/images/barn.jpg')}")`,
		'-moz-background-size': 'cover',
		'-o-background-size': 'cover',
		'-webkit-background-size': 'cover',
		'background-size': 'cover',
	},
	attribution: {
		position: 'absolute',
		bottom: 5,
		right: 5,
	},
});

export default withStyles(styles)(BarnWrapper);
