import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Search } from 'components/filters';
import { Button, Select } from 'components/common';
import { taskPriorityFilters, taskStatusFilters } from 'modules/Task/Utils';

const messages = {
	search: 'Search tasks',
	status: 'Status',
	priority: 'Priority',
};

const FiltersSection = ({ isMobile, ...props }) =>
	isMobile ? <FiltersMobile {...props} /> : <FiltersWeb {...props} />;

const FiltersWeb = ({ filters, applyFilter, onCreate, disable }) => (
	<Grid container wrap="nowrap">
		<Search
			placeholder={messages.search}
			onQueryChange={(value) => applyFilter({ searchQuery: value })}
			disabled={disable}
		/>
		<Box ml="40px">
			<Select
				value={filters.status}
				placeholder={messages.status}
				items={taskStatusFilters}
				onChange={(value) => applyFilter({ status: value })}
				disabled={disable}
			/>
		</Box>
		<Box ml="20px">
			<Select
				value={filters.priority}
				placeholder={messages.priority}
				items={taskPriorityFilters}
				onChange={(value) => applyFilter({ priority: value })}
				disabled={disable}
			/>
		</Box>
		<Button
			onClick={onCreate}
			variant="contained"
			color="primary"
			startIcon={<Add />}
			disabled={disable}
			height={38}
			style={{ marginLeft: 'auto' }}
		>
			Add task
		</Button>
	</Grid>
);

const FiltersMobile = ({ filters, applyFilter, isMobile, disable }) => (
	<Grid container>
		<Grid item xs={12}>
			<Search
				placeholder={messages.search}
				onQueryChange={(value) => applyFilter({ searchQuery: value })}
				disabled={disable}
				fullWidth={true}
			/>
		</Grid>

		<Grid item xs={6} style={{ marginTop: '10px', textAlign: 'center' }}>
			<Select
				value={filters.status}
				placeholder={messages.status}
				items={taskStatusFilters}
				onChange={(value) => applyFilter({ status: value })}
				disabled={disable}
				fullWidth={isMobile}
			/>
		</Grid>

		<Grid item xs={6} style={{ marginTop: '10px', textAlign: 'center' }}>
			<Select
				value={filters.priority}
				placeholder={messages.priority}
				items={taskPriorityFilters}
				onChange={(value) => applyFilter({ priority: value })}
				disabled={disable}
				fullWidth={isMobile}
			/>
		</Grid>
	</Grid>
);

FiltersSection.propTypes = {
	filters: PropTypes.shape({
		status: PropTypes.number,
		priority: PropTypes.number,
	}).isRequired,
	applyFilter: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
	disable: PropTypes.bool,
};

export default FiltersSection;
