/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, GenericSuccess, GenericError } from 'api/core';

//***************
// TYPES
//***************

//
// projectAutocomplete
//

export interface ProjectAutocompleteArgs {
	input: string;
	customerId: number;
}

export type ProjectAutocompleteResult =
	| GenericSuccess<{
			projects: models.Project[];
	  }>
	| GenericError;

//
// addressAutocomplete
//

export interface AddressAutocompleteArgs {
	input: string;
}

export type AddressAutocompleteResult =
	| GenericSuccess<{
			predictions: string[];
	  }>
	| GenericError;

//
// searchElements
//

export interface SearchArgs {
	query: string;
}

export type SearchResults =
	| GenericSuccess<{
			results: (models.ProjectNote | models.Project | models.Customer)[];
	  }>
	| GenericError;

//
// sendInviteEmail
//

export interface SendInviteEmailArgs {
	email: string;
	isAdmin: boolean;
}

export type SendInviteEmailResult = GenericSuccess<{}> | GenericError;

//***************
// REQUESTS
//***************

export const projectAutocomplete = (args: ProjectAutocompleteArgs): Promise<ProjectAutocompleteResult> => {
	return post('project/autocomplete/', args);
};

export const addressAutocomplete = (args: AddressAutocompleteArgs): Promise<AddressAutocompleteResult> => {
	return post('google/address-autocomplete/', args);
};

export const searchElements = (args: SearchArgs): Promise<SearchResults> => {
	return post('search/', args);
};

export const sendInviteEmail = (args: SendInviteEmailArgs): Promise<SendInviteEmailResult> => {
	return post('invite-email/', args);
};
