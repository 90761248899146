import React, { Component } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Divider, withStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { Button } from 'components/common';
import styles from 'modules/Project/Details/styles/eventsSection';
import UpdateEventDrawer from 'modules/Event/UpdateEventDrawer';
import CreateEventDrawer from 'modules/Event/CreateEventDrawer';
import ConfirmationAlert from 'components/confirmationAlert';
import * as client from 'api/client';
import { getCompactedTimeRange } from 'utils/time';
import EmptyState from 'components/EmptyState';
import * as ls from 'utils/localStorage';
import Actions from 'components/common/Actions';

const Event = withStyles(styles)(({ phaseId, title, startTime, endTime, onUpdate, onDelete, classes }) => (
	<Grid container justify="space-between" style={{ marginTop: 10 }}>
		<Grid item direction="column">
			<Typography variant="h5">{title}</Typography>
			<Typography variant="caption" className={classes.phaseDate}>
				{getCompactedTimeRange(startTime, endTime)}
			</Typography>
		</Grid>

		<Grid item>
			<Actions updateOnClick={() => onUpdate(phaseId)} removeOnClick={() => onDelete(phaseId)} />
		</Grid>
	</Grid>
));

Event.propTypes = {
	title: PropTypes.string,
	startTime: PropTypes.string,
	endTime: PropTypes.string,
};

class EventsSection extends Component {
	state = {
		// If this is true, then we render the drawer coming out from the
		// rigth for the user to create a new event. This can change back
		// to `false` in two ways: (i) the event get created succesfully
		// (view handleEventCreated), or (ii) if the user closes the drawer.
		isCreating: false,

		phaseIdToDelete: null,

		phaseIdToUpdate: null,

		companyAllowSmsReminders: false,
	};

	componentDidMount = () => {
		ls.getCompanyDetailsFromLocalStorage().then((company) => {
			this.setState({ companyAllowSmsReminders: company.allowSmsReminders });
		});
	};

	//----------------------------------------------------------------------
	// Create event
	//----------------------------------------------------------------------

	handleEventCreated = (phase) => {
		this.setState({ isCreating: false }, () => this.props.onEventCreated(phase));
	};

	handleCloseCreateDrawer = () => {
		this.setState({ isCreating: false });
	};

	//----------------------------------------------------------------------
	// Update event
	//----------------------------------------------------------------------

	getCurrentUpdatableEventFields = () => {
		const phase = _.find(this.props.phases, {
			projectPhaseId: this.state.phaseIdToUpdate,
		});
		if (phase) {
			return _.pick(phase, ['title', 'startTime', 'endTime', 'allowSmsReminder', 'color']);
		}
		// TODO(leandro): Removes the following non-sense, we should be doing something different.
		return { title: '', startTime: null, endTime: null, allowSmsReminder: null, color: null };
	};

	handleCloseUpdateDrawer = () => {
		this.setState({ phaseIdToUpdate: null });
	};

	handleUpdateIntention = (phaseId) => {
		this.setState({ phaseIdToUpdate: phaseId });
	};

	handleEventUpdated = (event) => {
		this.setState({ phaseIdToUpdate: null }, () => this.props.onEventUpdated(event));
	};

	//----------------------------------------------------------------------
	// Delete event
	//----------------------------------------------------------------------

	handleCloseConfirmationAlert = () => {
		this.setState({ phaseIdToDelete: false });
	};

	handleDeleteIntention = (phaseId) => {
		this.setState({ phaseIdToDelete: phaseId });
	};

	handleDeleteEvent = () => {
		const { phaseIdToDelete } = this.state;
		client.deletePhase({ phaseId: phaseIdToDelete }).then((response) => {
			if (response.success) {
				this.setState({ phaseIdToDelete: null }, () => this.props.onEventDeleted(phaseIdToDelete));
			}
		});
	};

	renderEventsList = () => {
		const { phases, classes } = this.props;

		if (!phases.length) {
			return (
				<Box height="100%" display="flex" justifyContent="center">
					<EmptyState name="es-no-events" title="No events yet">
						Start creating events and populate your calendar
					</EmptyState>
				</Box>
			);
		}

		return (
			<Box height="100%" pr="5px" className={classes.phasesContainer}>
				{phases.map((phase) => (
					<Event
						key={phase.projectPhaseId}
						phaseId={phase.projectPhaseId}
						title={phase.title}
						startTime={phase.startTime}
						endTime={phase.endTime}
						onUpdate={this.handleUpdateIntention}
						onDelete={this.handleDeleteIntention}
					/>
				))}
			</Box>
		);
	};

	render() {
		const { projectId, classes } = this.props;

		return (
			<>
				<Box
					display="flex"
					flexDirection="column"
					bgcolor="#fff"
					borderRadius="10px"
					p="20px 40px"
					height="400px"
					className={classes.container}
				>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Typography variant="h4">Events</Typography>
						<Button
							variant="text"
							startIcon={<Add />}
							height="30px"
							onClick={() => this.setState({ isCreating: true })}
						>
							Create event
						</Button>
					</Grid>
					<Divider />
					{this.renderEventsList()}
				</Box>

				<UpdateEventDrawer
					phaseId={this.state.phaseIdToUpdate}
					{...this.getCurrentUpdatableEventFields()}
					onClose={this.handleCloseUpdateDrawer}
					onEventUpdated={this.handleEventUpdated}
					companyAllowSmsReminders={this.state.companyAllowSmsReminders}
				/>

				<CreateEventDrawer
					isCreating={this.state.isCreating}
					onClose={this.handleCloseCreateDrawer}
					onEventCreated={this.handleEventCreated}
					projectId={projectId}
					companyAllowSmsReminders={this.state.companyAllowSmsReminders}
				/>

				<ConfirmationAlert
					openAlert={!!this.state.phaseIdToDelete}
					onCancelation={this.handleCloseConfirmationAlert}
					onConfirmation={this.handleDeleteEvent}
					message="Are you sure you want to delete this event?"
				/>
			</>
		);
	}
}

EventsSection.propTypes = {
	phases: PropTypes.arrayOf(
		PropTypes.shape({
			projectPhaseId: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			startTime: PropTypes.string.isRequired,
			endTime: PropTypes.string.isRequired,
		})
	),
	onEventCreated: PropTypes.func.isRequired,
	onEventUpdated: PropTypes.func.isRequired,
	projectId: PropTypes.number.isRequired,
};

export default withStyles(styles)(EventsSection);
