import React, { Component } from 'react';
import { Divider, Grid, Typography, Card, CardContent, withStyles, CardActions, CardHeader } from '@material-ui/core';
import { withRouter } from 'react-router';
import Linkify from 'react-linkify';
import { Button } from 'components/common';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import TitleSection from 'components/TitleSection';
import { getImageUrl } from 'utils/image';
import * as enums from 'types/enums';

class Integrations extends Component {
	state = {
		integrations: [],
		connecting: false,
	};

	componentDidMount = () => {
		client.getEnabledIntegrations().then((response) => {
			this.setState({ integrations: response.data.integrations });
		});
	};

	handleClickConnect = (name) => {
		this.setState({ connecting: true }, () => {
			client.getAuthorizationUrlForIntegration({ name }).then((response) => {
				this.setState({ connecting: false });
				window.open(response.data.url);
			});
		});
	};

	renderInstances = (instances) => {
		if (!instances || !instances.length) {
			return null;
		}

		const { classes } = this.props;

		return (
			<CardContent>
				<Typography variant="h5" component="h5">
					Connections
				</Typography>
				<Divider />
				<div className={classes.p10}>
					{instances.map(({ id, name, status }) => (
						<div key={id} className={classes.instance}>
							<div>{name}</div>
							{status === enums.IntegrationInstanceStatus.Enabled && <div>✅ </div>}
							{status === enums.IntegrationInstanceStatus.InProgress && <div>🔄 </div>}
						</div>
					))}
				</div>
			</CardContent>
		);
	};

	render = () => {
		const classes = this.props.classes;

		const { integrations, connecting } = this.state;

		return (
			<>
				<TitleSection title="Integrations" />

				<Grid container>
					{integrations.map(({ name, description, icon, status, instances }) => (
						<Grid key={name} item xs={12} md={3} className={classes.m10}>
							<Card className={classes.card}>
								<CardContent>
									<CardHeader
										avatar={icon && <img src={getImageUrl(icon)} height="32px" />}
										title={
											<Typography variant="h5" component="h2">
												{name}
											</Typography>
										}
									></CardHeader>

									<Typography variant="body2" component="p">
										<Linkify>{description}</Linkify>
									</Typography>
								</CardContent>

								{this.renderInstances(instances)}

								<CardActions className={classes.cardActions}>
									<Button
										variant="contained"
										color="primary"
										height={38}
										style={{ marginLeft: '10px' }}
										disabled={status === enums.IntegrationStatus.ComingSoon || connecting}
										onClick={() => {
											this.handleClickConnect(name);
										}}
									>
										{status === enums.IntegrationStatus.ComingSoon ? 'Coming Soon' : 'Connect'}
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</>
		);
	};
}

const styles = () => ({
	m10: {
		margin: 10,
	},
	p10: {
		padding: 10,
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	cardActions: {
		justifyContent: 'right',
	},
	instance: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});

export default withStyles(styles)(withRouter(withSnackbarContext(Integrations)));
