import React, { Component } from 'react';
import * as Yup from 'yup';
import * as client from 'api/client';
import { Formik } from 'formik';
import {
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Button,
	FormHelperText,
	Checkbox,
	Box,
	Typography,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { withSnackbarContext } from 'context/SnackbarsContext';

class FormDialog extends Component {
	render() {
		const { open, handleClose } = this.props;

		return (
			<div>
				<Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">
						<Typography color="textPrimary" variant="h3">
							Invite
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Send an invite email to your new worker. Their will create a new account link to your company.
						</DialogContentText>
						<Formik
							initialValues={{
								email: '',
								isAdmin: true,
							}}
							validateOnBlur={false}
							validationSchema={Yup.object().shape({
								email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
								isAdmin: Yup.bool(),
							})}
							onSubmit={async (values, { setErrors, setSubmitting }) => {
								setSubmitting(true);
								const { email, isAdmin } = values;
								const { snackbarContext } = this.props;

								client
									.sendInviteEmail({ email, isAdmin })
									.then((response) => {
										if (response.success) {
											if (!response.data.worker) {
												snackbarContext.info('Worker already invited');
											} else {
												snackbarContext.success('Email sent');
												handleClose(response.data.worker);
											}
										} else {
											setErrors({ submit: response.data.message });
										}
									})
									.finally(() => {
										setSubmitting(false);
									});
							}}
						>
							{({ errors, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
								<form noValidate onSubmit={handleSubmit}>
									<TextField
										error={Boolean(touched.email && errors.email)}
										helperText={touched.email && errors.email}
										autoFocus
										margin="normal"
										label="Email Address"
										name="email"
										type="email"
										onBlur={handleBlur}
										onChange={handleChange}
										variant="outlined"
										value={values.email}
										fullWidth
									/>
									{errors.submit && (
										<Box mt={3}>
											<FormHelperText error>{errors.submit}</FormHelperText>
										</Box>
									)}
									<FormControlLabel
										aria-label="Admin"
										onClick={(event) => event.stopPropagation()}
										onFocus={(event) => event.stopPropagation()}
										control={<Checkbox checked={values.isAdmin} name="isAdmin" onChange={handleChange} />}
										label={<span style={{ color: '#546e7a' }}>Admin: allow this worker full access.</span>}
									/>
									<DialogActions>
										<Button onClick={() => handleClose()} color="primary">
											Cancel
										</Button>
										<Button disabled={isSubmitting} color="primary" type="submit" variant="contained">
											Send
										</Button>
									</DialogActions>
								</form>
							)}
						</Formik>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}

export default withSnackbarContext(FormDialog);
