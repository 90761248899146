import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Grid, Box, Button, Checkbox, FormHelperText, TextField, Typography, Link } from '@material-ui/core';
import * as client from 'api/client';
import { withAuthContext } from 'context/AuthContext';
import { withRouter } from 'react-router-dom';

class JWTSignUp extends Component {
	render() {
		const {
			history,
			isInvite,
			authContext: { updateUser },
		} = this.props;

		let validation = {
			firstName: Yup.string().max(255).required('First name is required'),
			lastName: Yup.string().max(255).required('Last name is required'),
			password: Yup.string().min(7).max(255).required('Password is required'),
			policy: Yup.boolean().oneOf(
				[true],
				'You must agree with our "Terms of service" in order to create an account in Werkgo.'
			),
		};

		if (!isInvite) {
			validation = {
				...validation,
				phone: Yup.string()
					.required()
					.matches(/^[0-9]+$/, 'Invalid phone number')
					.min(10)
					.max(32)
					.required('Phone is required'),
				email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
				companyName: Yup.string().max(255).required('Company name is required'),
			};
		}

		return (
			<Formik
				initialValues={{
					firstName: '',
					lastName: '',
					password: '',
					companyName: '',
					phone: '',
					email: '',
					policy: false,
					submit: null,
				}}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={Yup.object().shape(validation)}
				onSubmit={async (values, { setErrors, setSubmitting }) => {
					const { invitationToken } = this.props.match.params;
					setSubmitting(true);

					if (invitationToken) {
						client
							.signupInvite({
								firstName: values.firstName,
								lastName: values.lastName,
								password: values.password,
								invitationToken,
							})
							.then((response) => {
								if (response.success && isInvite && !response.data.user.isAdmin) {
									history.push('/install');
								} else if (response.success) {
									updateUser(response.data.user);
									history.push('/');
								} else if (response?.data?.message) {
									setErrors({ submit: response.data.message });
								}
							})
							.finally(() => {
								setSubmitting(false);
							});
					} else {
						client
							.signup({
								firstName: values.firstName,
								lastName: values.lastName,
								password: values.password,
								companyName: values.companyName,
								phone: `${values.phone}`,
								email: values.email,
							})
							.then((response) => {
								if (response.success) {
									if (response.data.user.isVerified) {
										updateUser(response.data.user);
										history.push('/');
									} else {
										history.push('/verify');
									}
								} else if (response?.data?.message) {
									setErrors({ submit: response.data.message });
								}
							})
							.finally(() => {
								setSubmitting(false);
							});
					}
				}}
			>
				{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={1}>
							<Grid item md={6} xs={12}>
								<TextField
									error={Boolean(touched.firstName && errors.firstName)}
									fullWidth
									helperText={touched.firstName && errors.firstName}
									label="First name"
									margin="normal"
									name="firstName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.firstName}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									error={Boolean(touched.lastName && errors.lastName)}
									fullWidth
									helperText={touched.lastName && errors.lastName}
									label="Last name"
									margin="normal"
									name="lastName"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.lastName}
									variant="outlined"
								/>
							</Grid>
							{!isInvite && (
								<>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.companyName && errors.companyName)}
											fullWidth
											helperText={touched.companyName && errors.companyName}
											label="Company"
											margin="normal"
											name="companyName"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.companyName}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.phone && errors.phone)}
											fullWidth
											helperText={touched.phone && errors.phone}
											label="Phone"
											margin="normal"
											name="phone"
											onBlur={handleBlur}
											onChange={handleChange}
											type="number"
											value={values.phone}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<TextField
											error={Boolean(touched.email && errors.email)}
											fullWidth
											helperText={touched.email && errors.email}
											label="Email address"
											margin="normal"
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											type="email"
											value={values.email}
											variant="outlined"
										/>
									</Grid>
								</>
							)}
							<Grid item md={12} xs={12}>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="Password"
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<Box alignItems="center" display="flex" mt={2} ml={-1}>
							<Checkbox checked={values.policy} name="policy" onChange={handleChange} />
							<Typography variant="body2" color="textSecondary">
								I have read the{' '}
								<Link component="a" href="/tos" color="secondary">
									Terms of service
								</Link>
							</Typography>
						</Box>
						{Boolean(touched.policy && errors.policy) && <FormHelperText error>{errors.policy}</FormHelperText>}
						{errors.submit && (
							<Box mt={3}>
								<FormHelperText error>{errors.submit}</FormHelperText>
							</Box>
						)}
						<Box flexGrow={1} mt={2}>
							<Button
								color="secondary"
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
							>
								Sign Up
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		);
	}
}

export default withRouter(withAuthContext(JWTSignUp));
