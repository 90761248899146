import React, { Component } from 'react';
import moment from 'moment';
import { Row, Column, Header } from 'components/table';
import { withAuthContext } from 'context/AuthContext';
import * as client from 'api/client';
import { displayAmount } from 'utils/format';

const headerInfo = [
	{ text: 'Current period start', xs: 3 },
	{ text: 'Current period end', xs: 3 },
	{ text: 'Amount', xs: 3 },
	{ text: 'Status', xs: 3 },
];

class Subscriptions extends Component {
	state = {
		subscriptions: null,
		email: null,
	};

	componentDidMount = () => {
		client.getSubscriptions().then((response) => {
			if (response.success) {
				this.setState({ subscriptions: response.data.subscriptions });
			}
		});
		client.getCurrentUserInfo().then((response) => {
			if (response.success) {
				this.setState({ email: response.data.user.email });
			}
		});
	};

	renderSubscriptions() {
		const { subscriptions } = this.state;
		if (subscriptions === null) {
			return (
				<Row>
					<Column xs={12}>Loading ...</Column>
				</Row>
			);
		}

		if (subscriptions.length === 0) {
			return (
				<Row>
					<Column xs={12}>No subscriptions</Column>
				</Row>
			);
		}

		return subscriptions.map((subscription) => (
			<Row key={subscription.id}>
				<Column xs={3} justifyContent="flex-start">
					{moment(subscription.currentPeriodStart * 1000).format('D MMMM YYYY')}
				</Column>
				<Column xs={3}>{moment(subscription.currentPeriodEnd * 1000).format('D MMMM YYYY')}</Column>
				<Column xs={3}>{displayAmount(subscription.amount / 100)}</Column>
				<Column xs={3} justifyContent="flex-end">
					{subscription.status.replace('_', ' ')}
				</Column>
			</Row>
		));
	}

	render() {
		return (
			<>
				<Header headerInfo={headerInfo} />
				{this.renderSubscriptions()}
			</>
		);
	}
}

export default withAuthContext(Subscriptions);
