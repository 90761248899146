/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, GenericSuccess, GenericResult, GenericError } from 'api/core';

//***************
// TYPES
//***************

//
// listTasks
//

export interface ListTasksArgs {
	pagination: {
		start: number;
		limit: number;
	};
	filters?: {
		status: number[];
		priorities: number[];
		query: string;
	};
}

export type ListTasksResult = GenericSuccess<{ tasks: models.Task[] }> | GenericError;

//
// detailTask
//

export interface DetailTaskArgs {
	id: number;
}

//
// createTask
//

export interface CreateTaskArgs {
	description: string;
	status?: number;
	priority?: number;
}

export type CreateTaskResult = GenericSuccess<{ task: models.Task }> | GenericError;

//
// updateTask
//

export interface UpdateTaskArgs {
	id: number;
	description?: string;
	status?: number;
	priority?: number;
}

export type UpdateTaskResult = GenericSuccess<{ task: models.Task }> | GenericError;

//
// deleteTask
//

export interface DeleteTaskArgs {
	id: number;
}

//***************
// REQUESTS
//***************

export const listTasks = (args: ListTasksArgs): Promise<ListTasksResult> => {
	return post('task/list/', args);
};

export const detailTask = (args: DetailTaskArgs): Promise<UpdateTaskResult> => {
	return post('task/detail/', args);
};

export const createTask = (args: CreateTaskArgs): Promise<CreateTaskResult> => {
	return post('task/create/', args);
};

export const updateTask = (args: UpdateTaskArgs): Promise<UpdateTaskResult> => {
	return post('task/update/', args);
};

export const deleteTask = (args: UpdateTaskArgs): Promise<GenericResult> => {
	return post('task/remove/', args);
};
