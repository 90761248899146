export const DefaultPageSize = 10;

export const filter = (values, search) => {
	const res = [];
	values.forEach((elem) => {
		if (checkInList(elem, search)) {
			res.push(elem);
		}
	});

	return res;
};

const checkInList = (value, search) => {
	const search_terms = search
		.toLowerCase()
		.trim()
		.split(' ')
		.filter((x) => x.trim().length > 0);

	const items = Object.keys(value)
		.map((key) => {
			let item = value[key];
			if (typeof item === 'object' && item !== null && item.props && item.props.children) {
				return item.props.children;
			}
			return item;
		})
		.filter((item) => item && typeof item === 'string' && item.length > 0)
		.map((item) => item.toLowerCase().trim());

	let exist = true;
	search_terms.forEach((term) => {
		exist &= items.filter((item) => item.includes(term)).length > 0;
	});

	return exist;
};
