import { Component } from 'react';
import { getMobileOperatingSystem } from 'utils/mobile';
import { AndroidStoreLink, iOSStoreLink } from 'constant';

export default class QRCode extends Component {
	componentDidMount() {
		const os = getMobileOperatingSystem();
		if (os === 'Android' || os === 'iOS') {
			const link = os === 'Android' ? AndroidStoreLink : iOSStoreLink;
			window.location.assign(link);
		} else {
			window.location.assign('https://apps.apple.com/gw/app/werkgo/id1578570556');
		}
	}
	render() {
		return 'Loading App';
	}
}
