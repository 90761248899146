import React, { Component } from 'react';
import { Grid, Typography, Hidden, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Button } from 'components/common';
import styles from 'modules/Home/styles/headerSection';

class HeaderSection extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Grid
				container
				direction="row"
				wrap="nowrap"
				alignItems="center"
				justify="space-around"
				className={classes.container}
			>
				<Grid container direction="column" className={classes.textContainer}>
					<Typography className={classes.title}>
						Best Platform <br /> for Your Business
					</Typography>
					<Typography variant="h4" className={classes.subtitle}>
						Effectively manage your customers, projects and tasks with Werkgo CRM.
					</Typography>
					<Button component={Link} to="/signup" color="primary" width={240}>
						Get Started
					</Button>
				</Grid>
				<Hidden smDown>
					<img alt="A cartoon of a group of people at work" src={require('statics/images/home/group.png')} />
				</Hidden>
			</Grid>
		);
	}
}

export default withStyles(styles)(HeaderSection);
