import React from 'react';

import * as client from 'api/client';
import EventFormDrawer from 'modules/Event/EventFormDrawer';

const UpdateEventDrawer = ({
	companyAllowSmsReminders,
	phaseId,
	title,
	startTime,
	endTime,
	allowSmsReminder,
	color,
	onEventUpdated,
	onClose,
}) => (
	<EventFormDrawer
		drawerTitle={'Edit event'}
		submitButtonLabel={'Edit'}
		isOpen={Boolean(phaseId)}
		onClose={onClose}
		title={title}
		startTime={startTime}
		endTime={endTime}
		color={color}
		allowSmsReminder={allowSmsReminder}
		companyAllowSmsReminders={companyAllowSmsReminders}
		showTitleSuggestions={false}
		onSubmit={({ title, startTime, endTime, allowSmsReminder, color }) => {
			return client.editPhase({ phaseId, title, startTime, endTime, allowSmsReminder, color }).then((response) => {
				if (response.success) {
					onEventUpdated(response.data.phase);
				}
				return response;
			});
		}}
	/>
);

export default UpdateEventDrawer;
