import { scroll } from 'styles/shared';

const styles = (theme) => ({
	container: {
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
	},
	title: {
		fontSize: 20,
		marginBottom: 10,
	},
	notesContainer: {
		...scroll,
	},
	messagesEditorInput: {
		padding: '10px 50px 10px 14px',
		backgroundColor: '#fff',
		border: '1px solid #011842 !important',
		borderRadius: 3,
	},
	messagesEditor: {
		width: '100%',
		paddingRight: 20,
	},
	notchedOutline: {
		border: 'none',
	},
	adornedEnd: {
		paddingRight: 5,
	},
	sendButton: {
		position: 'absolute',
		right: 5,
		bottom: 16,
	},
	noteName: {
		fontWeight: 600,
	},
	noteTime: {
		marginLeft: 10,
		marginTop: 2,
		fontSize: 12,
		color: '#545454',
		fontWeight: 400,
	},
	noteMenuButton: {
		padding: 3,
		marginLeft: 'auto',
	},
	noteText: {
		fontWeight: 400,
		lineHeight: '18px',
		color: '#515151',
		marginTop: -6,
		paddingRight: 50,
		overflowWrap: 'break-word',
		'& ol': {
			paddingLeft: 15,
		},
		'& ul': {
			paddingLeft: 15,
		},
	},
});

export default styles;
