import { scroll } from 'styles/shared';

const styles = (theme) => ({
	container: {
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
	},
	phaseTitle: {
		color: '#00B2A1',
		fontWeight: 600,
		width: '100%',
		lineHeight: '18px',
		overflowWrap: 'break-word',
		whiteSpace: 'pre-wrap',
	},
	phasesContainer: {
		...scroll,
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			paddingRight: 4,
		},
	},
	phaseDate: {
		color: '#000',
	},
});

export default styles;
