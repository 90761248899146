import React from 'react';
import { Box, Paper, Grid, Typography, withStyles } from '@material-ui/core';
import { getImageUrl } from 'utils/image';
import moment from 'moment';
import CheckoutForm from './CheckoutForm';
import { ClientInvoiceStyles } from './style';
import { InvoiceStatus } from 'types/enums';
import TextVerticalAlignment from 'modules/Invoice/TextVerticalAlignment';
import MobileTable from 'modules/Invoice/MobileTable';
import WebInvoicePaymentsSection from 'modules/Invoice/WebInvoicePaymentsSection';
import WebTable from 'modules/Invoice/WebTable';
import InvoiceTotalsSection from 'modules/Invoice/InvoiceTotalsSection';
import { displayAmount } from 'utils/format';
import { withIsMobile } from 'hoc/IsMobileHOC';

const CustomerInvoiceContent = ({ invoice, classes, isMobile }) => (
	<Box className={classes.root} style={{ width: isMobile ? '100%' : 'auto' }}>
		<Paper className={classes.paper}>
			<Grid
				container
				justify="space-between"
				spacing={3}
				className={(classes.topBanner, isMobile ? classes.mobileTopBanner : '')}
			>
				<Grid item>
					{invoice.companyLogo && invoice.companyLogo.length ? (
						<img height="150px" width="auto" alt={invoice.companyName} src={getImageUrl(invoice.companyLogo)} />
					) : (
						''
					)}
					<Typography variant="h2" className={classes.titleCls}>
						{invoice.companyName}
					</Typography>
					<Box>{invoice.companyPhone}</Box>
					<Box>{invoice.companyEmail}</Box>
					<Box>{invoice.companyAddress}</Box>
				</Grid>
				<Grid item>
					<Typography variant="h1" component="h1" className={classes.titleCls}>
						INVOICE #{invoice.number}
					</Typography>
					<TextVerticalAlignment
						rows={[
							{
								label: <b>Invoice Date:</b>,
								value: moment(invoice.issuedDate).format('MMM DD, YYYY'),
							},
							{
								label: <b>Invoice Due:</b>,
								value: moment(invoice.dueDate).format('MMM DD, YYYY'),
							},
						]}
					/>
					<Box paddingTop="30px">
						{invoice.status === InvoiceStatus.Paid ? (
							<Typography variant="h2" className={classes.titleCls} style={{ color: 'green' }}>
								PAID
							</Typography>
						) : null}
						<Typography>Amount Due (USD)</Typography>
						<Typography variant="h2" className={classes.titleCls}>
							{displayAmount(invoice.amountDue / 100.0)}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Box className={classes.customerInfo} color="#8C959A" textAlign="start">
				BILL TO
			</Box>
			<Grid container justify="space-between" spacing={3}>
				<Grid item>
					<Box className={classes.boldTitles}>{invoice.customerName}</Box>
					<Box style={{ textAlign: 'start' }}>{invoice.customerPhone}</Box>
					<Box>{invoice.customerBillingAddress}</Box>
				</Grid>
			</Grid>
			{isMobile ? <MobileTable items={invoice.items} /> : <WebTable items={invoice.items} />}

			<div className={classes.totals}>
				<InvoiceTotalsSection invoice={invoice} />
			</div>

			{invoice.payments && invoice.payments.length > 0 ? <WebInvoicePaymentsSection invoice={invoice} /> : null}

			{invoice.invoiceCustomerNote && (
				<Box display="flex" flexDirection="column" mt={2}>
					<Box textAlign="left" component="b">
						Note
					</Box>
					<Box textAlign="left">{invoice.invoiceCustomerNote}</Box>
				</Box>
			)}

			{invoice.companyTermsAndConditions && (
				<Box display="flex" flexDirection="column" mt={2}>
					<Box display="flex" flexDirection="column">
						<Box textAlign="left" component="b">
							Terms &amp; Conditions
						</Box>
						<Box textAlign="left">{invoice.companyTermsAndConditions}</Box>
					</Box>
				</Box>
			)}
		</Paper>
		{invoice.shouldAcceptPayment ? (
			<CheckoutForm
				payButtonLabel={`Pay ${displayAmount(invoice.amountDue / 100)}`}
				stripeClientSecret={invoice.stripeClientSecret}
				stripeAccount={invoice.stripeAccount}
			/>
		) : (
			''
		)}
	</Box>
);

export default withIsMobile(withStyles(ClientInvoiceStyles)(CustomerInvoiceContent));
