import * as _ from 'lodash';
import React, { Component } from 'react';
import { Box, Grid, CircularProgress, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import * as client from 'api/client';
import InfoSection from 'modules/Project/Details/InfoSection';
import NotesSection from 'modules/Project/Details/NotesSection';
import EventsSection from 'modules/Project/Details/EventsSection';
import { withAuthContext } from 'context/AuthContext';
import { withSnackbarContext } from 'context/SnackbarsContext';
import TitleSection from 'components/TitleSection';
import styles from 'modules/Project/Details/styles/projectDetails';
import { Button } from 'components/common';
import EditProjectDrawer from 'modules/Project/Edit';

const messages = {
	noteDeletionSuccess: 'Note deleted sucesfully',
	noteDeletionFailure: 'Something went wrong, please try again later',
};

class ProjectDetailsView extends Component {
	state = {
		project: {},
		isLoading: true,
		isEditing: false,
	};

	fetchData = async (projectId, customer) => {
		this.setState({ isLoading: true });
		try {
			const response = await client.projectDetails({
				projectId,
				withCustomer: !customer,
			});
			this.setState({ isLoading: false, project: response.data.project });
		} catch (error) {
			const { snackbarContext } = this.props;

			snackbarContext.failure('Something went wrong');
			console.error('Error during fetch()', error);
		}
	};

	componentDidMount = () => {
		const { location } = this.props;
		const customer = location.state?.customer;
		const project = location.state?.project;

		if (!project) {
			const projectId = +this.props.match.params.projectId;
			this.fetchData(projectId, customer);
		} else {
			this.setState({ isLoading: false, project: { ...project, customer } });

			// Clear the router status to re-fetch the data back if the page is reloaded.
			const history = createBrowserHistory();
			history.replace({ ...history.location, state: null });
		}
	};

	handleDeleteAttachment = (attachmentId) => {
		return client
			.deleteAttachments({
				attachmentIds: [+attachmentId],
			})
			.then((response) => {
				if (response.success) {
					const { deletedAttachmentIds } = response.data;
					if (deletedAttachmentIds.indexOf(+attachmentId) >= 0) {
						this.setState(({ project }) => ({
							project: {
								...project,
								attachments: project.attachments.filter(({ id }) => id !== +attachmentId),
							},
						}));
					}
				}
				return response.success;
			})
			.catch((error) => {
				const { snackbarContext } = this.props;
				snackbarContext.failure('Error deleting attachment!');
				console.error(error);
				return false;
			});
	};

	handleUploadFiles = async (files) => {
		const { project } = this.state;
		const { snackbarContext } = this.props;
		const filesToUpload = [];
		for (let index = 0; index < files.length; index++) {
			filesToUpload.push(files.item(index));
		}

		try {
			const response = await client.addAttachments({
				projectId: project.projectId,
				files: filesToUpload,
			});
			snackbarContext.success('Files uploaded succesfully');
			this.setState((prevState) => {
				const project = {
					...prevState.project,
					attachments: [...prevState.project.attachments, ...response.data.attachments],
				};
				return { ...prevState, project };
			});
		} catch (error) {
			snackbarContext.failure('Error uploading files');
		}
	};

	handleCreateNote = (note) => {
		const { projectId } = this.state.project;
		return client
			.createNote({ projectId, note })
			.then((response) => {
				// alert(JSON.stringify(response));
				if (response.success) {
					this.setState(({ project }) => ({
						project: {
							...project,
							notes: [...project.notes, response.data.note],
						},
					}));
				} else {
					const { snackbarContext } = this.props;
					snackbarContext.failure('Error creating note.');
				}
				return response.success;
			})
			.catch((error) => {
				const { snackbarContext } = this.props;
				snackbarContext.failure('Error creating note.');
				console.error(error);
				return false;
			});
	};

	handleDeleteNote = (noteId) => {
		if (!noteId) {
			return;
		}

		const { snackbarContext } = this.props;

		client
			.deleteNote({ noteId })
			.then((response) => {
				if (response.success) {
					this.setState(({ project }) => ({
						project: {
							...project,
							notes: project.notes.filter((note) => note.noteId !== noteId),
						},
					}));
					snackbarContext.success(messages.noteDeletionSuccess);
				} else {
					snackbarContext.failure(messages.noteDeletionFailure);
				}
			})
			.catch((error) => {
				console.error(error);
				snackbarContext.failure(messages.noteDeletionFailure);
			});
	};

	handleEventCreated = (phase) => {
		this.setState(({ project }) => ({
			project: {
				...project,
				phases: [...project.phases, phase],
			},
		}));
	};

	handleEventDeleted = (phaseId) => {
		this.setState(({ project }) => ({
			project: {
				...project,
				phases: project.phases.filter(({ projectPhaseId }) => projectPhaseId !== phaseId),
			},
		}));
	};

	handleEventUpdated = (updatedPhase) => {
		this.setState(({ project }) => ({
			project: {
				...project,
				phases: project.phases.map((phase) =>
					phase.projectPhaseId === updatedPhase.projectPhaseId ? updatedPhase : phase
				),
			},
		}));
	};

	handleProjectEdited = (project) => {
		this.setState((previousState) => ({
			project: {
				...previousState.project,
				// To avoid overriding any previous field with the ones from the edited
				// project ( potentially incompleted ) we just pick those values that can
				// be edited from the drawer. If we add a new field to the drawer, we need
				// to include it here :/
				..._.pick(project, ['title', 'price', 'address', 'status', 'paymentMethod']),
			},
			isEditing: false,
		}));
	};

	render = () => {
		const { isLoading, isEditing, project } = this.state;
		const { classes } = this.props;

		if (isLoading) {
			return (
				<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
					<CircularProgress size={60} />
				</Box>
			);
		}

		const notes = project.notes.sort((a, b) => {
			return moment(b.creationDate).valueOf() - moment(a.creationDate).valueOf();
		});

		return (
			<Box>
				<EditProjectDrawer
					project={isEditing && project}
					onEdit={this.handleProjectEdited}
					onClose={() => this.setState({ isEditing: false })}
				/>

				<TitleSection title="Project details">
					<Button onClick={() => this.setState({ isEditing: true })} variant="text" color="primary" height={28}>
						Edit
					</Button>
				</TitleSection>
				<Box mt="30px" mb="20px">
					<Grid item container direction="row">
						<Grid item container direction="column" md={12} sm={12}>
							<Box className={classes.container}>
								<InfoSection
									project={project}
									onUploadFiles={this.handleUploadFiles}
									onDeleteAttachment={this.handleDeleteAttachment}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box pb="20px">
					<Grid item container direction="row">
						<Grid item container direction="column" lg={7} md={6} sm={12}>
							<Box mr="20px" className={classes.container}>
								<NotesSection notes={notes} onCreateNote={this.handleCreateNote} onDeleteNote={this.handleDeleteNote} />
							</Box>
						</Grid>
						<Grid item container direction="column" lg={5} md={6} sm={12}>
							<Box ml="20px" className={classes.container}>
								<EventsSection
									projectId={project.projectId}
									phases={project.phases}
									onEventCreated={this.handleEventCreated}
									onEventDeleted={this.handleEventDeleted}
									onEventUpdated={this.handleEventUpdated}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		);
	};
}

export default withAuthContext(withSnackbarContext(withRouter(withStyles(styles)(ProjectDetailsView))));
