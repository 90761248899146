// Return a function which passing the field name return
// common props needed in Formik fields.

function useCommonProps({ errors, onChange, onBlur, touched, isPending, values }) {
	return (fieldName) => ({
		name: fieldName,
		value: values[fieldName],
		onChange: onChange,
		onBlur: onBlur,
		disabled: Boolean(isPending),
		error:
			!!errors[fieldName] &&
			(errors[fieldName] !== 'Required.' || (errors[fieldName] === 'Required.' && touched[fieldName])),
		helperText:
			(errors[fieldName] !== 'Required.' || (errors[fieldName] === 'Required.' && touched[fieldName])) &&
			errors[fieldName],
	});
}

export { useCommonProps };
