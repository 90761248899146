import React, { Component } from 'react';
import { Link, Box, Typography, Divider, withStyles } from '@material-ui/core';

class Footer extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Box width="100%" bgcolor="#011842" bottom="0" className={classes.container}>
				<Box display="flex" justifyContent="center" className={classes.linkContainer}>
					<Link variant="h5" className={classes.text} href="/privacy">
						Privacy Policy
					</Link>

					<Link variant="h5" className={classes.text} href="/tos">
						Terms of Service
					</Link>

					<Link variant="h5" className={classes.text} href="mailto:support@werkgo.com">
						support@werkgo.com
					</Link>
				</Box>

				<Divider className={classes.divider} />
				<Typography variant="h5" align="center" className={classes.copyright}>
					Copyright © Werkgo 2020
				</Typography>
			</Box>
		);
	}
}

const styles = () => ({
	container: {
		padding: 10,
	},
	divider: {
		marginBottom: 10,
		backgroundColor: '#FFFFFFA0',
	},
	copyright: {
		color: '#FFFFFFA0',
	},
	text: {
		color: '#B8B8B8',
		margin: '0 10px',
		'&:hover': {
			color: '#A7CCF5',
		},
	},
	linkContainer: {
		marginBottom: 10,
	},
});

export default withStyles(styles)(Footer);
