/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, postMultipart, GenericSuccess, GenericError } from 'api/core';

//***************
// TYPES
//***************

//
// addAttachments
//

export interface AddAttachmentArgs {
	projectId: number;
	files: File[];
}

export type AddAttachmentResult =
	| GenericSuccess<{
			attachments: models.ProjectAttachment[];
	  }>
	| GenericError;

//
// deleteAttachments
//

export interface DeleteAttachmentsArgs {
	attachmentIds: number[];
}

export type DeleteAttachmentsResult =
	| GenericSuccess<{
			deletedAttachmentIds: number[];
	  }>
	| GenericError;

//***************
// REQUESTS
//***************

export const addAttachments = (args: AddAttachmentArgs): Promise<AddAttachmentResult> => {
	const data = new FormData();
	data.append('projectId', args.projectId.toString());
	args.files.forEach((file) => data.append('attachments', file, file.name));
	return postMultipart('attachment/add/', data);
};

export const deleteAttachments = (args: DeleteAttachmentsArgs): Promise<DeleteAttachmentsResult> => {
	return post('attachment/delete/', args);
};
