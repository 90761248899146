import React, { Component } from 'react';
import { Grid, Drawer, List, ListItem, Typography, Avatar, withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import clsx from 'clsx';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ArtTrackOutlinedIcon from '@material-ui/icons/ArtTrackOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import { breakpoints } from 'theme';
import { clearLocalStorage } from 'utils/localStorage';
import { getImageUrl } from 'utils/image';
import { IsProduction } from 'constant';
import { withAuthContext } from 'context/AuthContext';
import styles from 'components/bars/NavBar/styles';
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined';

const menuItems = [
	{ icon: <CalendarTodayOutlinedIcon />, text: 'Calendar', url: '/calendar' },
	{ icon: <PeopleAltOutlinedIcon />, text: 'Customers', url: '/customers' },
	{ icon: <ArtTrackOutlinedIcon />, text: 'Projects', url: '/projects' },
	{ icon: <AssignmentTurnedInOutlinedIcon />, text: 'Tasks', url: '/tasks' },
	{
		icon: <DescriptionOutlinedIcon />,
		text: 'Invoices',
		url: '/invoices',
	},
	{ icon: <BusinessOutlinedIcon />, text: 'Workers', url: '/workers' },
	{ icon: <PowerOutlinedIcon />, text: 'Integrations 🆕', url: '/integrations' },
	{ icon: <AccountCircleOutlinedIcon />, text: 'Account', url: '/profile' },
];

class NavBar extends Component {
	handleLogOut = () => {
		clearLocalStorage();
		window.location.replace('/home');
	};

	isSelected = (item) => {
		const {
			location: { pathname },
		} = this.props;

		const currentSection = pathname.split('/')[1];
		return (
			currentSection === item.url.slice(1) ||
			currentSection === item.url.slice(1, -1) ||
			(item.url.slice(1) === 'calendar' && currentSection === '')
		);
	};

	render() {
		const {
			isMobileNavBarOpen,
			toggleMobileNavBar,
			authContext: { firstName, avatar, companyId },
			classes,
		} = this.props;

		return (
			<Drawer
				open={isMobileNavBarOpen}
				onClose={toggleMobileNavBar}
				anchor="left"
				variant={window.innerWidth < breakpoints.md ? 'temporary' : 'permanent'}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<Grid container className={classes.avatarAndName}>
					<Avatar src={avatar ? getImageUrl(avatar) : null} className={classes.avatar} />
					<Typography>{firstName}</Typography>
				</Grid>

				<List className={classes.list}>
					{menuItems.map((item) => {
						if (IsProduction && item.companyGated && item.companyGated.indexOf(companyId) === -1) {
							return null;
						}

						return (
							<ListItem
								onClick={toggleMobileNavBar}
								key={item.text}
								className={clsx(classes.menuItem, { [classes.selectedSection]: this.isSelected(item) })}
							>
								<Link to={item.url} className={clsx(classes.link, { [classes.selectedLink]: this.isSelected(item) })}>
									<Grid container wrap="nowrap">
										{item.icon} <Typography className={classes.menuText}> {item.text} </Typography>
									</Grid>
								</Link>
							</ListItem>
						);
					})}

					<ListItem onClick={this.handleLogOut} className={clsx(classes.menuItem, classes.logout)}>
						<Grid container wrap="nowrap">
							<ExitToApp /> <Typography className={classes.menuText}> Logout </Typography>
						</Grid>
					</ListItem>
				</List>
			</Drawer>
		);
	}
}

export default withStyles(styles)(withRouter(withAuthContext(NavBar)));
