import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';

const Actions = ({ updateUrl, updateOnClick, removeUrl, removeOnClick }) => (
	<Box>
		{(removeUrl || removeOnClick) && (
			<ActionLink url={removeUrl} onClick={removeOnClick} color="#ff0000" title="Delete" />
		)}
		{(updateUrl || updateOnClick) && (
			<ActionLink url={updateUrl} onClick={updateOnClick} color="#000000" title="Edit" />
		)}
	</Box>
);

const ActionLink = ({ title, url, onClick, color }) => (
	<Link variant="body2" underline="always" to={url} onClick={onClick} style={{ color, cursor: 'pointer', margin: 5 }}>
		{title}
	</Link>
);

Actions.propTypes = {
	updateUrl: PropTypes.string,
	removeUrl: PropTypes.string,
	updateOnClick: PropTypes.func,
	removeOnClick: PropTypes.func,
};

export default Actions;
