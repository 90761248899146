import React, { Component } from 'react';
import { withIsMobile } from 'hoc/IsMobileHOC';
import CustomerProjectsMobileTable from 'modules/Customer/Details/CustomerProjectsMobileTable';
import CustomerProjectsWebTable from 'modules/Customer/Details/CustomerProjectsWebTable';

class CustomerProjects extends Component {
	render() {
		const { isMobile } = this.props;
		return isMobile ? <CustomerProjectsMobileTable {...this.props} /> : <CustomerProjectsWebTable {...this.props} />;
	}
}

export default withIsMobile(CustomerProjects);
