import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as client from 'api/client';
import { InvoiceStatus } from 'types/enums';
import CustomerInvoiceContent from 'modules/Invoice/CustomerInvoiceContent';

class CustomerInvoice extends Component {
	state = {
		invoice: null,
	};

	componentDidMount() {
		const invoiceId = this.props.match.params.id;
		client.getInvoice({ invoiceId }).then((response) => {
			if (response.success) {
				const { invoice } = response.data;

				this.setState({ invoice });

				if (invoice.status === InvoiceStatus.Sent || invoice.status === InvoiceStatus.Open) {
					const preview = new URLSearchParams(this.props.location.search).get('preview');
					if (preview !== '1') {
						client.recordOpenedInvoice({ invoiceUUID: invoice.uuid });
					}
				}

				return invoice;
			}
		});
	}

	render() {
		const { invoice } = this.state;
		return invoice ? <CustomerInvoiceContent invoice={invoice} /> : null;
	}
}

export default withRouter(CustomerInvoice);
