import React, { Component } from 'react';
import { CircularProgress, TextField, Grid } from '@material-ui/core';
import { currencyFormater } from 'utils/format';
import * as _ from 'lodash';
import * as client from 'api/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DefaultPageSize } from 'utils/search';
import { withSnackbarContext } from 'context/SnackbarsContext';
import CreateProjectDrawer from 'modules/Project/CreateProjectDrawer';

class ProjectAutocomplete extends Component {
	searchDebounced = null;
	createProjectOption = { title: '+ Create new project', createOption: true };

	state = {
		isCreating: false,
		loading: false,
		open: false,
		options: [this.createProjectOption],
		current: this.props.initialValue,
	};

	componentDidMount = () => {
		this.searchDebounced = _.debounce(this.search, 300);
		this.searchDebounced();
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.customerId !== this.props.customerId) {
			this.setState({ current: '' }, () => this.search());
		}
	};

	/**
	 * Called once the user types in the input.
	 */
	handleChange = (event) => {
		event.persist();
		this.updateCurrent(event.target.value, true /* triggerSearch */);
	};

	/**
	 * Called once the user selects a value from the dropdown. Since this
	 * is a choice from the list of options, no search needs to be done.
	 */
	handleSelect = (e, selectedValue) => {
		if (selectedValue && selectedValue.createOption) {
			this.setState({ isCreating: true });
		} else {
			this.updateCurrent(selectedValue, false /* triggerSearch */);
			const { onSelect } = this.props;
			if (onSelect) {
				onSelect(e, selectedValue);
			}
		}
	};

	/**
	 * This method updates the state of the current value in the input
	 * box and notifies the parent component about this change. It also
	 * triggers a search if requested.
	 */
	updateCurrent = (current, triggerSearch) => {
		this.setState({ current, loading: triggerSearch }, () => {
			if (triggerSearch) {
				this.searchDebounced();
			}
		});
	};

	search = () => {
		const { customerId } = this.props;

		if (!customerId) {
			return;
		}

		let { current } = this.state;

		if (typeof current === 'object') {
			current = current.title;
		}

		client
			.getProjects({
				pagination: { start: 0, limit: DefaultPageSize },
				search: { query: current },
				filters: { customerId },
			})
			.then((response) => {
				const { projects } = response.data;
				this.setState({
					options: [this.createProjectOption, ...projects],
					loading: false,
				});
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				snackbarContext.failure('Unable to fetch projects, refresh the page.');

				console.error(error);
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	handleProjectCreated = (newProject) => {
		const { snackbarContext } = this.props;
		const { options } = this.state;

		this.setState(
			{
				isCreating: false,
				options: [...options.slice(0, 1), newProject, ...options.slice(1, options.length)],
			},
			() => {
				snackbarContext.success('Project created');
			}
		);
	};

	render() {
		const { loading, open, current, options } = this.state;
		const { className, customerId } = this.props;

		return (
			<>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<Autocomplete
							className={className}
							open={open}
							onOpen={() => {
								this.setState({ open: true });
							}}
							onClose={() => {
								this.setState({ open: false });
							}}
							freeSolo={false}
							getOptionSelected={(option, value) => {
								if (!value || typeof value !== 'object') {
									return false;
								}
								return option.projectId === value.projectId;
							}}
							getOptionLabel={getProjectLabel}
							options={options}
							loading={loading}
							onChange={this.handleSelect}
							value={current}
							renderOption={(props) => {
								const optionLabel = props.price
									? `${props.title} - ${currencyFormater.format(props.price)}`
									: props.title;
								return props.createOption ? <b>{optionLabel}</b> : optionLabel;
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									label={this.props.label ?? 'Project'}
									name="project"
									variant="outlined"
									onChange={this.handleChange}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<CreateProjectDrawer
					isCreating={this.state.isCreating}
					customerId={customerId}
					onProjectCreated={this.handleProjectCreated}
					onClose={() => this.setState({ isCreating: false })}
				/>
			</>
		);
	}
}

const getProjectLabel = (project) => {
	if (typeof project !== 'object') {
		return project || '';
	}
	return project.title;
};

export default withSnackbarContext(ProjectAutocomplete);
