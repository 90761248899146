import React from 'react';
import { Box, Grid, withStyles } from '@material-ui/core';
import { ClientInvoiceStyles } from './style';
import { displayAmount } from 'utils/format';

const MobileTable = withStyles(ClientInvoiceStyles)(({ items, classes }) => (
	<>
		<Grid container justify="space-between" className={classes.table}>
			<Grid item>ITEMS</Grid>
			<Grid item>AMOUNT</Grid>
		</Grid>
		{items.map((item) => {
			return (
				<Grid key={item.name} container justify="space-between" alignItems="center" className={classes.mobileTableItem}>
					<Grid item>
						<Grid container direction="column" alignItems="flex-start">
							<Grid item>{item.name}</Grid>
							<Grid item>
								<Box display="flex">
									<Box
										bgcolor="#DEE1E2"
										border="1px solid #DEE1E2"
										width="fit-content"
										height="25px"
										borderRadius="20%"
									>
										{parseFloat(item.quantity).toFixed(2)}
									</Box>
									&nbsp; x {displayAmount(item.price)}
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>{displayAmount(item.quantity * item.price)}</Grid>
				</Grid>
			);
		})}
	</>
));

export default MobileTable;
