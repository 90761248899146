import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { isAuthenticated } from 'utils/localStorage';
import { withStyles } from '@material-ui/core/styles';

import Page from 'components/Page';

import {
	AccountView,
	AppointmentDetails,
	Calendar,
	CustomerDetails,
	CustomerInvoice,
	CustomerList,
	Forgot,
	GoogleIntegrationResult,
	Home,
	Install,
	Integrations,
	InvoiceList,
	NotFound,
	Privacy,
	ProjectDetails,
	ProjectList,
	QRCode,
	Reset,
	Signin,
	Signup,
	StripeSuccessfullyConnected,
	TaskList,
	TermsOfService,
	Verify,
	Workers,
} from 'modules';
import MobilePrompt from 'components/mobile/MobilePrompt';

class Routes extends Component {
	renderRoute = ({ path, component: Component, name, guarded, standalone }) => {
		if (guarded && !isAuthenticated()) {
			return <Route exact key={path} name={name} path={path} component={() => <Redirect key={path} to="/home" />} />;
		}

		const { classes } = this.props;

		return (
			<Route
				exact
				key={path}
				name={name}
				path={path}
				component={(props) =>
					standalone ? (
						<Component {...props} />
					) : (
						<>
							{isAuthenticated() && <MobilePrompt />}
							<Page className={classes.root} title={name}>
								<Container maxWidth={false}>
									<Component {...props} />
								</Container>
							</Page>
						</>
					)
				}
			/>
		);
	};

	render() {
		return (
			<Switch>
				{routes.map(this.renderRoute)}
				<Route>
					<NotFound />
				</Route>
			</Switch>
		);
	}
}

const defaultRouteConfig = {
	appBar: true,
	navBar: true,
	guarded: true,
};

export const routes = [
	//----------------------------------------------------------------------
	// Calendar
	//----------------------------------------------------------------------

	{
		path: '/',
		component: Calendar,
		name: 'Calendar',
		...defaultRouteConfig,
	},
	{
		path: '/calendar',
		component: Calendar,
		name: 'Calendar',
		...defaultRouteConfig,
	},
	{
		path: '/calendar/:resolution/:year/:month/:day',
		component: Calendar,
		name: 'Calendar',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Customers
	//----------------------------------------------------------------------

	{
		path: '/customers',
		component: CustomerList,
		name: 'Customers',
		...defaultRouteConfig,
	},
	{
		path: '/customer/:customerId/details',
		component: CustomerDetails,
		name: 'Customer Details',
		...defaultRouteConfig,
	},
	{
		path: '/project/:projectId/details',
		component: ProjectDetails,
		name: 'Project Main',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Account
	//----------------------------------------------------------------------
	{
		path: '/profile',
		component: AccountView,
		name: 'Profile',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Projects
	//----------------------------------------------------------------------
	{
		path: '/projects',
		component: ProjectList,
		name: 'Projects',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Workers
	//----------------------------------------------------------------------
	{
		path: '/workers',
		component: Workers,
		name: 'Workers',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Tasks
	//----------------------------------------------------------------------
	{
		path: '/tasks',
		component: TaskList,
		name: 'Tasks',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Auth
	//----------------------------------------------------------------------
	{
		path: '/signin',
		component: Signin,
		name: 'Signin',
		guarded: false,
		standalone: true,
	},
	{
		path: '/signup/:invitationToken?',
		component: Signup,
		name: 'Signup',
		guarded: false,
		standalone: true,
	},
	{
		path: '/install',
		component: Install,
		name: 'Install',
		guarded: false,
		standalone: true,
	},
	{
		path: '/forgot',
		component: Forgot,
		name: 'Forgot',
		guarded: false,
		standalone: true,
	},
	{
		path: '/reset/:userId/:token',
		component: Reset,
		name: 'Reset',
		guarded: false,
		standalone: true,
	},
	{
		path: '/verify/:userId?/:token?',
		component: Verify,
		name: 'Verify Email',
		guarded: false,
		standalone: true,
	},
	//----------------------------------------------------------------------
	// Home
	//----------------------------------------------------------------------
	{
		path: '/home',
		component: Home,
		name: 'Home Page',
		guarded: false,
		standalone: true,
		appBar: true,
	},
	{
		path: '/tos',
		component: TermsOfService,
		name: 'Terms of service | Werkgo',
		guarded: false,
		standalone: true,
		appBar: true,
	},

	{
		path: '/privacy',
		component: Privacy,
		name: 'Privacy | Werkgo',
		guarded: false,
		standalone: true,
		appBar: true,
	},
	//----------------------------------------------------------------------
	// Integrations
	//----------------------------------------------------------------------
	{
		path: '/integration/google/:status',
		component: GoogleIntegrationResult,
		name: 'Google Integration',
		...defaultRouteConfig,
	},

	{
		path: '/stripe/success',
		component: StripeSuccessfullyConnected,
		name: 'Stripe successfully connected',
		guarded: false,
		standalone: true,
		appBar: false,
	},

	{
		path: '/integrations',
		component: Integrations,
		name: 'Integrations',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Appointments
	//----------------------------------------------------------------------
	{
		path: '/a/:id',
		component: AppointmentDetails,
		name: 'Appointment details',
		guarded: false,
		standalone: true,
		appBar: false,
	},

	//----------------------------------------------------------------------
	// Invoices
	//----------------------------------------------------------------------
	{
		path: '/i/:id',
		component: CustomerInvoice,
		name: 'Invocice details',
		guarded: false,
		standalone: true,
		appBar: false,
	},
	{
		path: '/invoices',
		component: InvoiceList,
		name: 'Invoices',
		...defaultRouteConfig,
	},

	//----------------------------------------------------------------------
	// Extra
	//----------------------------------------------------------------------
	{
		path: '/qr-code',
		component: QRCode,
		name: 'QR code',
		guarded: false,
		standalone: true,
	},
	{
		path: '/app-ads.txt',
		name: 'App ads',
		guarded: false,
		standalone: true,
	},
];

const styles = (theme) => ({
	root: {
		height: '100%',
		width: '100%',
		backgroundColor: theme.palette.background.dark,
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
});

export default withStyles(styles)(Routes);
