/**
 * Determine the mobile operating system [https://stackoverflow.com/a/21742107/4586108]
 *
 * @returns {String} This function returns one of 'iOS', 'Android', 'Windows Phone', or
 * 'unknown'.
 */
export function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return 'Windows Phone';
	}

	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'iOS';
	}

	return 'unknown';
}
