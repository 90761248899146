import React from 'react';
import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HighlightOffOutlined } from '@material-ui/icons';
import CustomerUpdateForm from 'modules/Customer/Update/CustomerUpdateForm';

const UpdateCustomerDrawer = ({ customer, onCustomerUpdated, onClose, classes }) => (
	<Drawer
		anchor="right"
		classes={{ paper: classes.drawer }}
		onClose={onClose}
		open={Boolean(customer)}
		variant="temporary"
	>
		<Box p={2} display="flex" justifyContent="space-between" alignItems="center">
			<Typography variant="h4" color="textPrimary">
				Edit customer
			</Typography>
			<IconButton onClick={onClose}>
				<HighlightOffOutlined />
			</IconButton>
		</Box>

		<Box p={2} overflow="auto">
			<CustomerUpdateForm customer={customer} onCustomerUpdated={onCustomerUpdated} />
		</Box>
	</Drawer>
);

const styles = {
	drawer: { width: 500, maxWidth: '100%' },
};

export default withStyles(styles)(UpdateCustomerDrawer);
