import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { SharedSnackbarConsumer } from 'context/SnackbarsContext';
import { withIsMobile } from 'hoc/IsMobileHOC';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SharedSnackbar = ({ isMobile }) => (
	<SharedSnackbarConsumer>
		{({ snackbarIsOpen, message, closeSnackbar, type }) => {
			return (
				<Snackbar
					style={{ width: 256, maxWidth: '100%' }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: isMobile ? 'center' : 'left',
					}}
					open={snackbarIsOpen}
					autoHideDuration={5000}
					onClose={closeSnackbar}
				>
					<Alert onClose={closeSnackbar} severity={type}>
						{message}
					</Alert>
				</Snackbar>
			);
		}}
	</SharedSnackbarConsumer>
);

export default withIsMobile(SharedSnackbar);
