import React, { useEffect, useState } from 'react';
import * as client from 'api/client';
import NameSuggestions from 'components/suggestions/NameSuggestions';

const EventNameSuggestions = (props) => {
	const [names, setNames] = useState([]);
	useEffect(() => {
		client.getMostUsedEventNames().then((response) => {
			if (response.success) {
				setNames(response.data.names);
			}
		});
	}, []);
	return <NameSuggestions names={names} {...props} />;
};

export default EventNameSuggestions;
