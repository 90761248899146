/* eslint-disable max-len */
import React, { Component } from 'react';
import { CardContent, Typography, Box } from '@material-ui/core';

const IntegrationResultSuccess = () => (
	<>
		<Typography variant="h4" component="h4" align="center" style={{ color: '#00b830' }}>
			Your company just integrated with Google sucesfully
		</Typography>
		<Typography paragraph={true} align="justify">
			Now all customer and workers will have the corresponding Google Contact created. To get the most from this
			integration, please read the following article{' '}
			<a href="https://support.google.com/contacts/answer/2753077" target="_blank" rel="noopener noreferrer">
				Sync Google Contacts with your mobile device or computer
			</a>
			.
		</Typography>
	</>
);

const IntegrationResultFailure = () => (
	<Typography variant="h4" component="h4" align="center" style={{ color: '#ff2925' }}>
		Unable to integrate your company with Google
	</Typography>
);

class GoogleIntegrationResult extends Component {
	render() {
		const status = this.props?.match?.params?.status;

		return (
			<Box mx="auto" p={2} bgcolor="background.paper" style={{ maxWidth: '512px', width: '50%' }}>
				<Typography variant="h1" component="h2" align="center">
					Google Integration
				</Typography>

				<CardContent style={{ textAlign: 'center' }}>
					<img
						alt="google-logo"
						style={{ maxWidth: '124px' }}
						src={require('statics/images/integrations/google.svg')}
					/>
				</CardContent>

				{status === 'success' ? <IntegrationResultSuccess /> : <IntegrationResultFailure />}
			</Box>
		);
	}
}

export default GoogleIntegrationResult;
