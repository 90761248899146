import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, IconButton, withStyles, TextField } from '@material-ui/core';
import { Send, MoreVert } from '@material-ui/icons';

import { relativeTime } from 'utils/time';
import { Menu } from 'components/common';
import ConfirmationAlert from 'components/confirmationAlert';
import styles from 'modules/Project/Details/styles/notesSection';
import EmptyState from 'components/EmptyState';
import ReactMarkdown from 'react-markdown';

const messages = {
	deleteNoteWarn: 'Are you sure you want to delete this note?',
};

const Note = withStyles(styles)(({ note, onDelete, classes }) => (
	<Box width="100%" minHeight="70px" p="5px 0" m="10px 0" borderRadius="5px">
		<Grid container alignItems="flex-start">
			<Typography variant="h5" className={classes.noteName}>
				{note.author.fullName}
			</Typography>
			<Typography variant="h6" className={classes.noteTime}>
				{relativeTime(note.creationDate)}
			</Typography>
			<Menu
				items={[
					{
						value: 'delete',
						label: 'Delete',
						onClick: () => onDelete(note.noteId),
					},
				]}
				button={
					<IconButton className={classes.noteMenuButton}>
						<MoreVert />
					</IconButton>
				}
			/>
		</Grid>
		<Typography variant="h6" className={classes.noteText}>
			<ReactMarkdown>{note.content}</ReactMarkdown>
		</Typography>
	</Box>
));

Note.propTypes = {
	name: PropTypes.string,
	time: PropTypes.string,
	text: PropTypes.string,
};

class NotesSection extends Component {
	state = {
		creatingNote: false,
		noteToDelete: null,
	};

	constructor(props) {
		super(props);
		this.textFieldRef = React.createRef();
	}

	handleCreate = () => {
		// Before creating a note, we set `creatingNote`. This flag is used
		// to disable the note text box. If the note was created, then the
		// input is cleared, otherwise, we keep the text to avoid lost.
		this.setState({ creatingNote: true }, () => {
			const { onCreateNote } = this.props;
			onCreateNote(this.textFieldRef.current.value).then((created) => {
				if (created) {
					this.textFieldRef.current.value = '';
				}
				this.setState({ creatingNote: false });
			});
		});
	};

	//----------------------------------------------------------------------
	// Handlers related to delete a note
	//----------------------------------------------------------------------

	handleDeleteNoteIntention = (noteId) => {
		this.setState({ noteToDelete: noteId });
	};

	handleDeleteNote = () => {
		const { noteToDelete } = this.state;
		// Probably not the correct way to do this :/
		this.setState({ noteToDelete: null }, () => {
			this.props.onDeleteNote(noteToDelete);
		});
	};

	renderNotesList = () => {
		const { notes, classes } = this.props;

		if (!notes.length) {
			return (
				<Box height="100%" display="flex" justifyContent="center">
					<EmptyState name="es-no-notes" title="Add a new note">
						Your future self will thank you :)
					</EmptyState>
				</Box>
			);
		}

		return (
			<Box height="100%" pr="20px" className={classes.notesContainer}>
				{notes.map((note) => (
					<Note key={note.noteId} note={note} onDelete={this.handleDeleteNoteIntention} />
				))}
			</Box>
		);
	};

	render() {
		const { classes } = this.props;
		const { noteToDelete, creatingNote } = this.state;

		return (
			<Box
				display="flex"
				flexDirection="column"
				bgcolor="#fff"
				borderRadius="10px"
				p="20px 40px"
				height="400px"
				className={classes.container}
			>
				<Typography variant="h4" className={classes.title}>
					Notes
				</Typography>

				<ConfirmationAlert
					openAlert={!!noteToDelete}
					onCancelation={() => this.setState({ noteToDelete: null })}
					onConfirmation={this.handleDeleteNote}
					message={messages.deleteNoteWarn}
				/>

				{this.renderNotesList()}

				<TextField
					variant="outlined"
					multiline
					rowsMax={5}
					inputRef={this.textFieldRef}
					disabled={creatingNote}
					className={classes.messagesEditor}
					inputProps={{
						className: classes.messagesEditorInput,
					}}
					InputProps={{
						endAdornment: (
							<IconButton onClick={this.handleCreate} className={classes.sendButton}>
								<Send />
							</IconButton>
						),
						classes: {
							notchedOutline: classes.notchedOutline,
							adornedEnd: classes.adornedEnd,
						},
					}}
				/>
			</Box>
		);
	}
}

NotesSection.propTypes = {
	notes: PropTypes.arrayOf(
		PropTypes.shape({
			noteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			content: PropTypes.string,
			author: PropTypes.shape({
				fullName: PropTypes.string,
			}),
			creationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	onCreateNote: PropTypes.func,
	onDeleteNote: PropTypes.func,
};

export default withStyles(styles)(NotesSection);
