import React, { Component } from 'react';
import * as client from 'api/client';
import PropTypes from 'prop-types';
import { Avatar, Box, Chip, Link as MuiLink, Typography, withStyles } from '@material-ui/core';
import { Row, Column, Header, Content } from 'components/table';
import { withSnackbarContext } from 'context/SnackbarsContext';
import { getInitials } from 'utils/string';
import { getImageUrl } from 'utils/image';
import { getCompanyId } from 'utils/localStorage';
import Actions from 'components/common/Actions';

const headerInfo = [
	{ text: 'Name', xs: 4 },
	{ text: 'Status', xs: 1 },
	{ text: 'Phone', xs: 2 },
	{ text: 'Address', xs: 3 },
	{ text: '', xs: 2 },
];

class Table extends Component {
	renderWorkerStatus = (worker) => {
		const { isDeleted, isVerified } = worker;
		return (
			<Chip
				variant="outlined"
				size="small"
				label={isDeleted ? 'deleted' : isVerified ? 'verified' : 'unverified'}
				disabled={isDeleted}
				color={!isDeleted && isVerified ? 'primary' : undefined}
			/>
		);
	};

	resendEmail = (email) => {
		const { snackbarContext } = this.props;
		const companyId = getCompanyId();

		client.sendInviteEmail({ email, companyId }).then((response) => {
			if (response.success) {
				snackbarContext.success('Email sent');
			}
		});
	};

	render() {
		const { workers, classes, onUpdateWorker, onDeleteWorkerIntent } = this.props;

		return (
			<>
				<Header headerInfo={headerInfo} />
				{workers.map((worker) => {
					const { id, avatar, fullName, isAdmin, email, phone, address, inviteEmailPending } = worker;

					return (
						<Row key={id}>
							<Column justifyContent="flex-start" xs={4}>
								<Box display="flex" alignItems="center">
									<Avatar style={{ marginRight: '15px' }} src={getImageUrl(avatar)}>
										{getInitials(fullName)}
									</Avatar>
									<div>
										<Typography variant="h6">
											{inviteEmailPending ? `${fullName} (pending invitation)` : fullName}
											{isAdmin && (
												<span>
													&nbsp;(
													<small>
														<strong>admin</strong>
													</small>
													)
												</span>
											)}
										</Typography>
										{worker?.email && (
											<Content component={MuiLink} href={`mailto:${email}`} className={classes.emailText}>
												{email}
											</Content>
										)}
									</div>
								</Box>
							</Column>

							<Column xs={1}>
								<Content>{this.renderWorkerStatus(worker)}</Content>
							</Column>

							<Column xs={2}>
								<Content component={MuiLink} href={`tel:${phone}`}>
									{worker?.phone}
								</Content>
							</Column>

							<Column xs={3}>
								<Content
									component={MuiLink}
									target="_blank"
									rel="noopener"
									href={`https://maps.google.com?q=${address.address}`}
								>
									{address.address}
								</Content>
							</Column>

							<Column justifyContent="flex-end" xs={2}>
								<Actions
									updateOnClick={() => onUpdateWorker(worker)}
									removeOnClick={() => onDeleteWorkerIntent(worker)}
									// TODO(leandro): Enable invitation email!
									// sendEmail={inviteEmailPending ? () => this.resendEmail(email) : null}
								/>
							</Column>
						</Row>
					);
				})}
			</>
		);
	}
}

Table.propTypes = {
	workers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			fullName: PropTypes.string,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			phone: PropTypes.string,
			email: PropTypes.string,
			avatar: PropTypes.string,
			address: PropTypes.shape({
				address: PropTypes.string,
				lat: PropTypes.number,
				lon: PropTypes.number,
			}),
			isAdmin: PropTypes.bool,
			isVerified: PropTypes.bool,
			company: PropTypes.number,
			onUpdateWorker: PropTypes.function,
			onDeleteWorkerIntent: PropTypes.function,
		})
	).isRequired,
};

const styles = {
	emailText: {
		fontSize: 14,
		color: '#9b9b9b',
	},
};

export default withSnackbarContext(withStyles(styles)(Table));
