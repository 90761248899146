import { getAuthKey } from 'utils/localStorage';

export const getJustAuthHeader = () => {
	const token = getAuthKey();
	return token ? { Authorization: token } : {};
};

export const getHeaders = () => {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...getJustAuthHeader(),
	};
};
