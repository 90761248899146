import React, { Component } from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router';

import { Button } from 'components/common';
import { DefaultPageSize } from 'utils/search';
import { Search } from 'components/filters';
import { withSnackbarContext } from 'context/SnackbarsContext';
import * as client from 'api/client';
import ConfirmationAlert from 'components/confirmationAlert';
import CreateCustomerDrawer from 'modules/Customer/Create';
import Pagination from 'components/pagination';
import Table from 'modules/Customer/List/Table';
import TitleSection from 'components/TitleSection';
import UpdateCustomerDrawer from 'modules/Customer/Update';

const messages = {
	customers: 'Customers',
	addCustomer: 'Add customer',
	customerSuccessfullyCreated: 'Customer successfully created',
};

class CustomerList extends Component {
	state = {
		customers: [],
		customersPerPage: DefaultPageSize,
		searchQuery: '',
		totalCustomers: 0,
		currentPage: 1,
		customerToDelete: null,
		isLoading: true,
		isCreating: false,
		customerToUpdate: null,
	};

	componentDidMount = () => {
		this.goToPage(1);
	};

	fetchData = ({ start, limit, searchQuery = '' }) => {
		this.setState({ isLoading: true });

		client
			.getCustomers({
				pagination: { start, limit },
				search: { query: searchQuery },
			})
			.then((response) => {
				const { customers, totalCustomers } = response.data;
				this.setState((prevState) => ({
					...prevState,
					searchQuery,
					customers,
					totalCustomers,
					isLoading: false,
				}));
			})
			.catch((error) => {
				const { snackbarContext } = this.props;
				snackbarContext.failure('Something went wrong');

				this.setState({ isLoading: false });
				console.error('Error during fetch()', error);
			});
	};

	goToPage = (page) => {
		const { customersPerPage, searchQuery } = this.state;
		this.setState({ currentPage: page });
		this.fetchData({
			start: (page - 1) * customersPerPage,
			limit: customersPerPage,
			searchQuery,
		});
	};

	handleChangeQuery = async (query) => {
		this.setState({ searchQuery: query, currentPage: 1 }, () => {
			const { currentPage, customersPerPage, searchQuery } = this.state;
			this.fetchData({
				start: (currentPage - 1) * customersPerPage,
				limit: customersPerPage,
				searchQuery,
			});
		});
	};

	handleChangeItemsPerPage = (value) => {
		const { searchQuery } = this.state;
		this.setState({ customersPerPage: value, currentPage: 1 });
		this.fetchData({ start: 0, limit: value, searchQuery });
	};

	onRemoveCustomerInner = (customerId) => {
		client
			.deleteCustomer({ customerId })
			.then((response) => {
				if (response.success) {
					this.setState((prevState) => ({
						...prevState,
						isLoading: false,
						customers: prevState.customers.filter((c) => c.id !== customerId),
					}));
				}
			})
			.catch((error) => {
				const { snackbarContext } = this.props;

				this.setState({ isLoading: false });
				snackbarContext.failure('Something went wrong, unable to delete customer');
				console.error('Error during fetch()', error);
			});
	};

	onRemoveCustomer = () => {
		const { id } = this.state.customerToDelete;
		this.setState({ isLoading: true, customerToDelete: null }, () => {
			this.onRemoveCustomerInner(id);
		});
	};

	handleCustomerCreated = (customer) => {
		const { history, snackbarContext } = this.props;
		this.setState({ isCreating: false }, () => {
			snackbarContext.success(messages.customerSuccessfullyCreated);
			history.push(`/customer/${customer.id}/details`);
		});
	};

	renderAddCustomerButton = () => {
		return (
			<div>
				<Button
					onClick={() => this.setState({ isCreating: true })}
					variant="contained"
					color="primary"
					startIcon={<AddIcon />}
					height={38}
					style={{ marginLeft: '10px' }}
				>
					{messages.addCustomer}
				</Button>
			</div>
		);
	};

	handleCustomerUpdated = (customer) => {
		this.setState((previousState) => ({
			customers: previousState.customers.map((c) => (c.id === customer.id ? customer : c)),
			customerToUpdate: null,
		}));
	};

	render = () => {
		const { isMobile } = this.props;
		const {
			customers,
			currentPage,
			totalCustomers,
			customersPerPage,
			customerToDelete,
			isLoading,
			isCreating,
			customerToUpdate,
		} = this.state;

		const pages = Math.ceil(totalCustomers / customersPerPage);

		return (
			<>
				{!!customerToDelete && (
					<ConfirmationAlert
						openAlert={true}
						onCancelation={() => this.setState({ customerToDelete: null })}
						onConfirmation={() => this.onRemoveCustomer()}
						// eslint-disable-next-line max-len
						message={`Are you sure you want to delete the customer and all ${customerToDelete?.projectCount} project(s).`}
					/>
				)}

				<CreateCustomerDrawer
					isCreating={isCreating}
					onCustomerCreated={this.handleCustomerCreated}
					onClose={() => this.setState({ isCreating: false })}
				/>

				<UpdateCustomerDrawer
					customer={customerToUpdate}
					onCustomerUpdated={(customer) => this.handleCustomerUpdated(customer)}
					onClose={() => this.setState({ customerToUpdate: null })}
				/>

				<TitleSection title={messages.customers}>{isMobile && this.renderAddCustomerButton()}</TitleSection>

				<Grid container wrap="nowrap">
					<Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt="20px" mb="20px">
						<Search
							fullWidth={isMobile}
							placeholder="Search customers"
							onQueryChange={this.handleChangeQuery}
							disabled={isLoading}
						/>
						{!isMobile && this.renderAddCustomerButton()}
					</Box>
				</Grid>

				{isLoading ? (
					<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
						<CircularProgress size={60} />
					</Box>
				) : (
					<>
						<Table
							isSearch={!!this.state.searchQuery}
							customers={customers}
							onCustomerUpdate={(customerToUpdate) => this.setState({ customerToUpdate })}
							onRemoveCustomer={(customer) => this.setState({ customerToDelete: customer })}
						/>

						<Box mt="40px">
							<Pagination
								currentPage={currentPage}
								pages={pages}
								itemsPerPage={customersPerPage}
								onChangeItemsPerPage={(value) => this.handleChangeItemsPerPage(value)}
								goToPage={this.goToPage}
							/>
						</Box>
					</>
				)}
			</>
		);
	};
}

export default withRouter(withSnackbarContext(CustomerList));
