import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Card, CardContent, Container, FormHelperText, TextField, Typography } from '@material-ui/core';
import * as client from 'api/client';
import { withSnackbarContext } from 'context/SnackbarsContext';
import BarnWrapper from 'components/auth/BarnWrapper';

const Reset = ({ history, snackbarContext, match }) => (
	<BarnWrapper title="Reset password | Werkgo">
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Box alignItems="center" display="flex" justifyContent="space-between">
						<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
							<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
							<Typography color="textPrimary" gutterBottom variant="h2">
								Reset Password
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Use a minimum of 8 characters, combining numbers, upper and lowercase letters to make a stronger
								password.
							</Typography>
						</Box>
					</Box>

					<Formik
						initialValues={{
							password: '',
							submit: null,
						}}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							password: Yup.string().min(8).max(255).required('Password is required'),
							confirmedPassword: Yup.string()
								.min(8)
								.max(255)
								.oneOf([Yup.ref('password'), null], 'Passwords must match')
								.required('Need to confirm new password'),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);

							const { token, userId } = match.params;

							client
								.resetPassword({ newPassword: values.password, token, userId })
								.then((response) => {
									if (response.success) {
										snackbarContext.success('Password changed');
										history.push('/signin');
									} else {
										snackbarContext.failure('Link expired');
									}
								})
								.finally(() => {
									setSubmitting(false);
								});
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
							<form noValidate onSubmit={handleSubmit}>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									autoFocus
									helperText={touched.password && errors.password}
									label="New Password"
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
								<TextField
									error={Boolean(touched.confirmedPassword && errors.confirmedPassword)}
									fullWidth
									helperText={touched.confirmedPassword && errors.confirmedPassword}
									label="Confirm Password"
									margin="normal"
									name="confirmedPassword"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.confirmedPassword}
									variant="outlined"
								/>
								{errors.submit && (
									<Box mt={2}>
										<FormHelperText error>{errors.submit}</FormHelperText>
									</Box>
								)}
								<Box flexGrow={1} mt={2}>
									<Button
										color="secondary"
										disabled={isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
										margin="normal"
									>
										Submit
									</Button>
								</Box>
							</form>
						)}
					</Formik>
				</CardContent>
			</Card>
		</Container>
	</BarnWrapper>
);

export default withSnackbarContext(Reset);
