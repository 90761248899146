import React from 'react';
import { Typography, Box, withStyles } from '@material-ui/core';

const EmptyState = ({ name, classes, title, children }) => (
	<Box className={classes.root} display="flex" flexDirection="column" justifyContent="center">
		<img className={classes.image} alt={title} src={require(`statics/images/empty-states/${name}.svg`)} />
		<Typography variant="h1" className={classes.primary}>
			{title}
		</Typography>
		<Typography variant="h2" className={classes.secondary}>
			{children}
		</Typography>
	</Box>
);

const styles = () => ({
	root: {
		textAlign: 'center',
	},
	image: {
		maxHeight: '128px',
	},
	secondary: {
		fontSize: 14,
		fontWeight: 'unset',
		color: '#656565',
	},
	primary: {
		fontSize: 18,
		fontWeight: 'bold',
	},
});

export default withStyles(styles)(EmptyState);
