import React from 'react';
import { Box, Card, CardContent, Container, Typography } from '@material-ui/core';
import BarnWrapper from 'components/auth/BarnWrapper';

// Render information to let the user know they need to verify their account by
// performing certain action. In this case, going to their email inbox and look
// for a verification email from Werkgo.
const VerifyAccountManualVerification = () => {
	return (
		<BarnWrapper title="Verify account | Werkgo">
			<Container maxWidth="sm">
				<Card>
					<CardContent>
						<Box alignItems="center" display="flex" flexDirection="column" justifyContent="space-between">
							<img height="40px" width="40px" alt="Werkgo Logo" src={require('statics/images/home/logo.png')} />
							<Typography color="textPrimary" gutterBottom variant="h2">
								Verify Email
							</Typography>
							<Typography variant="body1" color="textSecondary">
								Please check your Inbox and Spam folder. We’ve sent a link to confirm your email address. Once you
								confirm, you will be able to access all the features of our site.
							</Typography>
						</Box>
					</CardContent>
				</Card>
			</Container>
		</BarnWrapper>
	);
};

export default VerifyAccountManualVerification;
