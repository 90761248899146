import React, { Component } from 'react';
import { Box, Divider, Paper, Typography, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Button } from 'components/common';

import { getCompactedTimeRange } from 'utils/time';
import { getEventEditLink } from 'utils/calendar';
import { getImageUrl } from 'utils/image';
import * as client from 'api/client';
import { withIsMobile } from 'hoc/IsMobileHOC';

class AppointmentDetails extends Component {
	state = {
		appointment: null,
		isAccepting: false,
		isCancelling: false,
	};

	componentDidMount() {
		const appointmentId = this.props.match.params.id;
		client.getAppointmentDetails({ appointmentId }).then((response) => {
			if (response.success) {
				this.setState({ appointment: response.data.appointment });

				const preview = new URLSearchParams(this.props.location.search).get('preview');
				if (preview !== '1') {
					setTimeout(() => {
						client.recordOpenedAppointment({ appointmentId });
					}, 2000 /* 2 seconds */);
				}
			}
		});
	}

	//----------------------------------------------------------------------
	// API methods
	//----------------------------------------------------------------------

	acceptAppointment = () => {
		const { id: appointmentId } = this.state.appointment;
		this.setState({ isAccepting: true }, () => {
			client.acceptAppointment({ appointmentId }).then((response) => {
				if (response.success) {
					this.setState({
						appointment: response.data.appointment,
						isAccepting: false,
					});
				}
			});
		});
	};

	cancelAppointment = () => {
		const { id: appointmentId } = this.state.appointment;
		this.setState({ isCancelling: true }, () => {
			client.cancelAppointment({ appointmentId }).then((response) => {
				if (response.success) {
					this.setState({
						appointment: response.data.appointment,
						isCancelling: false,
					});
				}
			});
		});
	};

	//----------------------------------------------------------------------
	// Render helpers
	//----------------------------------------------------------------------

	renderTitle = () => {
		const {
			appointment: { appointmentAcceptedTime, appointmentCanceledTime },
		} = this.state;

		const title = appointmentAcceptedTime
			? 'Appointment Accepted'
			: appointmentCanceledTime
			? 'Appointment Cancelled'
			: 'Appointment Confirmation';

		return (
			<Typography variant="h2" component="h2" className={this.props.classes.titleCls}>
				{title}
			</Typography>
		);
	};

	renderLogoOrCompanyName = () => {
		const { companyLogo, companyName } = this.state.appointment;
		if (companyLogo) {
			return <img height="70px" width="auto" alt={companyName} src={getImageUrl(companyLogo)} />;
		}
		return <Typography>{companyName}</Typography>;
	};

	renderCalendarButton = () => {
		const { isMobile, classes } = this.props;
		const { id, companyName, startTime, endTime, address, note } = this.state.appointment;

		let details = `🔗 https://crm.werkgo.com/a/${id}`;
		if (note) {
			details += `\n📝 ${note}`;
		}

		const googleEventArgs = {
			text: `Appointment with ${companyName}`,
			startTime,
			endTime,
			location: address,
			details,
			isMobile,
		};

		return (
			<Button className={classes.linkCls} color="info" variant="text" href={getEventEditLink(googleEventArgs)}>
				Add to calendar
			</Button>
		);
	};

	renderAcceptButton = () => (
		<Button
			className={this.props.classes.linkCls}
			color="primary"
			variant="text"
			disabled={this.state.isAccepting || this.state.isCancelling}
			loading={this.state.isAccepting}
			onClick={this.acceptAppointment}
		>
			Accept
		</Button>
	);

	renderCancelButton = () => (
		<Button
			className={this.props.classes.linkCls}
			color="secondary"
			variant="text"
			disabled={this.state.isAccepting || this.state.isCancelling}
			loading={this.state.isCancelling}
			onClick={this.cancelAppointment}
		>
			Cancel
		</Button>
	);

	renderAppointmentActions = () => {
		const {
			appointment: { appointmentAcceptedTime, appointmentCanceledTime },
		} = this.state;

		if (appointmentCanceledTime) {
			return <Box>{this.renderAcceptButton()}</Box>;
		}

		if (appointmentAcceptedTime) {
			return (
				<Box display="flex">
					{this.renderCalendarButton()}
					<Divider orientation="vertical" flexItem={true} />
					{this.renderCancelButton()}
				</Box>
			);
		}

		return (
			<Box display="flex">
				{this.renderAcceptButton()}
				<Divider orientation="vertical" flexItem={true} />
				{this.renderCancelButton()}
			</Box>
		);
	};

	render() {
		const { appointment } = this.state;
		if (!appointment) {
			return null;
		}

		const { classes } = this.props;
		const { address, startTime, endTime, note } = appointment;

		return (
			<>
				<Box className={classes.topBar}>{this.renderLogoOrCompanyName()}</Box>
				<Box className={classes.root}>
					<Paper className={classes.paper}>
						{this.renderTitle()}
						<Typography className={classes.timeCls}>🕒 {getCompactedTimeRange(startTime, endTime)}</Typography>
						{address && <Typography className={classes.addressCls}>📍 {address}</Typography>}
						{note && <Typography className={classes.noteCls}>📝 {note}</Typography>}
						{this.renderAppointmentActions()}
					</Paper>
				</Box>
			</>
		);
	}
}

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		marginTop: '100px',
	},
	topBar: {
		display: 'flex',
		position: 'fixed',
		width: '100%',
		height: '90px',
		background: 'white',
		paddingLeft: '10px',
		borderColor: 'black',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		padding: '20px',
		width: '512px',
		maxWidth: '100%',
	},
	titleCls: {
		marginBottom: '20px',
		fontWeigth: 'bold',
		textAlign: 'center',
	},
	addressCls: {
		marginBottom: '10px',
	},
	timeCls: {
		marginBottom: '10px',
	},
	linkCls: {
		width: '100%',
	},
	noteCls: {
		marginBottom: '10px',
	},
};

export default withIsMobile(withStyles(styles)(withRouter(AppointmentDetails)));
