import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Typography, Select as MuiSelect, MenuItem, withStyles } from '@material-ui/core';

import styles from 'components/common/styles/outlinedSelect';

class OutlinedSelect extends Component {
	state = {
		showPlaceholder: true,
	};

	handleChange = (e) => {
		this.setState({ value: e.target.value });
		this.props.onChange(e);
	};

	render() {
		const { items, value, placeholder, disabled, error, helperText, classes, ...props } = this.props;
		const { showPlaceholder } = this.state;

		return (
			<FormControl error={error} className={classes.formControl}>
				<MuiSelect
					variant="outlined"
					value={value ?? 'none'}
					onFocus={() => this.setState({ showPlaceholder: false })}
					onClose={(e) => this.setState({ showPlaceholder: e.target.value === undefined })}
					onChange={(e) => this.setState({ value: e.target.value })}
					disabled={disabled}
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
					}}
					className={!error ? classes.root : null}
					classes={{ select: classes.select }}
					{...props}
				>
					<MenuItem value="none" key="none" className={showPlaceholder ? null : classes.hidePlaceholder}>
						<Typography variant="h5" className={classes.placeholder}>
							{placeholder}
						</Typography>
					</MenuItem>
					{items.map(({ value, label }) => (
						<MenuItem key={value} value={value}>
							<Typography variant="h5">{label}</Typography>
						</MenuItem>
					))}
				</MuiSelect>
				{helperText && <FormHelperText className={classes.helper}>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
}

OutlinedSelect.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		})
	).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	error: PropTypes.bool,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
};

export default withStyles(styles)(OutlinedSelect);
