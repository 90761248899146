import React, { Component } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import * as client from 'api/client';
import Table from 'modules/Task/TaskList/Table';
import FiltersSection from 'modules/Task/TaskList/FiltersSection';
import TitleSection from 'components/TitleSection';
import { withSnackbarContext } from 'context/SnackbarsContext';
import ConfirmationAlert from 'components/confirmationAlert';
import Pagination from 'components/pagination';
import { DefaultPageSize } from 'utils/search';
import { TaskPriority, TaskStatus } from 'types/enums';
import EditTaskDrawer from 'modules/Task/Edit/index';
import CreateTaskDrawer from 'modules/Task/Create/index';
import { withIsMobile } from 'hoc/IsMobileHOC';
import { Button } from 'components/common';
import { Add as AddIcon } from '@material-ui/icons';

const messages = {
	tasks: 'Tasks',
};

const initialTask = {
	description: '',
	status: TaskStatus.Undone,
	priority: TaskPriority.Medium,
};

class TaskList extends Component {
	state = {
		tasks: [],
		newTask: { ...initialTask },
		currentPage: 1,
		filters: {
			searchQuery: '',
			status: TaskStatus.Undone,
			priority: -1,
		},
		tasksPerPage: DefaultPageSize,
		openAlert: false,
		selectedTask: {},
		totalTasks: 0,
		isCreating: false,
		isLoading: true,
		taskBeingEdited: null,
	};

	componentDidMount = () => {
		this.goToPage(1);
	};

	_getTasks = (tasks) => {
		// Filter task when the status change or a new task
		// is created.
		const { status, priority } = this.state.filters;
		const tasksFilterByStatus = status >= 0 ? tasks.filter((t) => t.status === status) : [...tasks];
		const tasksFiltersByPriority =
			priority >= 0 ? tasksFilterByStatus.filter((t) => t.priority === priority) : tasksFilterByStatus;

		return tasksFiltersByPriority;
	};

	_getNewLenght = (newPriority, newStatus) => {
		// Return a number to increase the total
		// of task when a task is created.
		const { status: filterStatus, priority: filterPriority } = this.state.filters;
		return (filterPriority === newPriority || filterPriority === -1) &&
			(filterStatus === newStatus || filterStatus === -1)
			? 1
			: 0;
	};

	_refresh = () => {
		// Re-fetch data when remove or mark as done a task and the number
		// of pages decreases as a cause of it.
		const { totalTasks, tasksPerPage, currentPage } = this.state;
		if (totalTasks % tasksPerPage === 0) {
			this.goToPage(currentPage);
		}
	};

	fetchData = async ({ start, limit, filters }) => {
		const { searchQuery, status, priority } = filters;
		const { tasks, totalTasks } = this.state;
		const tasksLength = tasks.length;

		if (start !== 0 && (totalTasks === tasksLength || tasksLength > start + limit)) {
			return;
		}
		this.setState({ isLoading: true });

		const statusList = status >= 0 ? [status] : [];
		const priorityList = priority >= 0 ? [priority] : [];

		try {
			const response = await client.listTasks({
				pagination: { start, limit },
				filters: {
					status: statusList,
					query: searchQuery,
					priorities: priorityList,
				},
			});
			if (response.success) {
				const { tasks, total } = response.data;
				this.setState((prevState) => ({
					...prevState,
					tasks,
					totalTasks: total,
					isLoading: false,
				}));
			}
		} catch (error) {
			const { snackbarContext } = this.props;

			snackbarContext.failure('Something went wrong');
			console.error('Error during fetch()', error);

			this.setState({ isLoading: false });
		}
	};

	goToPage = (page, newFilters = {}, newTasksPerPage) => {
		const { tasksPerPage, filters } = this.state;
		const itemsPerPage = newTasksPerPage || tasksPerPage;
		this.setState((prev) => ({
			isCreating: false,
			currentPage: page,
			tasksPerPage: itemsPerPage,
			filters: { ...prev.filters, ...newFilters },
		}));
		this.fetchData({
			start: (page - 1) * itemsPerPage,
			limit: itemsPerPage,
			filters: { ...filters, ...newFilters },
		});
	};

	handleChangeItemsPerPage = (itemsPerPage) => {
		this.goToPage(1, {}, itemsPerPage);
	};

	handleCreateTask = async () => {
		this.setState({ isLoading: true });
		try {
			const response = await client.createTask({ ...this.state.newTask });
			if (response.success) {
				this.setState((prev) => ({
					...prev,
					tasks: this._getTasks([response.data.task, ...prev.tasks]),
					totalTasks: prev.totalTasks + this._getNewLenght(),
					newTask: { ...initialTask },
					isCreating: false,
					isLoading: false,
				}));
			}
		} catch (error) {
			const { snackbarContext } = this.props;
			this.setState({
				isLoading: false,
			});

			snackbarContext.failure('Something went wrong creating the task');
			console.error('Error during creating()', error);
		}
	};

	handleDoneTask = async (task, done) => {
		const { status: filterStatus } = this.state.filters;
		const status = done ? TaskStatus.Done : TaskStatus.Undone;

		// This variable is used to calculate the new total
		// because in this case the condition is different
		// from the one used in _getNewLenght.
		const newLenght = filterStatus >= 0 && filterStatus !== status ? 1 : 0;

		// This variable is used to store the currentTask
		// in case that the request fail
		let currentTasks;

		this.setState((prevState) => {
			currentTasks = [...prevState.tasks];
			return {
				...prevState,
				tasks: prevState.tasks.map((t) => (t.id === task.id ? { ...t, status } : t)),
				totalTasks: prevState.totalTasks - newLenght,
			};
		});

		try {
			// TODO:  Remove in the future the delay used for the
			// fading effect or move it to a more suitable location.
			const [response] = await Promise.all([
				client.updateTask({ ...task, status }),
				new Promise((r) => setTimeout(r, 500)),
			]);

			if (response.success) {
				const { task: newTask } = response.data;

				this.setState((prevState) => ({
					...prevState,
					tasks: this._getTasks(prevState.tasks.map((t) => (t.id === newTask.id ? newTask : t))),
				}));

				this._refresh();
			}
		} catch (error) {
			const { snackbarContext } = this.props;
			this.setState((prevState) => ({
				...prevState,
				tasks: currentTasks,
				totalTasks: prevState.totalTasks + newLenght,
			}));

			snackbarContext.failure('Something went wrong changing this task to done');
			console.error('Error during updating()', error);
		}
	};

	handleDeleteTask = async () => {
		const task = this.state.selectedTask;

		// This variable is used to store the currentTask
		// in case that the request fail
		let currentTasks;

		this.setState((prevState) => {
			currentTasks = [...prevState.tasks];
			return {
				...prevState,
				openAlert: false,
				selectedTask: {},
				tasks: prevState.tasks.filter((t) => t.id !== task.id),
				totalTasks: prevState.totalTasks - 1,
			};
		});

		try {
			await client.deleteTask({ id: task.id });
			this._refresh();
		} catch (error) {
			const { snackbarContext } = this.props;

			this.setState((prev) => ({
				tasks: currentTasks,
				totalTasks: prev.totalTasks + 1,
			}));

			snackbarContext.failure('Something went wrong. Unable to delete element');
			console.error('Error during removing()', error);
		}
	};

	handlePriorityChange = (value) => {
		this.setState((prev) => ({
			...prev,
			newTask: { ...prev.newTask, priority: value },
		}));
	};

	handleStatusChange = (value) => {
		this.setState((prev) => ({
			...prev,
			newTask: { ...prev.newTask, status: value },
		}));
	};

	handleTitleChange = (value) => {
		this.setState((prev) => ({
			...prev,
			newTask: { ...prev.newTask, description: value },
		}));
	};

	handleEditIntention = (task) => {
		this.setState({ taskBeingEdited: task });
	};

	handleTaskEdited = (editedTask) => {
		this.setState(({ tasks }) => ({
			tasks: tasks.map((task) => (task.id === editedTask.id ? editedTask : task)),
			taskBeingEdited: null,
		}));
	};

	handleTaskCreated = (task) => {
		this.setState((previousState) => ({
			tasks: [task, ...previousState.tasks],
			isCreating: false,
		}));
	};

	renderAddTaskForMobile = () => {
		return (
			<Button
				onClick={() => this.setState({ isCreating: true })}
				variant="contained"
				color="primary"
				startIcon={<AddIcon />}
				height={38}
				style={{ marginLeft: 'auto' }}
			>
				Add Task
			</Button>
		);
	};

	render = () => {
		const { isMobile } = this.props;

		const {
			tasks,
			newTask,
			filters,
			currentPage,
			totalTasks,
			tasksPerPage,
			openAlert,
			selectedTask,
			isCreating,
			isLoading,
			taskBeingEdited,
		} = this.state;

		const pages = Math.ceil(totalTasks / tasksPerPage);

		return (
			<>
				<ConfirmationAlert
					openAlert={openAlert}
					onCancelation={() => this.setState({ openAlert: false })}
					onConfirmation={() => this.handleDeleteTask(selectedTask)}
					message={'Are you sure you want to delete the task?'}
				/>

				<EditTaskDrawer
					task={taskBeingEdited}
					onClose={() => this.setState({ taskBeingEdited: null })}
					onTaskEdited={this.handleTaskEdited}
				/>

				<CreateTaskDrawer
					isCreating={isCreating && isMobile}
					onTaskCreated={this.handleTaskCreated}
					onClose={() => this.setState({ isCreating: false })}
				/>

				<TitleSection title={messages.tasks}>{isMobile && this.renderAddTaskForMobile()}</TitleSection>

				<Box mt="20px" mb="20px">
					<FiltersSection
						filters={filters}
						applyFilter={(filter) => this.goToPage(1, filter)}
						onCreate={() => this.setState({ isCreating: true })}
						disable={isLoading}
						isMobile={isMobile}
					/>
				</Box>
				{isLoading ? (
					<Box display="flex" width="100%" height="300px" justifyContent="center" alignItems="flex-end">
						<CircularProgress size={60} />
					</Box>
				) : (
					<>
						<Table
							tasks={tasks}
							newTask={newTask}
							status={filters.status}
							// We support inline creation on web only
							isCreating={isCreating && !isMobile}
							onCancelCreating={() =>
								this.setState({
									isCreating: false,
									newTask: { ...initialTask },
								})
							}
							onCreateTask={this.handleCreateTask}
							onDoneTask={this.handleDoneTask}
							onRemoveTask={(task) => this.setState({ openAlert: true, selectedTask: task })}
							onChangeTitle={this.handleTitleChange}
							onChangePriority={this.handlePriorityChange}
							onChangeStatus={this.handleStatusChange}
							onEditIntention={this.handleEditIntention}
						/>
						<Box mt="40px">
							<Pagination
								currentPage={currentPage}
								pages={pages}
								itemsPerPage={tasksPerPage}
								onChangeItemsPerPage={(value) => this.handleChangeItemsPerPage(value)}
								goToPage={this.goToPage}
							/>
						</Box>
					</>
				)}
			</>
		);
	};
}

export default withIsMobile(withSnackbarContext(TaskList));
