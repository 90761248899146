const styles = {
	formControl: {
		display: 'flex',
	},
	select: {
		backgroundColor: '#fff',
		'&:focus': {
			backgroundColor: '#fff',
		},
	},
	placeholder: {
		color: '#657b86',
	},
	hidePlaceholder: {
		display: 'none',
	},
	helper: {
		marginLeft: 10,
	},
};

export default styles;
