import React, { Component } from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';

import Page from 'components/Page';
import Footer from 'components/Footer';
import HeaderSection from 'modules/Home/HeaderSection';
import Section from 'modules/Home/Section';
import BuyAppSection from 'modules/Home/BuyAppSection';
import styles from 'modules/Home/styles/home';

class Home extends Component {
	render() {
		const { classes } = this.props;

		return (
			<Page className={classes.root} title="Home">
				<HeaderSection />
				<Box pt="100px">
					<Typography align="center" variant="h1" className={classes.featuresTitle}>
						All Features
					</Typography>
				</Box>
				<Section
					title="CRM System"
					text="Werkgo helps you become more productive while you’re running your contract business. It is an all-in-one
					CRM app and a project management tool to help you keep a track of all your projects and
					customers."
					url="#"
					leftImage={require('statics/images/home/crm-system.svg')}
				/>
				<Section
					title="Invoicing"
					text="Creating and sharing invoices have never been so easy and intuitive. 
					In Werkgo we provide you with the tools to get paid fast and secure."
					url="#"
					rightImage={require('statics/images/home/invoicing.svg')}
				/>
				<Section
					title="Scheduling System"
					text="Never miss another meeting with your client, keep all your company 
					events in one place and link them to your customer and projects."
					url="#"
					leftImage={require('statics/images/home/crm-system.svg')}
				/>
				<Section
					title="Mobile App"
					text="Doesn't matter if you're an Android or iPhone user, 
					keep all your company information to the reach of your pocket with a fast, 
					secure and reliable app which is the pride of our company."
					url="#"
					rightImage={require('statics/images/home/mobile-app.svg')}
				/>
				{/* <Box width="100%" m="80px 0" className={classes.experienceContainer}>
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid container item md={6} direction="column" className={classes.experienceTextContainer}>
							<Typography variant="h1" className={classes.experienceTitle}>
								5 Years Experience
							</Typography>
							<Typography variant="h5" className={classes.experienceText}>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco irure dolor in reprehen
								deritLorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore
								Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostru
							</Typography>
							<Button component={Link} to="/" color="primary" width={240}>
								Get Started
							</Button>
						</Grid>

						<Grid item md={6} className={classes.playImageContainer}>
							<MuiLink href="#youtube">
								<img
									alt="Go to watch video"
									src={require('statics/images/home/video-play.svg')}
									className={classes.playImage}
								/>
							</MuiLink>
						</Grid>
					</Grid>
				</Box> */}
				<Box m="40px 0 100px" p="0 250px" className={classes.buyAppSectionContainer}>
					<BuyAppSection />
				</Box>
				<Footer />
			</Page>
		);
	}
}

export default withStyles(styles)(Home);
