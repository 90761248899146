import React, { useEffect, useState } from 'react';
import * as client from 'api/client';
import VerifyAccountVerifying from 'modules/Auth/Verify/VerifyAccountVerifying';
import VerifyAccountFailure from 'modules/Auth/Verify/VerifyAccountFailure';
import VerifyAccountSuccess from 'modules/Auth/Verify/VerifyAccountSuccess';

// Verify the account using the user ID and token provided as URL parameters.
const VerifyAccount = ({ userId, token }) => {
	const [status, setStatus] = useState(-1);

	useEffect(() => {
		client.verifyEmail({ token, userId }).then(({ data: { status } }) => {
			setStatus(status);
		});
	});

	switch (status) {
		case -1:
			return <VerifyAccountVerifying />;
		case 1:
		case 2:
			// If status is `1`, then it means the account was verified in
			// this same request. If the status is `2`, the account was already
			// verified. In either case, we show a success screen.
			return <VerifyAccountSuccess />;
		default:
			return <VerifyAccountFailure />;
	}
};

export default VerifyAccount;
