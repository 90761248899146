import React, { Component } from 'react';
import * as _ from 'lodash';
import { Formik } from 'formik';
import {
	Box,
	Card,
	CardContent,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { DateTimePicker } from '@material-ui/pickers';
import * as client from 'api/client';
import * as Yup from 'yup';
import EventColorPicker from 'modules/Event/EventColorPicker';
import CustomerAutocomplete from 'components/autocomplete/CustomerAutocomplete';
import ProjectAutocomplete from 'components/autocomplete/ProjectAutocomplete';
import * as ls from 'utils/localStorage';
import PropTypes from 'prop-types';
import EventNameSuggestions from 'components/suggestions/EventNameSuggestions';
import { Button } from 'components/common';

class AddCustomerAndEventForm extends Component {
	state = {
		phaseId: null,
		customer: {},
		project: {},
		eventTitle: '',
		startTime: null,
		endTime: null,
		color: '#039be5',
		allowSmsReminder: false,
		companyAllowSmsReminders: false,
	};

	componentDidMount = () => {
		// Edit mode. Load the event for edition
		if (this.props.event) {
			const { title, customer, projectId, startTime, endTime, allowSmsReminder } = this.props.event.extra.phase;
			const newState = {
				phaseId: this.props.event.id,
				customer: customer ?? {},
				startTime,
				endTime,
				eventTitle: title,
				color: this.props.event.backgroundColor,
				allowSmsReminder: allowSmsReminder,
			};

			if (projectId) {
				client
					.projectDetails({
						projectId,
						withCustomer: false,
					})
					.then((resp) => {
						newState.project = resp.data.project;
						this.setState(newState);
					});
			} else {
				this.setState(newState);
			}
		}

		ls.getCompanyDetailsFromLocalStorage().then((company) => {
			this.setState({ companyAllowSmsReminders: company.allowSmsReminders });
		});
	};

	componentDidUpdate = (prevProps) => {
		const prevRange = prevProps.range;
		const currRange = this.props.range;

		if (!_.isEqual(prevRange, currRange)) {
			if (currRange) {
				const { start, end } = currRange;
				this.setState({
					startTime: new Date(start),
					endTime: new Date(end),
				});
			}
		}
	};

	render() {
		const { phaseId } = this.state;
		const { open, onClose, onAddOrUpdateCompleted } = this.props;

		return (
			<Formik
				initialValues={this.state}
				enableReinitialize={true}
				validationSchema={Yup.object().shape({
					eventTitle: Yup.string().max(512).required('A title is required to create this event.'),
					startTime: Yup.date(),
					endTime: Yup.date().min(Yup.ref('startTime'), 'Event must finish after it start :)'),
				})}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					const { customer, project, eventTitle, startTime, endTime, color, allowSmsReminder } = values;
					const eventCustomer = customer && customer.id ? customer.id : null;
					const eventProject = project && project.projectId ? project.projectId : null;

					let args;
					if (phaseId) {
						args = {
							phaseId,
							title: eventTitle,
							startTime,
							endTime,
							allowSmsReminder,
							color,
							customerId: eventCustomer,
							projectId: eventProject,
							extraFields: true,
						};
					} else {
						args = {
							customerId: eventCustomer,
							projectId: eventProject,
							phase: {
								title: eventTitle,
								start: startTime,
								end: endTime,
								color,
								allowSmsReminder,
							},
						};
					}

					const promise = phaseId ? client.editPhase(args) : client.createPhaseFromCalendar(args);

					promise.then((response) => {
						actions.setSubmitting(false);
						if (response.success) {
							onAddOrUpdateCompleted(response.data.phase);
						}
					});
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldTouched,
					setFieldValue,
					touched,
					values,
				}) => {
					// Do not render the reminder checkbox if any of the below options are true:
					// - Company is not allowed to send SMSs
					// - Customer is not selected
					// - Customer does not have a phone number

					const renderAutomaticReminderOption =
						values.companyAllowSmsReminders &&
						!!Object.keys(values.customer).length &&
						values.customer.phone &&
						values.customer.phone.length;

					return (
						<>
							<Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
								<DialogTitle id="alert-dialog-title">
									<Typography color="textPrimary">{phaseId ? 'Edit' : 'Add'} event</Typography>
								</DialogTitle>
								<form onSubmit={handleSubmit}>
									<DialogContent>
										<Card>
											<CardContent>
												<Grid container spacing={3}>
													<Grid item md={12} xs={12}>
														<TextField
															error={Boolean(touched.eventTitle && errors.eventTitle)}
															fullWidth
															helperText={touched.eventTitle && errors.eventTitle}
															label="Event title"
															name="eventTitle"
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.eventTitle}
															variant="outlined"
															placeholder="Estimate, Installation, Followup, etc."
														/>
													</Grid>

													<EventNameSuggestions
														onSelect={(name) => {
															setFieldValue('eventTitle', name);
														}}
													/>

													<Grid item md={6} xs={12}>
														<DateTimePicker
															fullWidth
															inputVariant="outlined"
															label="Start time"
															name="startTime"
															onClick={() => setFieldTouched('startTime')}
															variant="inline"
															autoOk={true}
															onChange={(date) => {
																// First, calculate the delta between the previous start and
																// end times in order to "shift" the new end time relative to
																// the new start time.
																let deltaMs = 60 * 60 * 1000;
																if (values['endTime']) {
																	deltaMs = new Date(values['endTime']) - new Date(values['startTime']);
																}

																setFieldValue('startTime', date);
																setFieldValue('endTime', date.clone().add(deltaMs, 'ms'));
															}}
															value={values.startTime}
														/>
													</Grid>
													<Grid item md={6} xs={12}>
														<DateTimePicker
															fullWidth
															inputVariant="outlined"
															label="End time"
															name="endTime"
															onClick={() => setFieldTouched('endTime')}
															onChange={(date) => setFieldValue('endTime', date)}
															value={values.endTime}
															variant="inline"
															autoOk={true}
														/>
													</Grid>
													<Box display="flex" pl="12px" pb="12px" pt="12px" width="100%">
														<CustomerAutocomplete
															onSelect={(e, customer) => {
																if (customer && Object.keys(customer).length && Object.keys(e).length) {
																	setFieldValue('customer', customer);
																} else {
																	setFieldValue('customer', {});
																	setFieldValue('project', {});
																}
															}}
															label="Customer (Optional)"
															initialValue={values.customer}
														/>
														<Tooltip title="Link event to new or existing customer.">
															<IconButton aria-label="event-customer">
																<InfoIcon />
															</IconButton>
														</Tooltip>
													</Box>
													{!!Object.keys(values.customer).length && (
														<Box display="flex" pl="12px" pb="12px" pt="12px" width="100%">
															<ProjectAutocomplete
																customerId={values.customer.id}
																onSelect={(e, project) => {
																	setFieldValue('project', project);
																	handleChange(e);
																}}
																label="Project (Optional)"
																initialValue={values.project}
															/>
															<Tooltip title="Link event to new or existing project.">
																<IconButton aria-label="event-customer">
																	<InfoIcon />
																</IconButton>
															</Tooltip>
														</Box>
													)}
													<Grid item md={12} xs={12}>
														<EventColorPicker
															color={values.color}
															onChange={(color) => setFieldValue('color', color)}
														/>
													</Grid>
													{renderAutomaticReminderOption && (
														<Grid item md={12} xs={12}>
															<FormControlLabel
																aria-label="Send SMS reminder"
																onClick={(event) => event.stopPropagation()}
																onFocus={(event) => event.stopPropagation()}
																control={
																	<Checkbox
																		checked={values.allowSmsReminder}
																		name="allowSmsReminder"
																		onChange={handleChange}
																	/>
																}
																label="Send SMS reminder to customer 24 hours before."
															/>
														</Grid>
													)}
												</Grid>
											</CardContent>
										</Card>
									</DialogContent>

									<DialogActions>
										<Button type="submit" variant="contained" height="50px" color="info" onClick={onClose}>
											Cancel
										</Button>
										<Button type="submit" variant="contained" height="50px" color="primary" disabled={isSubmitting}>
											{phaseId ? 'Edit' : 'Create'}
										</Button>
									</DialogActions>
								</form>
							</Dialog>
						</>
					);
				}}
			</Formik>
		);
	}
}

AddCustomerAndEventForm.propTypes = {
	open: PropTypes.bool,
	range: PropTypes.shape({
		start: PropTypes.instanceOf(Date),
		end: PropTypes.instanceOf(Date),
	}),
	onClose: PropTypes.func,
	onAddOrUpdateCompleted: PropTypes.func,
};

export default AddCustomerAndEventForm;
