/* eslint-disable no-mixed-spaces-and-tabs */
import * as models from 'types/models/models';
import { post, GenericSuccess, GenericError } from 'api/core';
import { Pagination, SearchTerm } from 'api/shared';

//***************
// TYPES
//***************

//
// getWorkers
//

export interface GetWorkersArgs {
	pagination?: Pagination;
	search?: SearchTerm;
}

export type GetWorkersResult =
	| GenericSuccess<{
			workers: models.Worker[];
			total: number;
	  }>
	| GenericError;

//
// createWorker
//

export interface CreateWorkerArgs {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	address: string;
	allowLogin: boolean;
}

export type CreateWorkerResult =
	| GenericSuccess<{
			worker: models.Worker;
	  }>
	| GenericError;

//
// editWorker
//

export interface EditWorkerArgs {
	userId: number;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address: string;
}

export type EditWorkerResult =
	| GenericSuccess<{
			worker: models.Worker;
	  }>
	| GenericError;

//
// deleteWorker
//

export interface DeleteWorkerArgs {
	workerId: number;
}

export type DeleteWorkerResult = GenericSuccess<{}> | GenericError;

//***************
// REQUESTS
//***************

export const getWorkers = (args: GetWorkersArgs): Promise<GetWorkersResult> => {
	return post('worker/list/', args);
};

export const createWorker = (args: CreateWorkerArgs): Promise<CreateWorkerResult> => {
	return post('worker/create/', args);
};

export const editWorker = (args: EditWorkerArgs): Promise<EditWorkerResult> => {
	return post('worker/edit/', args);
};

export const deleteWorker = (args: DeleteWorkerArgs): Promise<DeleteWorkerResult> => {
	return post('worker/delete/', args);
};
